import React, { useEffect, useState } from "react";

import manual from "./icons/manual.png";
import pin from "./icons/pin.png";
import UserManual from "../UserManual/UserManual";
import Button from "../Button/Button";
import Unlockscreen from "../unlockScreen/Unlockscreen";
import axios from "axios";
import "./Style.css";
import { useGlobalContext } from "../Context/Context";
import Accounts from "../Accounts/Accounts";
import ChangePassword from "../ChangePassword/ChangePassword";
import TrustFolder from "../TrustFolder/TrustFolder";

function DefinePin({
  selectAccount,
  setSelectAccount,
  setCurrentPage,
  setSelectAccountPin,
  selectAccountPin,
}) {
  const [password, setPassword] = useState(false);
  const { loggedUser, setLoggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();

  const { baseUrl } = useGlobalContext();
  const [selectAccountPattern, setSelectAccountPattern] = useState([]); //treba loggedUser.pattern

  const handleOpenUserManual = (e) => {
    console.log(e.currentTarget);
    setSelectAccountPin(2);
  };

  const handleOpenAccount = (e) => {
    console.log(e.currentTarget);
    setSelectAccountPin(1);
  };

  const handleOpenSetPassword = (e) => {
    setSelectAccountPin(3);
  };

  useEffect(() => {
    const handleCheckPattern = async () => {
      console.log(selectAccountPattern);

      let localPattern = "";
      selectAccountPattern.forEach((pattern) => {
        localPattern += pattern.toString();
      });
      console.log(localPattern);
      try {
        const { data } = await axios.post(
          `${baseUrl}/auth/checkUserPattern.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
            user_pattern: localPattern,
          }
        );
        if (data.status === 200) {
          console.log("Usao");
          setSelectAccountPin(1);
          loggedUser.pattern = localPattern;
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedUser.pattern.length !== 0) handleCheckPattern();
  }, [selectAccountPattern]);

  console.log("Render definePin", selectAccount);
  console.log("Render definePin", selectAccountPin);
  return (
    <div className="definePin-container">
      {selectAccountPin === 0 && (
        <div className="loggin-pin-container" /*onClick={handleOpenAccount}*/>
          <div className="manual">
            <a href="#" onClick={handleOpenUserManual}>
              <img src={manual} alt="manual" />
            </a>
          </div>
          {loggedUser.pattern.length !== 0 ? (
            <div className="pin">
              <Unlockscreen
                type={"second"}
                winPattern={""}
                draft={true}
                setInputPattern={setSelectAccountPattern}
              />
            </div>
          ) : (
            <div className="pinBtn" onClick={handleOpenSetPassword}>
              <Button>
                <img src={pin} alt="pin" />
              </Button>
            </div>
          )}
        </div>
      )}

      {selectAccountPin === 1 && (
        <Accounts
          selectAccount={selectAccount}
          setSelectAccount={setSelectAccount}
          setSelectAccountPin={setSelectAccountPin}
          setCurrentPageGlobal={setCurrentPage}
        />
      )}
      {selectAccountPin === 2 && (
        <UserManual setSelectAccountPin={setSelectAccountPin} />
      )}
      {selectAccountPin === 3 && (
        <ChangePassword
          setCurrentPage={setCurrentPage}
          setSelectAccountPin={setSelectAccountPin}
          type={true}
        />
      )}
      {selectAccountPin === 4 && (
        <TrustFolder setCurrentPage={setCurrentPage} type={true} />
      )}
      {selectAccountPin === 5 && (
        <ChangePassword
          setCurrentPage={setCurrentPage}
          setSelectAccountPin={setSelectAccountPin}
          type={false}
        />
      )}
      {selectAccountPin === 6 && (
        <TrustFolder
          setCurrentPage={setCurrentPage}
          type={false}
          setSelectAccountPin={setSelectAccountPin}
        />
      )}
    </div>
  );
}

export default DefinePin;
