import { useGlobalContext } from "../Context/Context";
import key from "../picture/Key.png";
import play from "../picture/Play.png";
import axios from "axios";
import "./singleAccount.css";

const SingleAccount = ({ setSelectLocation, setSelectAccount, locations }) => {
  let keyCompany = "";
  const { account } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { setLocation } = useGlobalContext();

  const shiftFocus = (e, index) => {
    const inputs = document.querySelectorAll("input");
    const valueArray = e.currentTarget.value.split("");
    keyCompany += valueArray.toString();
    if (e.currentTarget.value === "" && index === 0) {
      inputs[0].blur();
      return;
    }
    if (e.currentTarget.value === "") {
      inputs[index - 1].focus();
      return;
    }
    if (index === inputs.length - 1) inputs[index].blur();
    else inputs[index + 1].focus();
  };

  const handleEntryEditor = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/companies/checkEntryKey.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          company_id: account.id,
          entry_key: keyCompany,
        }
      );
      console.log(data);
      console.log(keyCompany);
      console.log(locations);
      if (data.status === 200) {
        if (locations.length > 1) {
          setSelectAccount(1);
        } else {
          setSelectAccount(3);
          setLocation(locations[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="single-account">
      <div className="single-account-key">
        <img src={key} alt="" />
        <input type="number" max={1} onChange={(e) => shiftFocus(e, 0)} />
        <input type="number" max={1} onChange={(e) => shiftFocus(e, 1)} />
        <input type="number" max={1} onChange={(e) => shiftFocus(e, 2)} />
        <input type="number" max={1} onChange={(e) => shiftFocus(e, 3)} />
      </div>
      <div className="play-button" onClick={handleEntryEditor}>
        <img src={play} alt="" />
      </div>
      <div className="account-container">
        {account.logo === "" ? (
          <span>{account.name[0].toUpperCase()} </span>
        ) : (
          <img src={account.logo} alt="" />
        )}
        <span>{account.name}</span>
      </div>
    </div>
  );
};

export default SingleAccount;
