import React from "react";
import "./Style.css";
import { useRef } from "react";
import axios from "axios";
import icon from "./icons/icon.png";
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";

const SendMyselfOrTrustedUser = ({
  setCurrentPage,
  setCurrentPagePassword,
}) => {
  const { controlEmail, baseUrl } = useGlobalContext();
  const controlOtherUserEmail = useRef("");

  console.log(controlEmail);

  const handleSendMYCode = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/verification_codes/send_verification_code.php`,
        {
          user_email: controlEmail,
        }
      );
      console.log(data);
      if (data.status === 200) {
        setCurrentPagePassword(1);
      } else {
        setCurrentPage(0);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendOtherCode = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/recovery_codes/sendEmail.php`,
        {
          user_email: controlEmail,
          trusted_contact_email: controlOtherUserEmail.current,
        }
      );
      console.log(data);
      if (data.status === 200) {
        setCurrentPagePassword(1);
      } else {
        setCurrentPage(0);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputOtherUser = (e) => {
    controlOtherUserEmail.current = e.currentTarget.value;
    console.log(controlOtherUserEmail);
  };

  console.log(controlOtherUserEmail);

  return (
    <div className="send-myself-or-trusted-user-container">
      <div className="message-top">
        <p>
          KOD će stići na vasu email adresu, molimo vas da isti unesete u polja
          u prvom pokusaju.
        </p>
      </div>

      <div className="send-code-btn" onClick={handleSendMYCode}>
        <Button activation={true}>POŠALJI MI KOD</Button>
      </div>

      <div className="message-bottom">
        <p>
          KOD ce stici na email adresu navedenog pouzdanog kontakta, molimo vas
          da isti unesete u polja u prvom pokusaju.
        </p>
      </div>

      <div className="enter-contact">
        <img src={icon} alt="" />
        <input
          className="send-trust-user-input"
          type="text"
          onChange={handleInputOtherUser}
        />
      </div>

      <div className="send-code-to-contact-btn" onClick={handleSendOtherCode}>
        <Button activation={true}>POŠALJI KOD KONTAKTU</Button>
      </div>
    </div>
  );
};

export default SendMyselfOrTrustedUser;
