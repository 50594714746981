import "./archive.css";
import {
  compareAsc,
  format,
  getMonth,
  getYear,
  eachDayOfInterval,
} from "date-fns";
import axios from "axios";
import { useEffect, useState } from "react";
import playLeft from "../play-left.png";
import playRight from "../play-right.png";
import Button from "../Button/Button";
import greenComment from "../green-messages.png";
import redComment from "../red-messages.png";
import commentImg from "../message.png";
import userImg from "../korisnik.png";
import imagesImg from "../images.png";
import { useGlobalContext } from "../../Context/Context";

const Archive = ({
  setArchiveClicked,
  setArchiveActive,
  archive,
  setItemList,
  setDetailsChecked,
  daysArchive,
  setDaysArchive,
  setFromDateColumn,
}) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAJ",
    "JUN",
    "JUL",
    "AVG",
    "SEP",
    "OKT",
    "NOV",
    "DEC",
  ];
  let changeColor = true;

  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();

  const isLandscape = window.innerWidth > window.innerHeight;

  const [selected, setSelected] = useState(new Date());

  const handlePrevMonth = () => {
    let newDate;
    if (
      daysArchive[0].getMonth() === selected.getMonth() &&
      selected.getYear() === daysArchive[0].getYear()
    )
      return;
    if (getMonth(selected) - 1 === -1)
      newDate = new Date(`${getYear(selected) - 1}-12-1`);
    else {
      newDate = new Date(
        `${getYear(selected)}-${
          getMonth(selected) - 1 !== 0 ? getMonth(selected) : "1"
        }-1`
      );
    }
    setSelected(newDate);
  };

  const handleNextMonth = () => {
    let newDate;
    if (
      selected.getMonth() === new Date().getMonth() &&
      selected.getYear() === new Date().getYear()
    )
      return;
    if (getMonth(selected) + 1 === 12)
      newDate = new Date(`${getYear(selected) + 1}-1-1`);
    else {
      newDate = new Date(`${getYear(selected)}-${getMonth(selected) + 2}-1`);
    }
    setSelected(newDate);
  };

  const getAllReportsForSelectedDay = (day) => {
    const reports = archive.filter(
      (item) =>
        compareAsc(day, new Date(item.timeCreated).setHours(0, 0, 0, 0)) === 0
    );
    return reports;
  };

  const getCommentsForSelectedReports = (reports) => {
    let comments = [];
    reports.forEach((report) => (comments = [...comments, ...report.comments]));
    return comments;
  };

  const getUsersForSelectedReports = (reports) => {
    let users = [];
    reports.forEach((report) => (users = [...users, ...report.people]));
    return users;
  };

  const checkComments = (comments) => {
    // vraca true ukoliko su svi komentari vidjeni
    let found = comments.find((comment) => !comment.seen);
    if (found !== undefined) return false;
    else return true;
  };

  const handleDateColumnClick = (reports) => {
    console.log(reports);
    try {
      axios
        .post(`${baseUrl}/items/updateSeen.php`, {
          item_id: reports[0].id,
          api_key: apiKey,
          user_id: loggedUser.id,
        })
        .then((data) => {
          console.log(data);
          if (data.data.status === 200) {
            setArchiveActive(true);
            setArchiveClicked(false);
            setItemList(reports);
            setDetailsChecked(true);
            setFromDateColumn(false);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleCommentColumnClick = (reports, allViewed) => {
    try {
      axios
        .post(`${baseUrl}/items/updateSeen.php`, {
          item_id: reports[0].id,
          api_key: apiKey,
          user_id: loggedUser.id,
        })
        .then((data) => {
          console.log(data);
          if (data.data.status === 200) {
            if (allViewed) {
              let newReports = reports.filter(
                (report) => report.comments.length !== 0
              );
              if (newReports.length === 0) return;
              setItemList(newReports);
            } else {
              let unseenCommentReports = reports.filter(
                (report) =>
                  report.comments.find((comment) => !comment.seen) !== undefined
              );

              setItemList(unseenCommentReports);
            }
            setDetailsChecked(true);
            setArchiveActive(true);
            setArchiveClicked(false);
            setFromDateColumn(true);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
    // allViewed -> da li se otvara zeleno ili crveno dugme
  };

  useEffect(() => {
    if (daysArchive !== undefined) {
      const selectedMonth = selected.getMonth();
      const daysInSelectedMonth = daysArchive.filter(
        (day) => day.getMonth() === selectedMonth
      );
      if (daysInSelectedMonth.length !== 0) {
        const elementForScroll = document.getElementById(
          format(
            daysInSelectedMonth[daysInSelectedMonth.length - 1],
            "dd-MM-yyyy"
          )
        );
        elementForScroll.scrollIntoView();
      }
    }
  }, [selected]);

  useEffect(() => {
    const handleFetchDate = () => {
      try {
        axios
          .post(`${baseUrl}/items/getTimeOfCreation.php`, {
            location_id: location.location_id,
            api_key: apiKey,
          })
          .then((data) => {
            console.log(data);
            if (data.status === 200) {
              setDaysArchive(
                eachDayOfInterval({
                  start: new Date(data.data.start),
                  end: new Date(data.data.end),
                })
              );
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };

    handleFetchDate();
  }, []);

  return (
    <div className="archive">
      <div className="archive-header">
        <div className="archive-arrow" onClick={handlePrevMonth}>
          <img className="archive-arrow-img" src={playLeft} alt="" />
        </div>
        <div className="archive-text-month">
          {months[selected.getMonth()]}
          <span>-</span>
          {format(selected, "yyyy")}
        </div>
        <div className="archive-arrow" onClick={handleNextMonth}>
          <img className="archive-arrow-img" src={playRight} alt="" />
        </div>
      </div>
      <div className="archive-list">
        <div className="archive-list-wrapper">
          {daysArchive?.map((day, index) => {
            const reports = getAllReportsForSelectedDay(day);
            const comments = getCommentsForSelectedReports(reports);
            const users = getUsersForSelectedReports(reports);
            const allViewed = checkComments(comments);

            if (index > 0) {
              const month = daysArchive[index].getMonth();
              const lastMonth = daysArchive[index - 1].getMonth();

              if (month !== lastMonth) {
                changeColor = !changeColor;
              }
            }
            return (
              <div className="archive-list-wrapper-item">
                <div
                  className="archive-list-item "
                  key={index}
                  id={format(day, "dd-MM-yyyy")}
                >
                  <div
                    className={`${
                      reports.length === 0
                        ? `archive-list-item-comments not-active ${
                            changeColor ? "odd" : "even"
                          }`
                        : `archive-list-item-comments ${
                            changeColor ? "odd" : "even"
                          }`
                    }`}
                    onClick={() =>
                      handleCommentColumnClick(reports, comments, allViewed)
                    }
                  >
                    <Button activation={true}>
                      <div
                        className={
                          changeColor
                            ? "wrapper-button-archive odd "
                            : "wrapper-button-archive even"
                        }
                      >
                        <span className="comment-number">
                          {reports.length === 0 ? "" : comments.length}
                        </span>
                        <img
                          src={
                            reports.length === 0
                              ? commentImg
                              : reports[0].csu_seen
                              ? greenComment
                              : redComment
                          }
                          alt=""
                          className="comment-img"
                        />
                      </div>
                    </Button>
                  </div>
                  <div
                    className={`${
                      reports.length === 0
                        ? `archive-list-item-date not-active ${
                            changeColor ? "odd" : "even"
                          }`
                        : `archive-list-item-date ${
                            changeColor ? "odd" : "even"
                          }`
                    }`}
                    onClick={() => handleDateColumnClick(reports)}
                  >
                    <Button activation={true}>
                      <span
                        className={
                          changeColor ? "archive-date odd" : "archive-date even"
                        }
                      >
                        {format(day, "dd.MM.yyyy")}
                      </span>
                    </Button>
                  </div>
                </div>
                {isLandscape && (
                  <div className="archive-list-right-item">
                    <div className="archive-list-right-item-box">
                      <div className="circle">{reports.length}</div>
                    </div>
                    <div className="archive-list-right-item-box">
                      <div className="circle">{users.length}</div>
                      <img src={userImg} alt="" />
                    </div>
                    <div className="archive-list-right-item-box">
                      <div className="circle">0</div>
                      <img src={imagesImg} alt="" />
                    </div>
                    <div className="archive-list-right-item-box">
                      <div className="circle">0</div>
                    </div>
                    <div className="archive-list-right-item-box">
                      <div className="circle">0</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Archive;
