import Button from "../Button/Button";
import "./settings.css";
import Back from "../picture/Back.png";
import Pen from "../picture/Pen.png";
import Key from "../picture/Key.png";
import TrustContactSettings from "../picture/TrustContactSettings.png";
import PowerOff from "../picture/PowerOff.png";
const Settings = ({ setSelectAccountPin }) => {
  console.log("settings");

  const handleChangeKey = () => {
    setSelectAccountPin(5);
  };

  const handelChaneTrustContact = () => {
    setSelectAccountPin(6);
  };

  return (
    <div className="wrapper-settings">
      <div className="column-wrapper-settings">
        <div className="settings-button">
          <Button>
            <div className="settings-button-image">
              <img src={Back} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button">
          <Button>
            <div className="settings-button-image">
              <img src={Pen} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button" onClick={handleChangeKey}>
          <Button>
            <div className="settings-button-image">
              <img src={Key} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button">
          <Button />
        </div>
      </div>
      <div className="column-wrapper-settings">
        <div className="settings-button">
          <Button>
            <div className="settings-button-image"></div>
          </Button>
        </div>
        <div className="settings-button">
          <Button>
            <div className="settings-button-image"></div>
          </Button>
        </div>
        <div className="settings-button">
          <Button>
            <div
              className="settings-button-image"
              onClick={handelChaneTrustContact}
            >
              <img src={TrustContactSettings} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button">
          <Button>
            <div className="settings-button-image">
              <img src={PowerOff} alt="" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Settings;
