import { compareAsc, format, getDay, getMonth, getYear } from "date-fns";
import { setDays, weekdays, months } from "./data";
import { useState, useEffect } from "react";
import axios from "axios";
import arrowLeft from "../play-left.png";
import arrowRight from "../play-right.png";
import { useGlobalContext } from "../../Context/Context";
import Slider from "../SliderNew/Slider";
import "./calendarWork.css";

const CalendarWork = ({ timeOfCreate, setTimeOfCreate }) => {
  const [selectedDay, setSelectedDay] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [currentDays, setCurrentDays] = useState(setDays(new Date()));
  const [shifts, setShifts] = useState([]);

  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();

  const renderEmpty = (cond) => {
    const index = cond
      ? getDay(currentDays[0])
      : 6 - getDay(currentDays[currentDays.length - 1]);
    if (index === 0) return;
    let array = Array.from({ length: index });
    return array.map((_, index) => {
      return <div className="calendar-main-item" key={index}></div>;
    });
  };

  const handleNextMonth = () => {
    let newDate;
    if (getMonth(selectedDay) + 1 === 12)
      newDate = new Date(`${getYear(selectedDay) + 1}-1-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDay)}-${getMonth(selectedDay) + 2}-1`
      );
    }
    setSelectedDay(newDate);
    setCurrentDays(setDays(newDate));
  };

  const handlePreviousMonth = () => {
    let newDate;
    if (getMonth(selectedDay) - 1 === -1)
      newDate = new Date(`${getYear(selectedDay) - 1}-12-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDay)}-${
          getMonth(selectedDay) - 1 !== 0 ? getMonth(selectedDay) : "1"
        }-1`
      );
    }
    setSelectedDay(newDate);
    setCurrentDays(setDays(newDate));
  };

  const handleSelectedDay = (selected) => {
    try {
      axios
        .post(`${baseUrl}/shifts/getShiftsByLocation.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          date: selected,
        })
        .then((data) => {
          setShifts(data.data.shifts);
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }

    setSelectedDay(selected);
  };

  const checkIfSelected = (day) => {
    if (compareAsc(day, selectedDay) === 0) return true;
    else return false;
  };

  useEffect(() => {
    handleSelectedDay(new Date());
  }, []);

  return (
    <div className="calendar-wrapper">
      <div className="calendar">
        <div className="calendar-header">
          <img
            src={arrowLeft}
            alt=""
            className="calendar-header-left"
            onClick={handlePreviousMonth}
          />
          <div id="header" className="calendar-header-date">
            <span className="month">{months[getMonth(selectedDay)]}</span>
            <span className="line">-</span>
            <span className="year">{getYear(selectedDay)}</span>
          </div>
          <img
            src={arrowRight}
            alt=""
            className="calendar-header-right"
            onClick={handleNextMonth}
          />
        </div>
        <div className="calendar-main-wrapper">
          <div className="calendar-main">
            {weekdays.map((day, index) => {
              return (
                <span key={index} className="calendar-main-item letter">
                  {day}
                </span>
              );
            })}
            {renderEmpty(true)}
            {currentDays.map((day, index) => {
              return (
                <div
                  className={
                    checkIfSelected(day)
                      ? "calendar-main-item active selected"
                      : "calendar-main-item active"
                  }
                  key={index}
                  onClick={() => handleSelectedDay(day)}
                >
                  <span className="calendar-main-item-day">
                    {format(day, "d").toString().length === 1
                      ? `0${format(day, "d")}`
                      : format(day, "d")}
                  </span>
                </div>
              );
            })}
            {renderEmpty(false)}
          </div>
          <div className="slider-wrapper">
            <Slider selectedShifts={shifts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarWork;
