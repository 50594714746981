import "./pdfViewer.css";
import Button from "../../Button/Button";
import commentImg from "../komentar.png";
import linkImg from "../7.png";
import documentImg from "../dokument.png";
import imageImg from "../slika.png";
import cancelImg from "../zatvaranje.png";
import categoriesImg from "../9.png";
import userImg from "../korisnik.png";
import pdfImg from "../generisi_pdf.png";
import Pdf from "./Pdf";

import ReactPDF, {
  PDFDownloadLink,
  PDFViewer,
  PDFRenderer,
  usePDF,
} from "@react-pdf/renderer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import { CoPresent } from "@mui/icons-material";
import UserFilter from "../Archive/FilterArchive/UserFilter";
import CategoryFilter from "../Archive/FilterArchive/CategoryFilter";
import { compareAsc, format } from "date-fns";
import { MobilePDFReader } from "react-read-pdf";

const PdfViewer = ({
  setPdfActive,
  itemList,
  setItemList,
  isFilter,
  account,
  filterData,
}) => {
  const [openUser, setOpenUser] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [commentsActive, setCommentsActive] = useState(false);
  const [linksActive, setLinksActive] = useState(false);
  const [documentsActive, setDocumentsActive] = useState(false);
  const [imagesActive, setImagesActive] = useState(false);
  const [viewerState, setViewerState] = useState({ people: [], category: [] });
  const [filteredData, setFilteredData] = useState(itemList);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [numberOfCopy, setNumberOfCopy] = useState(0);
  const { location } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { isIOS, isAndroid } = useGlobalContext();
  console.log(linksActive);
  const [myDocument, setMyDocument] = useState(
    <Pdf
      itemList={filteredData}
      imagesActive={imagesActive}
      documentsActive={documentsActive}
      commentsActive={commentsActive}
      linksActive={linksActive}
      account={account}
      location={location}
      loggedUser={loggedUser}
      numberOfCopy={numberOfCopy}
      categoriesItem={categoriesItem}
    />
  );

  const [instance, updateInstance] = usePDF({ document: myDocument });

  console.log(account);
  console.log(itemList);

  const handleImageAdd = async () => {
    const responses = [];
    filteredData.forEach((item) => {
      try {
        const response = axios.post(
          `${baseUrl}/items/getAllImagesByItemId.php`,
          {
            item_id: item.id,
            api_key: apiKey,
            user_id: loggedUser.id,
          }
        );
        responses.push(response);
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    });

    const fullImage = await Promise.all(responses);
    console.log(fullImage);
    const newFilteredData = filteredData.map((item, index) => {
      return {
        ...item,
        images:
          fullImage[index].data.status === 200
            ? fullImage[index].data.images
            : [],
      };
    });
    setFilteredData(newFilteredData);

    setImagesActive(!imagesActive);
  };

  const handleAddDocument = async () => {
    const responses = [];
    filteredData.forEach((item) => {
      try {
        const response = axios.post(
          `${baseUrl}/items/getAllDocumentsForLink.php`,
          {
            item_id: item.id,
            api_key: apiKey,
            user_id: loggedUser.id,
          }
        );
        responses.push(response);
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    });
    const fullDocument = await Promise.all(responses);
    console.log(fullDocument);
    const newFilteredData = filteredData.map((item, index) => {
      return {
        ...item,
        documents:
          fullDocument[index].data.status === 200
            ? fullDocument[index].data.documents
            : [],
      };
    });
    setFilteredData(newFilteredData);
    setMyDocument(
      <Pdf
        itemList={newFilteredData}
        imagesActive={imagesActive}
        documentsActive={documentsActive}
        commentsActive={commentsActive}
        linksActive={linksActive}
        account={account}
        location={location}
        loggedUser={loggedUser}
        numberOfCopy={numberOfCopy}
        categoriesItem={categoriesItem}
      />
    );
    setDocumentsActive(!documentsActive);
  };

  const handleAddComment = async () => {
    const responsesItemList = [];
    let images = [],
      documents = [];
    let responseImages = [],
      responseDocuments = [];
    filteredData.forEach((item) => {
      let responseComments = [],
        response;

      item.comments.forEach((comment) => {
        try {
          response = axios.post(
            `${baseUrl}/items/getAllImagesByCommentId.php`,
            {
              item_id: item.id,
              api_key: apiKey,
              user_id: loggedUser.id,
              comment_id: comment.id,
            }
          );
          responseImages.push({ promise: response, commentId: comment.id });
        } catch (error) {
          console.log("Error fetching messages");
        }

        try {
          response = axios.post(
            `${baseUrl}/items/getAllDocumentsByCommentId.php`,
            {
              item_id: item.id,
              api_key: apiKey,
              user_id: loggedUser.id,
              comment_id: comment.id,
            }
          );
          responseDocuments.push({ promise: response, commentId: comment.id });
        } catch (error) {
          console.log("Error fetching messages");
        }
      });
    });
    images = await Promise.all(responseImages.map((r) => r.promise));
    documents = await Promise.all(responseDocuments.map((r) => r.promise));
    images = images.map((r, index) => {
      return {
        images: r.data.images,
        commentId: responseImages[index].commentId,
      };
    });
    documents = documents.map((r, index) => {
      return {
        documents: r.data.documents,
        commentId: responseDocuments[index].commentId,
      };
    });
    const newItemList = filteredData.map((item) => {
      const newComments = item.comments.map((comment) => {
        let newCommentImage, newCommentDocument;
        newCommentImage = images.find((img) => img.commentId === comment.id);
        newCommentDocument = documents.find(
          (doc) => doc.commentId === comment.id
        );
        console.log(newCommentImage, newCommentDocument);
        return {
          ...comment,
          image: newCommentImage === undefined ? [] : newCommentImage.images,
          document:
            newCommentDocument === undefined
              ? []
              : newCommentDocument.documents !== undefined
              ? newCommentDocument.documents
              : [],
        };
      });
      return { ...item, comments: newComments };
    });
    console.log(newItemList);
    setFilteredData(newItemList);
    setMyDocument(
      <Pdf
        itemList={newItemList}
        imagesActive={imagesActive}
        documentsActive={documentsActive}
        commentsActive={commentsActive}
        linksActive={linksActive}
        account={account}
        location={location}
        loggedUser={loggedUser}
        numberOfCopy={numberOfCopy}
        categoriesItem={categoriesItem}
      />
    );
    setCommentsActive(!commentsActive);
  };

  const handleIncrementCopy = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/reports_counter/incrementCounter.php`,
        {
          location_id: parseInt(location.location_id),
          startDate:
            itemList.length !== 0 &&
            format(itemList[0].timeCreated, "yyyy-MM-dd"),
          endDate:
            itemList.length !== 0 &&
            (compareAsc(
              new Date(new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)),
              new Date(
                new Date(itemList[itemList.length - 1].timeCreated).setHours(
                  0,
                  0,
                  0,
                  0
                )
              )
            ) !== 0
              ? format(itemList[itemList.length - 1].timeCreated, "yyyy-MM-dd")
              : ""),
          user_id: loggedUser.id,
          api_key: apiKey,
        }
      );
      console.log(data);
      if (data.status === 200) {
        console.log("Uspesno ste preuzeli izvestaj");
      }
    } catch (data) {
      console.log(data);
    }
  };

  const handleAddLink = () => {
    const newDocument = (
      <Pdf
        itemList={filteredData}
        imagesActive={imagesActive}
        documentsActive={documentsActive}
        commentsActive={commentsActive}
        linksActive={linksActive}
        account={account}
        location={location}
        loggedUser={loggedUser}
        numberOfCopy={numberOfCopy}
        categoriesItem={categoriesItem}
      />
    );
    setMyDocument(newDocument);

    setLinksActive(!linksActive);
  };

  useEffect(() => {
    const handleGetNumberOfCopy = async () => {
      const sendData = {
        location_id: parseInt(location.location_id),
        startDate:
          itemList.length !== 0 &&
          format(itemList[0].timeCreated, "yyyy-MM-dd"),
        endDate:
          itemList.length !== 0 &&
          (compareAsc(
            new Date(new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)),
            new Date(
              new Date(itemList[itemList.length - 1].timeCreated).setHours(
                0,
                0,
                0,
                0
              )
            )
          ) !== 0
            ? format(itemList[itemList.length - 1].timeCreated, "yyyy-MM-dd")
            : ""),
        user_id: loggedUser.id,
        api_key: apiKey,
      };
      console.log(sendData);

      try {
        const { data } = await axios.post(
          `${baseUrl}/reports_counter/getNumberOfCopy.php`,
          {
            location_id: parseInt(location.location_id),
            startDate:
              itemList.length !== 0 &&
              format(itemList[0].timeCreated, "yyyy-MM-dd"),
            endDate:
              itemList.length !== 0 &&
              (compareAsc(
                new Date(
                  new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)
                ),
                new Date(
                  new Date(itemList[itemList.length - 1].timeCreated).setHours(
                    0,
                    0,
                    0,
                    0
                  )
                )
              ) !== 0
                ? format(
                    itemList[itemList.length - 1].timeCreated,
                    "yyyy-MM-dd"
                  )
                : ""),
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setNumberOfCopy(data.counter);
        } else {
          setNumberOfCopy(1);
        }
      } catch (data) {
        console.log(data);
      }
    };
    handleGetNumberOfCopy();
    const handleSetCategories = async () => {
      try {
        axios
          .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
            location_id: parseInt(location.location_id),
            api_key: apiKey,
            user_id: loggedUser.id,
            item_id: undefined,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              let findCategories = [];
              filteredData.forEach((item) => {
                console.log(item);
                if (item.categories !== undefined && item.length !== 0)
                  findCategories.push(...item.categories);
              });
              findCategories = Array.from(new Set(findCategories));
              let categories = [];
              data.data.categories.forEach((category) => {
                if (
                  findCategories.find((cat) => cat === category.id) !==
                  undefined
                ) {
                  categories.push(category);
                }
              });
              setCategoriesItem(categories);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleSetCategories();

    setMyDocument(
      <Pdf
        itemList={filteredData}
        imagesActive={imagesActive}
        documentsActive={documentsActive}
        commentsActive={commentsActive}
        linksActive={linksActive}
        account={account}
        location={location}
        loggedUser={loggedUser}
        numberOfCopy={numberOfCopy}
        categoriesItem={categoriesItem}
      />
    );
  }, []);

  useEffect(() => {
    console.log("UseEffect");
    let newItemList;
    let filterData = itemList;
    if (viewerState.people.length !== 0) {
      newItemList = [];
      itemList.forEach((item) => {
        const user = viewerState.people.find(
          (user) => user.user_id === item.user_id
        );
        if (user !== undefined) {
          newItemList.push(item);
        }
      });
      filterData = newItemList;
    }
    if (viewerState.category.length !== 0) {
      newItemList = [];
      filterData.forEach((item) => {
        let newCategory;
        item.categories.forEach((category) => {
          newCategory = viewerState.category.find((cat) => cat.id === category);
          if (newCategory !== undefined) {
            newItemList.push(item);
          }
        });
      });
      const filteredNewItemList = Array.from(
        new Set(newItemList.map((item) => item))
      );
      filterData = filteredNewItemList;
    }
    setFilteredData(filterData);
    setCommentsActive(false);
    setDocumentsActive(false);
    setImagesActive(false);
    setLinksActive(false);
  }, [viewerState, setViewerState]);

  console.log(filteredData);
  console.log(location);
  console.log(instance.url);
  console.log(myDocument);
  return (
    <div className={isFilter ? "pdf-viewer-main" : "pdf-viewer-main-filter"}>
      <div className="pdf-viewer-wrapper">
        <div className="pdf-viewer">
          {!isAndroid && !isIOS ? (
            <PDFViewer style={{ height: "100%", width: "100%" }}>
              <Pdf
                itemList={filteredData}
                imagesActive={imagesActive}
                documentsActive={documentsActive}
                commentsActive={commentsActive}
                linksActive={linksActive}
                account={account}
                location={location}
                loggedUser={loggedUser}
                numberOfCopy={numberOfCopy}
                categoriesItem={categoriesItem}
              />
            </PDFViewer>
          ) : instance.loading ? null : (
            <MobilePDFReader url={instance.url} height="90%" width="100%" />
          )}
        </div>
      </div>
      <div className="pdf-viewer-buttons">
        <div className="pdf-viewer-btn" onClick={() => handleAddComment()}>
          <Button activation={true}>
            <div className={`pdf-button ${!commentsActive && "non-active"}`}>
              <img src={commentImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={handleAddLink}>
          <Button activation={true}>
            <div className={`pdf-button ${!linksActive && "non-active"}`}>
              <img src={linkImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => handleAddDocument()}>
          <Button activation={true}>
            <div className={`pdf-button ${!documentsActive && "non-active"}`}>
              <img src={documentImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => handleImageAdd()}>
          <Button activation={true}>
            <div className={`pdf-button ${!imagesActive && "non-active"}`}>
              <img src={imageImg} alt="" />
            </div>
          </Button>
        </div>
      </div>
      <div className="pdf-viewer-buttons">
        <div className="pdf-viewer-btn" onClick={() => setPdfActive(false)}>
          <Button activation={true} image={cancelImg}></Button>
        </div>
        <div
          className="pdf-viewer-btn"
          onClick={() => setOpenChart(!openChart)}
        >
          <Button activation={true} image={categoriesImg}></Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => setOpenUser(!openUser)}>
          <Button activation={true} image={userImg}></Button>
        </div>
        <div className="pdf-viewer-btn">
          <Button activation={true}>
            <div className="pdf-download" onClick={handleIncrementCopy}>
              <PDFDownloadLink
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                document={
                  <Pdf
                    itemList={filteredData}
                    imagesActive={imagesActive}
                    documentsActive={documentsActive}
                    commentsActive={commentsActive}
                    linksActive={linksActive}
                    account={account}
                    location={location}
                    loggedUser={loggedUser}
                    numberOfCopy={numberOfCopy}
                    categoriesItem={categoriesItem}
                  />
                }
                fileName="pdf.pdf"
              >
                <img
                  style={{ height: "60%", width: "60%" }}
                  src={pdfImg}
                  alt=""
                />
              </PDFDownloadLink>
            </div>
          </Button>
        </div>
      </div>
      {openUser && (
        <UserFilter
          filterState={viewerState}
          setFilterState={setViewerState}
          isFromFilter={false}
        />
      )}
      {openChart && (
        <CategoryFilter
          filterState={viewerState}
          setFilterState={setViewerState}
          isFromFilter={false}
        />
      )}
    </div>
  );
};

export default PdfViewer;
