import "./slider.css";
import { useEffect, useRef } from "react";
import leftArrow from "../play-left.png";
import rightArrow from "../play-right.png";

const Slider = ({ selectedShifts }) => {
  let shifts = [],
    startMarker = 0,
    endMarker = 100,
    selectedMarker,
    startMoving, // sluzi da koriguje style.left deo
    isLeftMarkerSelected,
    currentTime,
    previousPosition, // kada se ponovo pomera vec pomeren marker cuva tu poziciju
    currentMovingElement,
    currentShift = undefined,
    currentFill,
    spanLeft = "",
    spanRight = "",
    isMoveValidated; // da li je pomeraj markera bio korektan

  const termWidth = useRef();

  // racunanje sirine jednog podeoka
  // (pretpostavka da traje termin u pocetku 1h)
  const calculateWidthTerm = () => {
    const slider = document
      .querySelector(".slider-line")
      .getBoundingClientRect();
    termWidth.current = slider.width / 24;
  };

  // racuna vreme markera koji se trenutno pomera
  const calculateTime = () => {
    const slider = document
      .querySelector(".slider-line")
      .getBoundingClientRect();
    let timePosition;
    if (currentShift !== undefined) {
      timePosition =
        currentMovingElement.pin.getBoundingClientRect().left +
        currentMovingElement.width / 2;
    } else {
      timePosition =
        selectedMarker.pin.getBoundingClientRect().left +
        selectedMarker.width / 2;
    }
    const correctTime = Math.round(
      (timePosition - slider.left) / termWidth.current
    );
    if (correctTime < 10) currentTime = `0${correctTime}:00`;
    else currentTime = `${correctTime}:00`;
  };

  // kreiranje time spanova na pocetku
  const createTimeSpans = () => {
    const sliderTime = document.querySelector(".slider-time");
    const sliderMarkerStart = document.querySelector(".slider-marker.start");
    let span = document.createElement("span");
    span.innerHTML = "00:00";
    span.classList.add("span-start");
    // korigovanje pozicije
    sliderTime.appendChild(span);
    span.style.left = `${-span.getBoundingClientRect().width / 2}px`;
    span = document.createElement("span");
    span.innerHTML = "24:00";
    span.classList.add("span-end");
    // korigovanje pozicije
    sliderTime.appendChild(span);
    span.style.right = `${-span.getBoundingClientRect().width / 2}px`;
  };

  // koriguje pozicije markera da bude tacno na krajevima slajdera (samo na pocetku)
  const correctMarkerPositions = () => {
    const sliderLeft = document
      .querySelector(".slider-line")
      .getBoundingClientRect().left;
    let markerRect = document.querySelector(".slider-marker.start");
    markerRect.style.left = `${
      markerRect.getBoundingClientRect().left -
      markerRect.getBoundingClientRect().width / 2 -
      sliderLeft
    }px`;
    markerRect = document.querySelector(".slider-marker.end");
    markerRect.style.left = `${
      markerRect.getBoundingClientRect().left +
      markerRect.getBoundingClientRect().width / 2 -
      sliderLeft
    }px`;
  };

  // kada se kreira novi marker korigovanje
  const correctNewMarkerPosition = (marker) => {
    const sliderLeft = document
      .querySelector(".slider-line")
      .getBoundingClientRect().left;
    if (isLeftMarkerSelected) {
      marker.style.left = `${
        marker.getBoundingClientRect().left -
        marker.getBoundingClientRect().width / 2 -
        sliderLeft
      }px`;
    } else {
      marker.style.left = `${
        marker.getBoundingClientRect().left +
        marker.getBoundingClientRect().width / 2 -
        sliderLeft
      }px`;
    }
  };

  // kreira transparentne podeoke
  const fillLines = () => {
    const sliderLine = document.querySelector(".slider-line-podeok");
    // korigovanje markera
    correctMarkerPositions();
    // kreiranje podeoka
    for (let i = 0; i < 24; i++) {
      let div = document.createElement("div");
      div.classList.add("slider-podeok");
      div.style.width = `${termWidth.current}px`;
      sliderLine.appendChild(div);
    }
  };

  const handleStartMoving = (e) => {
    // brisanje kreiranog finog podesavanja
    const arrows = document
      .querySelector(".slider-time")
      .querySelectorAll("img");
    if (arrows.length !== 0) arrows.forEach((arrow) => arrow.remove());
    const sliderMarkers = document.querySelector(".slider-markers");
    previousPosition = e.clientX;
    // provera da li se pomera marker za neku od vec kreiranih smena
    currentShift = shifts.find(
      (shift) =>
        shift.startPin.pin === e.currentTarget ||
        shift.endPin.pin === e.currentTarget
    );
    // provera da li se pomera desni ili levi u smeni (jer se salje e kao parametar)
    if (currentShift !== undefined) currentMovingElement = e.currentTarget;
    // provera da li se pomera levi ili desni marker
    const startPositionMarker =
      e.currentTarget.getBoundingClientRect().left +
      e.currentTarget.getBoundingClientRect().width / 2;
    const startSliderMarkers = sliderMarkers.getBoundingClientRect().left;
    isLeftMarkerSelected =
      Math.abs(Math.round(startSliderMarkers - startPositionMarker)) === 0;
    // setovanje pocetne pozicije
    startMoving =
      sliderMarkers.getBoundingClientRect().x +
      e.currentTarget.getBoundingClientRect().width / 2;
    e.currentTarget.addEventListener("mousemove", handleMoving);
    // hendlovanje korektne boje
    if (e.currentTarget.style.backgroundColor === "")
      settingMarkerColor(e.currentTarget);
  };

  const settingMarkerColor = (marker) => {
    marker.style.backgroundColor = "green";
  };

  const updateTimeSpan = () => {
    let currentSpan, spans;
    if (currentShift !== undefined) {
      // provera da li treba pomeriti desno ili levo vreme od smene
      if (currentMovingElement.pin === currentShift.startPin.pin)
        currentSpan = currentShift.timeLeft;
      else currentSpan = currentShift.timeRight;
      currentSpan.style.left = `${
        currentMovingElement.position -
        currentSpan.getBoundingClientRect().width / 2
      }px`;
      currentSpan.innerHTML = currentTime;
    } else {
      // da li se pomera sa pocetka slajdera
      if (selectedMarker.pin.classList.contains("start")) {
        // pomera se prvi marker
        if (startMarker === 0) {
          spans = [...document.querySelectorAll(".span-start")];
          currentSpan = spans.find(
            (span) => !span.classList.contains("shifted")
          );
          currentSpan.classList.add("moved");
          spanLeft = currentSpan;
        }
        // da li je startni marker i da li je vec pomeran
        else if (selectedMarker.pin === startMarker.pin) {
          currentSpan = document.querySelector(".span-start.moved");
          spanLeft = currentSpan;
        }
        // pomeranje krajnjeg markera sa iste strane slajdera
        else {
          spans = [...document.querySelectorAll(".span-start")];
          currentSpan = spans.find(
            (span) =>
              !span.classList.contains("shifted") &&
              !span.classList.contains("moved")
          );
          spanRight = currentSpan;
        }
        // da li se pomera sa kraja slajdera
      } else {
        // pomera se prvi marker
        if (startMarker === 0) {
          spans = [...document.querySelectorAll(".span-end")];
          currentSpan = spans.find(
            (span) => !span.classList.contains("shifted")
          );
          console.log(spans);
          console.log(currentSpan);
          currentSpan.classList.add("moved");
          spanLeft = currentSpan;
        }
        // da li je krajnji marker i da li je vec pomeran
        else if (selectedMarker.pin === startMarker.pin) {
          currentSpan = document.querySelector(".span-end.moved");
          spanLeft = currentSpan;
        }
        // pomeranje krajnjeg markera sa iste strane slajdera
        else {
          spans = [...document.querySelectorAll(".span-end")];
          currentSpan = spans.find(
            (span) =>
              !span.classList.contains("shifted") &&
              !span.classList.contains("moved")
          );
          spanRight = currentSpan;
        }
      }
      currentSpan.style.left = `${
        selectedMarker.position - currentSpan.getBoundingClientRect().width / 2
      }px`;
      currentSpan.innerHTML = currentTime;
    }
  };

  const updateCurrentFill = () => {
    let width;
    // provera da li se pomera levi ili desni
    if (currentShift.startPin.pin === currentMovingElement.pin) {
      width = Math.abs(
        currentShift.endPin.position - currentMovingElement.position
      );
      currentShift.fill.style.left = `${currentMovingElement.position}px`;
    } else {
      width = Math.abs(
        currentMovingElement.position - currentShift.startPin.position
      );
      currentShift.fill.right = `${currentMovingElement.position}px`;
    }
    currentShift.fill.style.width = `${width}px`;
  };

  const validateMoves = (movingElement) => {
    let isGood = true;
    if (currentShift !== undefined) {
      // DODAJ KOD ZA PROVERU POMERANJA MARKERA VEC KREIRANE SMENE
    } else {
      // provera da li marker upada u vec postojeci termin
      const fills = document.querySelectorAll(".slider-line-fill");
      const rectElement = movingElement.getBoundingClientRect();
      const position = rectElement.left + rectElement.width / 2;
      let rect;
      fills.forEach((fill) => {
        rect = fill.getBoundingClientRect();
        if (rect.left < position && rect.right > position) isGood = false;
      });
      // provera za pomeranje drugog markera
      // da li na odredjenoj poziciji se moze kreirati termin
      if (startMarker !== 0 && startMarker.pin !== movingElement) {
        let correct = undefined,
          prevCorrect = undefined,
          isFound = false;
        // pronalazenje prostora na kome se moze kreirati termin
        if (shifts.length !== 0) {
          for (let i = 0; i < shifts.length && !isFound; i++) {
            if (shifts[i].startPin.position > startMarker.position) {
              correct = shifts[i];
              prevCorrect = shifts[i - 1];
              isFound = true;
            }
          }
          // termin se kreira skroz desno
          if (correct === undefined && prevCorrect === undefined) {
            if (
              shifts[shifts.length - 1].fill.getBoundingClientRect().left >
              position
            )
              isGood = false;
          }
          // termin se kreira skroz levo
          else if (correct !== undefined && prevCorrect === undefined) {
            if (correct.fill.getBoundingClientRect().left < position)
              isGood = false;
          } else {
            if (
              prevCorrect.fill.getBoundingClientRect().right > position ||
              correct.fill.getBoundingClientRect().left < position
            )
              isGood = false;
          }
        }
      }
    }
    return isGood;
  };

  const sortShifts = () => {
    shifts.sort((a, b) => {
      if (a.startPin.position < b.startPin.position) return -1;
      else if (a.startPin.position > b.startPin.position) return 1;
      else return 0;
    });
  };

  const handleMoving = (e) => {
    const sliderMarkers = document.querySelector(".slider-markers");
    const movingElement = e.currentTarget;
    // ogranicenja da ne moze da ide van okvira (i levo i desno)
    if (
      e.clientX - startMoving <
      sliderMarkers.getBoundingClientRect().left - startMoving
    ) {
      movingElement.style.left = `${
        -movingElement.getBoundingClientRect().width / 2
      }px`;
    } else if (
      e.clientX - startMoving >
      sliderMarkers.getBoundingClientRect().right - startMoving
    ) {
      movingElement.style.left = `${
        sliderMarkers.getBoundingClientRect().right - startMoving.current
      }px`;
    } else {
      // provera validnosti poteza
      isMoveValidated = validateMoves(movingElement);
      if (isMoveValidated) movingElement.style.backgroundColor = "green";
      else movingElement.style.backgroundColor = "red";
      // pomeranje ukoliko je izabran neki iz kreirane smene
      movingElement.style.left = `${e.clientX - startMoving}px`;
      const rect = movingElement.getBoundingClientRect();
      // inicijalizacija markera
      if (currentShift !== undefined) {
        currentMovingElement = {
          pin: movingElement,
          width: rect.width,
          position: rect.left + rect.width - startMoving,
        };
        updateCurrentFill();
      } else {
        selectedMarker = {
          pin: movingElement,
          width: rect.width,
          position: rect.left + rect.width - startMoving,
        };
      }
      calculateTime();
      updateTimeSpan();
    }
  };

  const resetValues = () => {
    startMarker = 0;
    endMarker = 100;
  };

  const createNewTimeSpan = () => {
    const sliderTime = document.querySelector(".slider-time");
    let span = document.createElement("span");
    // da li se kreira levi ili desni
    if (isLeftMarkerSelected) {
      span.classList.add("span-start");
      span.innerHTML = "00:00";
    } else {
      span.classList.add("span-end");
      span.innerHTML = "24:00";
    }
    sliderTime.appendChild(span);
    if (isLeftMarkerSelected)
      span.style.left = `${-span.getBoundingClientRect().width / 2}px`;
    else span.style.right = `${-span.getBoundingClientRect().width / 2}px`;
  };

  const createNewMarker = () => {
    // isLeftMarkerSelected -> na kojoj strani slidera ga kreiramo
    const sliderMarkers = document.querySelector(".slider-markers");
    const newMarker = document.createElement("div");
    newMarker.classList.add("slider-marker");
    if (isLeftMarkerSelected) newMarker.classList.add("start");
    else newMarker.classList.add("end");
    newMarker.addEventListener("mousedown", handleStartMoving);
    newMarker.addEventListener("mouseup", handleStopMoving);
    sliderMarkers.appendChild(newMarker);
    // korigovanje pozicije
    correctNewMarkerPosition(newMarker);
    // kreiranje novog spana za vreme
    createNewTimeSpan();
  };

  const createFill = () => {
    const sliderContainer = document.querySelector(".slider-line");
    const sliderLine = document.createElement("div");
    sliderLine.classList.add("slider-line-fill");
    const width = Math.abs(endMarker.position - startMarker.position);
    sliderLine.style.width = `${width}px`;
    // korigovanje u zavisnosti od pozicije markera
    if (endMarker.position > startMarker.position) {
      sliderLine.style.left = `${startMarker.position}px`;
      sliderLine.style.right = `${endMarker.position}px`;
    } else {
      sliderLine.style.left = `${endMarker.position}px`;
      sliderLine.style.right = `${startMarker.position}px`;
    }
    sliderContainer.appendChild(sliderLine);
    currentFill = sliderLine;
  };

  const createSetting = (movingElement) => {
    // kreiranje finog podesavanja za zadnji izabran marker
    const sliderTime = document.querySelector(".slider-time");
    const anySpan = sliderTime.querySelector("span");
    let arrow = document.createElement("img");
    arrow.src = leftArrow;
    sliderTime.appendChild(arrow);
    arrow.style.left = `${
      movingElement.getBoundingClientRect().left -
      startMoving -
      movingElement.getBoundingClientRect().width / 2 -
      anySpan.getBoundingClientRect().width * 0.75
    }px`;
    arrow = document.createElement("img");
    arrow.src = rightArrow;
    sliderTime.appendChild(arrow);
    arrow.style.left = `${
      movingElement.getBoundingClientRect().left -
      startMoving -
      movingElement.getBoundingClientRect().width / 2 +
      anySpan.getBoundingClientRect().width * 1.25
    }px`;
  };

  const handleStopMoving = (e) => {
    e.currentTarget.removeEventListener("mousemove", handleMoving);
    // provera validnosti poteza
    if (isMoveValidated) {
      if (currentShift !== undefined) {
        // provera za azuriranje desnog ili levog pina
        if (currentMovingElement.pin === currentShift.startPin.pin) {
          currentShift.startPin = currentMovingElement;
        } else {
          currentShift.endPin = currentMovingElement;
        }
        shifts.map((shift) => {
          if (shift.fill === currentShift.fill) return currentShift;
          else return shift;
        });
        sortShifts();
      } else {
        // pomeranje startnog  markera
        if (startMarker === 0) {
          startMarker = selectedMarker;
          createNewMarker();
        }
        // novo pomeranje startnog markera bez pomeraja krajnjeg
        if (startMarker !== 0 && startMarker.pin === e.currentTarget)
          startMarker = selectedMarker;
        // pomeranje krajnjeg markera nakon pomeranja startnog
        if (startMarker !== 0 && startMarker.pin !== e.currentTarget) {
          endMarker = selectedMarker;
          createNewMarker();
        }
        // kreiranje nove smene
        if (startMarker !== 0 && endMarker !== 100) createNewShift();
      }
      createSetting(e.currentTarget);
    } else {
      // da li je pomeren marker koji je deo definisane smene
      if (currentShift !== undefined) {
      } else {
        // resetovanje markera
        e.currentTarget.style.backgroundColor = "#808080";
        const slider = document.querySelector(".slider-line");
        if (e.currentTarget.classList.contains("start")) {
          e.currentTarget.style.left = `-${
            e.currentTarget.getBoundingClientRect().width -
            e.currentTarget.getBoundingClientRect().width / 2
          }px`;
        } else {
          e.currentTarget.style.left = `${
            slider.getBoundingClientRect().right -
            slider.getBoundingClientRect().left -
            e.currentTarget.getBoundingClientRect().width / 2
          }px`;
        }
        // resetovanje njegovog time spana
        let timeSpan;
        if (isLeftMarkerSelected) {
          // pronalazak konkretnog time spana
          if (startMarker !== 0) {
            const timeSpans = [...document.querySelectorAll(".span-start")];
            timeSpan = timeSpans.find(
              (ts) =>
                !ts.classList.contains("shifted") &&
                !ts.classList.contains("moved")
            );
          } else {
            timeSpan = document.querySelector(".moved");
          }
          timeSpan.style.left = `-${
            e.currentTarget.getBoundingClientRect().width
          }px`;
          timeSpan.innerHTML = "00:00";
          if (timeSpan.classList.contains("moved") && startMarker === 0)
            timeSpan.classList.remove("moved");
        } else {
          // pronalazak konkretnog time spana
          if (startMarker !== 0) {
            const timeSpans = [...document.querySelectorAll(".span-end")];
            timeSpan = timeSpans.find(
              (ts) =>
                !ts.classList.contains("shifted") &&
                !ts.classList.contains("moved")
            );
          } else {
            timeSpan = document.querySelector(".moved");
          }
          // korigovanje pozicije
          timeSpan.style.left = `${
            slider.getBoundingClientRect().right -
            e.currentTarget.getBoundingClientRect().width -
            slider.getBoundingClientRect().left
          }px`;
          timeSpan.innerHTML = "24:00";
          if (timeSpan.classList.contains("moved") && startMarker === 0)
            timeSpan.classList.remove("moved");
        }
      }
    }
  };

  const createNewShift = () => {
    createFill();
    const newShift = {
      startPin:
        endMarker.position > startMarker.position ? startMarker : endMarker,
      endPin:
        endMarker.position > startMarker.position ? endMarker : startMarker,
      timeLeft: spanLeft,
      timeRight: spanRight,
      fill: currentFill,
    };
    // korigovanje classNames
    spanLeft.classList.remove("moved");
    spanRight.classList.remove("moved");
    spanLeft.classList.add("shifted");
    spanRight.classList.add("shifted");
    shifts.push(newShift);
    console.log(shifts);
    sortShifts();
    resetValues();
  };

  const createSelectedShifts = () => {
    const sliderMarkers = document.querySelectorAll(".slider-markers");
    const sliderMarkersTop = sliderMarkers[0];
    const sliderMarkersBottom = sliderMarkers[1];
    const sliderTime = document.querySelectorAll(".slider-time");
    const sliderTimeTop = sliderTime[0];
    const sliderTimeBottom = sliderTime[1];
    let marker,
      span,
      position,
      shiftHours,
      spanStartPosition,
      spanEndPosition,
      newShift = {
        startPin: "",
        endPin: "",
        fill: "",
        timeLeft: "",
        timeRight: "",
      };
    const fill = document.querySelectorAll(".slider-line-fill");
    if (fill.length !== 0) fill.forEach((f) => f.remove());

    while (sliderMarkersTop.hasChildNodes()) {
      sliderMarkersTop.removeChild(sliderMarkersTop.firstChild);
    }
    while (sliderTimeTop.hasChildNodes()) {
      sliderTimeTop.removeChild(sliderTimeTop.firstChild);
    }

    while (sliderMarkersBottom.hasChildNodes()) {
      sliderMarkersBottom.removeChild(sliderMarkersBottom.firstChild);
    }
    while (sliderTimeBottom.hasChildNodes()) {
      sliderTimeBottom.removeChild(sliderTimeBottom.firstChild);
    }
    selectedShifts.forEach((shift) => {
      // kreiranje markera za trenutni shift
      marker = document.createElement("div");
      marker.classList.add("slider-marker");
      marker.classList.add("start");
      shiftHours = parseInt(shift.shift_start.split(":")[0]);
      position = termWidth.current * shiftHours;
      //marker.addEventListener("mousedown", handleStartMoving);
      //marker.addEventListener("mouseup", handleStopMoving);
      sliderMarkersTop.appendChild(marker);
      marker.style.backgroundColor = "green";
      marker.style.left = `${
        position - marker.getBoundingClientRect().width / 2
      }px`;
      newShift.startPin = marker;
      startMarker = marker;
      spanStartPosition = position;
      marker = document.createElement("div");
      marker.classList.add("slider-marker");
      marker.classList.add("end");
      shiftHours = parseInt(shift.shift_end.split(":")[0]);
      position = termWidth.current * shiftHours;
      //marker.addEventListener("mousedown", handleStartMoving);
      //marker.addEventListener("mouseup", handleStopMoving);
      sliderMarkersBottom.appendChild(marker);
      marker.style.backgroundColor = "green";
      marker.style.left = `${
        position - marker.getBoundingClientRect().width / 2
      }px`;
      newShift.endPin = marker;
      endMarker = marker;
      spanEndPosition = position;
      // kreiranje popune za trenutni shift
      const sliderContainer = document.querySelector(".slider-line");
      const sliderLine = document.createElement("div");
      sliderLine.classList.add("slider-line-fill");
      const width = spanEndPosition - spanStartPosition;
      sliderLine.style.width = `${width}px`;
      sliderLine.style.left = `${spanStartPosition}px`;
      sliderLine.style.right = `${spanEndPosition}px`;
      sliderContainer.appendChild(sliderLine);
      newShift.fill = sliderLine;
      // kreiranje time spanova za trenutni shift
      span = document.createElement("span");
      span.innerHTML = `${shift.shift_start}`;
      span.classList.add("span-start");
      span.classList.add("shifted");
      sliderTimeTop.appendChild(span);
      newShift.timeLeft = span;
      span.style.left = `${
        spanStartPosition - span.getBoundingClientRect().width / 2
      }px`;
      span = document.createElement("span");
      span.innerHTML = `${shift.shift_end}`;
      span.classList.add("span-end");
      span.classList.add("shifted");
      sliderTimeBottom.appendChild(span);
      newShift.timeRight = span;
      span.style.left = `${
        spanEndPosition - span.getBoundingClientRect().width / 2
      }px`;
      shifts.push(newShift);
    });
  };

  useEffect(() => {
    calculateWidthTerm();
    createTimeSpans();
    fillLines();
  }, []);

  useEffect(() => {
    if (selectedShifts.length !== 0) createSelectedShifts();
    if (shifts.length !== 0) console.log(shifts);
  }, [selectedShifts]);

  return (
    <div className="slider">
      <div className="slider-time" />
      <div className="slider-markers">
        <div className="slider-marker start" />
      </div>
      <div className="slider-line">
        <div className="slider-line-podeok" />
      </div>
      <div className="slider-markers">
        <div className="slider-marker end" />
      </div>
      <div className="slider-time" />
    </div>
  );
};

export default Slider;
