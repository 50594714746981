import Button from "../../Button/Button";
import "./documentLinkComment.css";
import addDocument from "../../plus.png";
import { useState } from "react";
import link from "../../link.png";
import document from "../../dokument.png";
import { useGlobalContext } from "../../../Context/Context";

const DocumentLinkComment = ({
  itemComment,
  setItemComment,
  commentImageActive,
}) => {
  const { setOpenAddLink } = useGlobalContext();

  // isCOmmenting === true -> dodavanje komentara
  // attachModalActive === undefined => dodavanje stavke
  // !isCommenting -> citanje stavke
  // isCommenting === undefined -> citanje komentara

  const maxFileNameLength = 40;
  const [openLink, setOpenLink] = useState(false);

  const handleInputLink = (e) => {
    setItemComment({ ...itemComment, link: e.currentTarget.value });
  };

  const handleInputDocument = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setItemComment({
        ...itemComment,
        document: { data: reader.result, name: e.target.files[0].name },
      })
    );
    reader.readAsDataURL(e.target.files[0]);
  };
  console.log(itemComment);
  const handleOpenLink = () => {
    setOpenLink(!openLink);
  };

  return (
    <div className="image-document-comment-wrapper">
      {itemComment.document !== "" && (
        <a
          className="download-link"
          download={itemComment.document?.name}
          href={itemComment.document?.data}
        >
          <img src={document} alt="" />
          {itemComment.document?.name?.length > maxFileNameLength
            ? `${itemComment.document.name.slice(0, maxFileNameLength)}...`
            : itemComment.document.name}
        </a>
      )}
      <span className="image-modal-span">* Dozvoljeno 1 dokument i 1 link</span>
      <span className="image-modal-span">
        * Dozvoljeni formati: PDF, DOCX...
      </span>
      <span className="image-modal-span">* Link se dodaje kopiranjem</span>
      <div className="wrapper-link-button">
        {openLink ? (
          <input
            defaultValue={itemComment.link}
            type="text"
            onChange={handleInputLink}
          />
        ) : (
          <span>{itemComment.link}</span>
        )}
        <div className="document-link-add">
          <div className="document-add">
            <label className="button-image-document-link" htmlFor="file-upload">
              <img src={addDocument} alt="" className="" />
              <img src={document} alt="" className="" />
            </label>
            <input
              accept="application/*"
              id="file-upload"
              type="file"
              onChange={handleInputDocument}
            />
          </div>
          <div className="document-add" onClick={handleOpenLink}>
            <Button
              active={itemComment.link === "" && openLink}
              activation={true}
            >
              <div className="button-image-document-link">
                <img src={addDocument} alt="" className="" />
                <img src={link} alt="" className="" />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentLinkComment;
