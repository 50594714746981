import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../Context/Context";
import axios from "axios";
import "./languageApp.css";
import Button from "../../Button/Button";
import Plus from "../../picture/Plus.png";
// Components
import SingleLanguageField from "../SingleLanguageField/SingleLanguageField";
import LanguagesHeader from "../LanguagesHeader/LanguagesHeader";
import LanguageFlag from "../LangugageFlag/LanguageFlag";
import LanguageChoseFlag from "../LanguageChoseFlag/LanguageChoseFlag";
import LanguageTranslate from "../LanguageTranslate/LanguageTranslate";
import LanguageTranslateTable from "../LanguageTranslateTable/LanguageTranslateTable";

const LanguageApp = ({ setCurrentPageGlobal, setSelectAccount }) => {
  const [languages, setLanguages] = useState([]);
  const [translateID, setTranslateID] = useState(0);
  const [translateLanguage, setTranslateLanguage] = useState();
  const [chosenFlag, setChosenFlag] = useState();
  const inputName = useRef();
  // const [newLanguage, setNewLanguage] = useState("");
  //https://data.nadzor.app/api
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  console.log(baseUrl);
  console.log(loggedUser);
  console.log(apiKey);
  const [currentPage, setCurrentPage] = useState(0);

  console.log(languages);

  useEffect(() => {
    const requestBody = {
      user_id: loggedUser?.id,
      api_key: apiKey,
    };

    axios
      .post(`${baseUrl}/languages/read.php`, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data);
          setLanguages(response.data.data);
        } else {
          console.error("Failed to fetch languages:", response.data.status);
        }
      })
      .catch((error) => console.error("Error fetching languages:", error));
    inputName.current = "";
  }, []);

  const handleChoseFlag = () => {
    setCurrentPage(2);
  };

  const handleInputText = (e) => {
    inputName.current = e.currentTarget.value;
  };

  const handleSendNewFlag = async () => {
    try {
      await axios
        .post(`${baseUrl}/languages/createLang.php`, {
          flag_name: inputName.current,
          flag_id: chosenFlag.flag_id,
          flag_image: chosenFlag.image,
        })
        .then((value) => {
          if (value.data.status === 200) {
            console.log("Postavljam nove promenljive");
            const currenLanguage = {
              lang_id: value.data.language_id,
              lang_flag: chosenFlag.flag_id,
              lang_name: inputName.current,
              lang_flag_base64: chosenFlag.image,
            };
            let newLanguages = [...languages, currenLanguage];
            setLanguages(newLanguages);
            setChosenFlag(undefined);
            inputName.current = "";
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LanguagesHeader
        shareBack={currentPage === 0 ? false : true}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      {currentPage === 0 && (
        <LanguageFlag
          setCurrentPage={setCurrentPage}
          setCurrentPageGlobal={setCurrentPageGlobal}
          setSelectAccount={setSelectAccount}
        />
      )}
      {currentPage === 1 && (
        <div className="languages-container">
          <div className="language-section">
            <div className="edit-button" onClick={handleChoseFlag}>
              <Button
                image={chosenFlag !== undefined ? chosenFlag.image : Plus}
              ></Button>
            </div>
            <div className="language-text-app">
              <input type="text" onChange={handleInputText} />
            </div>
            <div className="edit-button" onClick={handleSendNewFlag}>
              <Button activation={true} image={Plus}></Button>
            </div>
          </div>
          <div className="language-list">
            {languages.map((lang) => {
              console.log(lang);
              return (
                <SingleLanguageField
                  id={lang.lang_id}
                  name={lang.lang_name.toUpperCase()}
                  image={lang.lang_flag_base64}
                  setCurrentPage={setCurrentPage}
                  setTranslateLanguage={setTranslateLanguage}
                />
              );
            })}
          </div>
        </div>
      )}
      {currentPage === 2 && (
        <LanguageChoseFlag
          setCurrentPage={setCurrentPage}
          setChosenFlag={setChosenFlag}
        />
      )}
      {currentPage === 3 && (
        <LanguageTranslate
          translateLanguage={translateLanguage}
          setCurrentPage={setCurrentPage}
          setTranslateID={setTranslateID}
        />
      )}
      {currentPage === 4 && (
        <LanguageTranslateTable translateID={translateID} />
      )}
    </>
  );
};

export default LanguageApp;
