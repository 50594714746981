import { useState } from "react";
import "./homePage.css";
import Company from "../picture/Company.png";
import WwwLink from "../picture/WwwLink.png";
import PdfItem from "../picture/PdfItem.png";
import PeopleHome from "../picture/PeopleHome.png";
import LanguageHome from "../picture/LanguageHome.png";
import WwwData from "../picture/WwwData.png";
import AddPeople from "../picture/AddPeople.png";
import AddMessage from "../picture/AddMessage.png";
import LogOut from "../picture/LogOut.png";
import SearchHome from "../picture/SearchHome.png";

import Button from "../Button/Button";

const HomePage = ({ setSelectAccount = { setSelectAccount } }) => {
  const array = [
    { image: Company },
    { image: WwwLink },
    { image: PdfItem },
    { image: PeopleHome },
    { image: LanguageHome },
    { image: WwwData },
    { image: AddPeople },
    { image: AddMessage },
    { image: LogOut },
    { image: SearchHome },
  ];

  const handleChooseItem = (index) => {
    console.log(index);
    setSelectAccount(index + 6);
  };

  return (
    <div className="home-page">
      {array.map((item, index) => {
        return (
          <div
            className="wrapper-item-button"
            onClick={() => handleChooseItem(index)}
          >
            <Button image={item.image}></Button>
          </div>
        );
      })}
    </div>
  );
};

export default HomePage;
