import "./languageTranslateTable.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../Context/Context";

const LanguageTranslateTable = ({ translateID }) => {
  const [allWords, setAllWords] = useState([]);
  const [translations, setTranslations] = useState({});
  const { selectedLanguage, loggedUser } = useGlobalContext();
  const { baseUrl } = useGlobalContext();

  console.log(selectedLanguage);
  console.log(loggedUser);

  useEffect(() => {
    const fetchWords = () => {
      axios
        .post(`${baseUrl}/all_words/getWordsByLanguage.php`, {
          language: selectedLanguage.name,
        })
        .then(function (response) {
          setAllWords(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    fetchWords();
  }, [localStorage.name]);

  const handleInputChange = (id, value) => {
    setTranslations({
      ...translations,
      [id]: value,
    });
  };

  const handleSaveChanges = () => {
    Object.keys(translations).forEach((id) => {
      axios
        .post(`${baseUrl}/translation_users/createWord.php`, {
          aw_id: id,
          word: translations[id],
          sentBy: loggedUser.user_email,
          translation_id: translateID,
        })
        .then(function (response) {
          console.log(response.data);
          setAllWords((prevWords) =>
            prevWords.map((word) =>
              word.aw_id === parseInt(id)
                ? { ...word, word: translations[id] }
                : word
            )
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const renderInputField = (logged_user, item) => {
    const inputElement = (
      <input
        placeholder="Translation"
        type="text"
        value={translations[item.aw_id] || ""}
        onChange={(e) => handleInputChange(item.aw_id, e.target.value)}
      />
    );

    if (logged_user === localStorage.getItem("initiator")) {
      return (
        <>
          <td>{inputElement}</td>
          <td>
            <h5></h5>
          </td>
          <td>
            <h5></h5>
          </td>
        </>
      );
    } else if (logged_user === localStorage.getItem("user1")) {
      return (
        <>
          <td>
            <h5></h5>
          </td>
          <td>{inputElement}</td>
          <td>
            <h5></h5>
          </td>
        </>
      );
    } else if (logged_user === localStorage.getItem("user2")) {
      return (
        <>
          <td>
            <h5></h5>
          </td>
          <td>
            <h5></h5>
          </td>
          <td>{inputElement}</td>
        </>
      );
    } else {
      return (
        <>
          <td>
            <h5></h5>
          </td>
          <td>
            <h5></h5>
          </td>
          <td>
            <h5></h5>
          </td>
        </>
      );
    }
  };

  return (
    <div className="wrapper-language-translate-table">
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>{selectedLanguage.name.toUpperCase()}</th>
            <th>{localStorage.getItem("initiator")}</th>
            <th>{localStorage.getItem("user1")}</th>
            <th>{localStorage.getItem("user2")}</th>
          </tr>
        </thead>
        <tbody>
          {allWords.map((item) => (
            <tr key={item.aw_id}>
              <td>{item.aw_id}</td>
              <td>{item.word}</td>
              {renderInputField(localStorage.getItem("logged_user"), item)}
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleSaveChanges}>Save</button>
    </div>
  );
};

export default LanguageTranslateTable;
