import Button from "../Button/Button";
import "./categoryModal.css";
import { useEffect, useRef, useState } from "react";
const CategoryModal = ({
  itemData,
  setItemData,
  isAdding,
  categoryModalActive,
  order,
}) => {
  console.log(itemData);
  console.log(order);
  const settingLocalCategory = () => {
    console.log("Poziv metode");
    if (categoryModalActive === undefined) {
      return itemData?.categories;
    }

    if (categoryModalActive === true) {
      return itemData;
    }
    /*
        if (imageModalActive.isCommenting) {
          return realItemData.images;
        }
    
        if (imageModalActive.isCommenting === undefined) {
          return [
            itemData?.comments !== undefined
              ? itemData.comments[imageModalCommentActive.value].image
              : realItemData.images,
          ];
        }
    
        if (imageModalActive.isCommenting) {
          return realItemData.images;
        } else {
          return itemData.images;
        }*/
    return [];
  };

  const [localCategory, setLocalCategory] = useState(settingLocalCategory());

  console.log(localCategory);
  console.log(itemData);

  const handleSelectCategory = (index) => {
    const newItemCategories = itemData.categories.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      } else return item;
    });
    console.log(newItemCategories);
    setItemData({ ...itemData, categories: newItemCategories });
  };

  console.log(itemData);

  return (
    <div className="category-modal">
      {localCategory?.map((category, index) => {
        console.log(category);
        return (
          <div
            className="category-modal-item-button"
            onClick={() => handleSelectCategory(index)}
          >
            <Button active={category.selected}>
              <div className="category-modal-item">
                <div className="category-modal-item-color">
                  <div
                    className="category-modal-item-color-letter"
                    style={{ backgroundColor: category.color }}
                  >
                    <div
                      className="category-modal-item-letter"
                      style={{ color: category.letter_color }}
                    >
                      {category.letter}
                    </div>
                  </div>
                </div>
                <div className="category-modal-item-name">
                  {category.name.toUpperCase()}
                </div>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryModal;
