import React, { useState, useEffect, useContext } from "react";
import "./logoPage.css";
import android from "../picture/Android.png";
import apple from "../picture/Apple.png";
import logo from "../picture/Logo.png";
import youtube from "../picture/Youtube.png";
import www from "../picture/Www.png";
import { useGlobalContext } from "../Context/Context";

const LogoPage = ({ setCurrentPage }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallVisible, setIsInstallVisible] = useState(false);
  const { isAndroid, setIsAndroid, isIOS, setIsIOS } = useGlobalContext();

  useEffect(() => {
    const interval = setTimeout(() => {
      setCurrentPage(1);
    }, 4000);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Detect the user's device
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/android/.test(userAgent)) {
      setIsAndroid(true);
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      setIsIOS(true);
    }

    return () => {
      clearTimeout(interval);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [setCurrentPage]);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === "accepted") {
      console.log("User accepted the install prompt");
    } else {
      console.log("User dismissed the install prompt");
    }
    setDeferredPrompt(null);
    setIsInstallVisible(false);
  };

  return (
    <div className="wrapper-logo">
      <div className="android-apple">
        <img
          src={apple}
          alt="Install on iOS"
          onClick={handleInstallClick}
          className={isIOS ? "glow" : ""}
        />
        <img
          src={android}
          alt="Install on Android"
          onClick={handleInstallClick}
          className={isAndroid ? "glow" : ""}
        />
      </div>
      <div
        className="logo"
        onClick={() => setCurrentPage(1)}
        onTouchStart={() => setCurrentPage(1)}
      >
        <img src={logo} alt="Logo" />
      </div>
      <div className="youtube-link">
        <img src={youtube} alt="Youtube" />
        <img src={www} alt="Website" />
        <img src={youtube} alt="Youtube" />
      </div>
    </div>
  );
};

export default LogoPage;
