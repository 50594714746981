import React from 'react';
import './singleLanguageField.css';
import Button from "../../Button/Button"

// Icons 
import Edit from "./icons/edit.png";

function SingleLanguageField({ id, name, image,setCurrentPage,setTranslateLanguage }) {
  const handleOpenLanguage = () => 
    {
      setCurrentPage(3);
      setTranslateLanguage(id);
      console.log("Upisujem id za translate",id)
    }
  return (
    <div className="language-section">
      <div className="flag-button">
      <button className="flag-button" style={{ backgroundImage: `url(${image})` }}></button>
      </div>
      <div className="language-text"onClick={handleOpenLanguage}>
      <Button >{name}</Button>
      </div>
      <div className="edit-button" onClick={handleOpenLanguage}>
      <Button >
        <img src={Edit} />
      </Button>
      </div>
    </div>
  );
}

export default SingleLanguageField;
