import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import plane from "./icons/plane.png";
import planeLight from "./icons/plane-light.png"; // Import the light version of the plane image
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";
import axios from "axios";

function EnterCode({setCurrentPage}) {
  const {controlEmail,baseUrl,setLoggedUser,setApiKey} = useGlobalContext();
  const [inputValues, setInputValues] = useState(["", "", ""]);
  const [isCorrect, setIsCorrect] = useState(false);
  const inputsRef = useRef([]);

  // Handle input value change
  const handleInputChange = (index, value) => {
    if (value === " ") return; // Ignore space character

    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (index < inputsRef.current.length - 1 && value !== "") {
      inputsRef.current[index + 1].focus();
    }
  };

  // Handle backspace key press
  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && inputsRef.current[index].value === "") {
      inputsRef.current[index - 1].focus();
    }
  };

  // Handle button click
  const handleButtonClick =async () => {
    const enteredCode = inputValues.join("");
    let inputValuesString =""
    inputValues.forEach((value)=>{
      inputValuesString +=value
    })
    try {
      const { data } = await axios.post(
        `${baseUrl}/recovery_codes/validateCode.php`,
        {
          user_email: controlEmail,
          code : inputValuesString
        }
      );
      console.log(data)
      if (data.status === 200) {
        const mailUser = {
          id: 0,
          user_email: controlEmail,
          user_pin: "" ,
          isTemp: data.pattern_use,
          pattern: data.user_pattern,
          privilage: "",
          firstContact: "",
          secondContact: "",
        };

        setApiKey(data.api_key);
        setLoggedUser(mailUser);
        setCurrentPage(4);
        setIsCorrect(true);
      }
      else
      {
        setCurrentPage(0)
      }
      console.log(data)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="enter-code-container">
      <h1>UNESI KOD</h1>
      <div className="input-group">
        {[...Array(3)].map((_, index) => (
          <input
            className="enter-code-input"
            type="text"
            maxLength="1"
            key={index}
            ref={(input) => (inputsRef.current[index] = input)}
            value={inputValues[index]}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
          />
        ))}
      </div>
      <div className="confirm-code-button" onClick={handleButtonClick}>
        <Button activation={true}  >
          <img src={isCorrect ? planeLight : plane} alt="" />
        </Button>
      </div>

      <div className="message-verif-code">
        <p>
          Unesite KOD koji je upravo stigao na email adresu sa kojom ste
          registrovali ovaj nalog.
        </p>
      </div>
    </div>
  );
}

export default EnterCode;
