import { useState, useEffect } from "react";
import "./filterCalendar.css";
import { compareAsc, format, getDay, getMonth, getYear } from "date-fns";
import { currentDate, setDays, weekdays, months, years } from "./dataCalendar";
import Button from "../Button/Button";

const FilterCalendar = ({ dates, setDates, isFrom, setOpenCalendar }) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedDays, setSelectedDays] = useState(setDays(selectedDate));
  const [chooseMonth, setChooseMonth] = useState(false);
  const [chooseYear, setChooseYear] = useState(false);

  const handleSelectedDate = (day) => {
    // buducnost
    //if (compareAsc(day, new Date()) === 1) return;
    if (!(dates.from === "" && dates.to === "")) {
      // prvi je izabran, vrsi se odabir drugog
      if (!isFrom && dates.to === "" && compareAsc(day, dates.from) === -1)
        return;
      // drugi je izabran, vrsi se odabir prvog
      if (isFrom && dates.from === "" && compareAsc(day, dates.to) === 1)
        return;
    }
    setSelectedDate(day);
    const newDate = { ...dates, [`${isFrom ? "from" : "to"}`]: day };
    setDates(newDate);
    setOpenCalendar(null);
    setChooseMonth(false);
    setChooseYear(false);
  };

  const renderEmpty = (cond) => {
    const index = cond
      ? getDay(selectedDays[0])
      : 6 - getDay(selectedDays[selectedDays.length - 1]);
    if (index === 0) return;
    let array = Array.from({ length: index });
    return array.map((_, index) => {
      return (
        <div className="calendar-day" key={index}>
          <Button />
        </div>
      );
    });
  };

  const setStates = (date) => {
    setSelectedDate(date);
    setSelectedDays(setDays(date));
    setChooseMonth(false);
    setChooseYear(false);
  };

  const handlePrevMonth = () => {
    let newDate;
    if (getMonth(selectedDate) - 1 === -1)
      newDate = new Date(`${getYear(selectedDate) - 1}-12-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDate)}-${
          getMonth(selectedDate) - 1 !== 0 ? getMonth(selectedDate) : "1"
        }-1`
      );
    }
    //newDate.setHours(0, 0, 0, 0);
    setStates(newDate);
  };

  const handleNextMonth = () => {
    let newDate;
    if (getMonth(selectedDate) + 1 === 12)
      newDate = new Date(`${getYear(selectedDate) + 1}-1-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDate)}-${getMonth(selectedDate) + 2}-1`
      );
    }
    //newDate.setHours(0, 0, 0, 0);
    setStates(newDate);
  };

  const handleMonth = () => {
    setChooseMonth(!chooseMonth);
    setChooseYear(false);
  };

  const handleYear = () => {
    setChooseYear(!chooseYear);
    setChooseMonth(false);
  };

  const handleChooseMonth = (index) => {
    const newDate = new Date(`${getYear(selectedDate)}-${index + 1}-1`);
    setStates(newDate);
  };

  const handleChooseYear = (year) => {
    const newDate = new Date(`${year}-1-1`);
    setStates(newDate);
  };

  useEffect(() => {
    if (chooseYear) {
      document.getElementById(`${getYear(selectedDate)}`).scrollIntoView();
    }
  }, [chooseYear]);

  return (
    <div className="event-calendar-filter">
      <div className="calendar-filter">
        <div className="calendar-filter-options">
          <div className="calendar-filter-options-div" onClick={handleMonth}>
            <Button>M</Button>
          </div>
          <div
            className="calendar-filter-options-div"
            onClick={handlePrevMonth}
          >
            <Button activation={true}>{"<"}</Button>
          </div>
          <h4>{format(selectedDate, "MMMM yyyy")}</h4>
          <div
            className="calendar-filter-options-div"
            onClick={handleNextMonth}
          >
            <Button activation={true}>{">"}</Button>
          </div>
          <div className="calendar-filter-options-div" onClick={handleYear}>
            <Button>Y</Button>
          </div>
          {chooseMonth && (
            <div className="choose-month">
              {months.map((month, index) => {
                return (
                  <div
                    key={index}
                    className={
                      index === getMonth(selectedDate)
                        ? "month active"
                        : "month"
                    }
                    onClick={() => handleChooseMonth(index)}
                  >
                    <Button active={index === getMonth(selectedDate)}>
                      {month}
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
          {chooseYear && (
            <div className="choose-year-wrapper">
              <div className="choose-year">
                {years.map((year, index) => {
                  return (
                    <div
                      id={year}
                      key={index}
                      className={
                        year === getYear(selectedDate).toString()
                          ? "year active"
                          : "year"
                      }
                      onClick={() => handleChooseYear(year)}
                    >
                      <Button
                        active={year === getYear(selectedDate).toString()}
                      >
                        {year}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="calendar-filter-wrapper">
          {weekdays.map((day) => {
            return (
              <div className="editor-calendar-header" key={day}>
                {day}
              </div>
            );
          })}
          {renderEmpty(true)}
          {selectedDays.map((day, index) => {
            return (
              <div
                className={
                  compareAsc(day, currentDate) === 0
                    ? "calendar-day today"
                    : compareAsc(day, selectedDate) === 0
                    ? "calendar-day active"
                    : "calendar-day"
                }
                key={index}
                onClick={() => handleSelectedDate(day)}
              >
                <Button
                  active={compareAsc(day, currentDate) === 0}
                  activation={!(compareAsc(day, currentDate) === 0)}
                >
                  {format(day, "d")}
                </Button>
              </div>
            );
          })}
          {renderEmpty(false)}
        </div>
      </div>
    </div>
  );
};

export default FilterCalendar;
