import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "40 20",
  },

  textArea: {
    marginTop: 10,
    textAlign: "center",
    fontSize: "10",
  },
});

function Pdf({ text }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.textArea}>{text}</Text>
      </Page>
    </Document>
  );
}

export default Pdf;
