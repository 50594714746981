import imageImg from "../../slika.png";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "../../Button/Button";
import attachImg from "../../prilog.png";
import closeImg from "../../zatvaranje.png";
import sendImg from "../../slanje.png";
import messageImg from "../../komentar.png";
import editImg from "../../olovka.png";
import { useState } from "react";
import "./imageModalComment.css";
import ImageModal from "./ImageModal";
import ImagePopupComment from "./ImagePopupComment";
import DocumentLinkComment from "../DocumentLinkComment/DocumentLinkComment";
import axios from "axios";
import { useGlobalContext } from "../../../Context/Context";

const ImageModalComment = ({
  index,
  itemData, // itemList
  setItemData,
  loggedUser,
  name,
  itemList,
  setItemList,
  imageModalActive,
  setImageModalActive,
  user_id,
  realItemData,
}) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();

  const [commentActive, setCommentActive] = useState({
    value: false,
    index: 0,
  });
  const [commentImageActive, setCommentImageActive] = useState(false);
  const [attachCommentActive, setAttachCommentActive] = useState(false);
  const [itemComment, setItemComment] = useState({
    document: "",
    image: "",
    link: "",
    seen: false,
    text: "",
    timeCreated: "",
    tagged_users: [],
    user: loggedUser.user_email,
  });

  const downloadStyles = {
    height: "100%",
    width: "100%",
  };

  const handleCloseComment = () => {
    const text = document.querySelector(".text-area-comment");
    setCommentActive({ ...commentActive, value: false });
    setCommentImageActive(false);
    text.value = "";
    const imageModal = document.querySelector(".image-modal-wrapper");
    const buttons = document.querySelector(".comment-active-buttons");
    buttons.classList.remove("fixed");
    imageModal.style.overflowY = "auto";
  };

  const handleActiveComment = (index) => {
    setCommentActive({ value: true, index });
  };

  const handleCommentImage = () => {
    // if (commentActive.value) return;
    const imageModal = document.querySelector(".image-modal-wrapper");
    const buttons = document.querySelector(".comment-active-buttons");
    if (commentImageActive) {
      buttons.classList.remove("fixed");
      imageModal.style.overflowY = "auto";
    } else {
      imageModal.style.overflowY = "hidden";
      imageModal.scroll(0, 0);
      buttons.classList.add("fixed");
    }
    setCommentImageActive(!commentImageActive);
  };

  const handleCommentAttach = () => {
    // if (commentActive.value) return;
    const documentModal = document.querySelector(".image-modal-wrapper");
    const buttons = document.querySelector(".comment-active-buttons");
    console.log(documentModal);

    if (attachCommentActive) {
      buttons.classList.remove("fixed");
      documentModal.style.overflowY = "auto";
    } else {
      documentModal.style.overflowY = "hidden";
      documentModal.scroll(0, 0);
      buttons.classList.add("fixed");
    }

    setAttachCommentActive(!attachCommentActive);
  };

  const handleAddComment = () => {
    const text = document.querySelector(".text-area-comment");
    try {
      // console.log("Ovde se salje komentar", itemData);
      axios
        .post(`${baseUrl}/items/createComment.php`, {
          api_key: apiKey,
          user_id: loggedUser.id,
          item_id: itemData.id,
          comment_text: text.value,
          taggedPeople: [],
          document: itemComment.document !== "" ? itemComment.document : [],
          link: itemComment.link,
          image: itemComment.image,
        })
        .then((data) => {
          //console.log(data.data);
          if (data.data.status === 200) {
            const newComment = {
              id: data.data.comment_id,
              seen: false,
              user: loggedUser.user_email,
              text: text.value,
              taggedPeople: [],
              timeCreated: data.data.created_at,
              document: itemComment.document || [],
              link: itemComment.link || "",
              image: itemComment.image,
            };
            const comments = [...itemList[index].comments, newComment];
            const newItemList = itemList.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  comments,
                  comments_count: data.data.comments_count,
                };
              } else return item;
            });
            setItemData(newItemList);
            setItemComment({
              document: "",
              image: "",
              link: "",
              seen: false,
              text: "",
              timeCreated: "",
              tagged_users: [],
              user: loggedUser.user_email,
            });
            setCommentActive({ ...commentActive, value: false });
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  return (
    <>
      {" "}
      <div className="comment-active-image">
        {commentActive.value && commentActive.index === index && (
          <textarea className="text-area-comment" />
        )}
        <div className="comment-active-buttons">
          {commentActive.value && commentActive.index === index ? (
            <div
              className="comment-list-item-button"
              onClick={handleCloseComment}
            >
              <Button image={closeImg} activation={true} />
            </div>
          ) : (
            <div className="image-modal-icon">
              <Button activation={true}>
                <a
                  href={itemData?.images[index]?.data}
                  download={itemData?.images[index]?.title}
                  style={{
                    color: "inherit",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <DownloadIcon style={downloadStyles} />
                </a>
              </Button>
            </div>
          )}
          <div
            className={
              commentActive.value
                ? "comment-list-item-button"
                : "comment-list-item-button not-active"
            }
            onClick={handleCommentImage}
          >
            <Button image={imageImg} active={commentImageActive} />
          </div>
          <div
            className={
              commentActive.value
                ? "comment-list-item-button"
                : "comment-list-item-button not-active"
            }
            onClick={handleCommentAttach}
          >
            <Button image={attachImg} active={attachCommentActive} />
          </div>
          {commentActive.value && commentActive.index === index ? (
            <div
              className="comment-list-item-button"
              onClick={() => handleAddComment(index)}
            >
              <Button image={sendImg} activation={true} />
            </div>
          ) : (
            <div
              className="editor-list-item-btn comments"
              onClick={() => handleActiveComment(index)}
            >
              <Button activation={true}>
                <img src={editImg} alt="" />
                <img src={messageImg} alt="" />
              </Button>
            </div>
          )}
        </div>
      </div>
      {commentImageActive && (
        <ImagePopupComment
          itemComment={itemComment}
          setItemComment={setItemComment}
          commentImageActive={commentImageActive}
        />
      )}
      {attachCommentActive && (
        <DocumentLinkComment
          itemComment={itemComment}
          setItemComment={setItemComment}
          commentImageActive
        />
      )}
    </>
  );
};

export default ImageModalComment;
