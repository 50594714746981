import { useEffect, useState, useRef } from "react";
import ControlScreen from "../ControlScreen/ControlScreen";
import EnterCode from "../EnterCode/EnterCode";
import "./controlPassword.css";
import axios from "axios";
import CheckForTrustedContacts from "../CheckForTrustedContacts/CheckForTrustedContacts";
import SendCodeOnlyToMyself from "../SendCodeOnlyToMyself/SendCodeOnlyToMyself";
import SendMyselfOrTrustedUser from "../SendMyselfOrTrustedUser/SendMyselfOrTrustedUser";
import SendOrCopyMail from "../SendOrCopyMail/SendOrCopyMail";
import { useGlobalContext } from "../../Context/Context";

const ControlPassword = ({ setCurrentPage }) => {
  const [currentPagePassword, setCurrentPagePassword] = useState(0);
  const [usePatternAnswer, setUserPatternAnswer] = useState(undefined);
  const [haveTrustContact, setHaveTrustContact] = useState(undefined);
  const [usePattern, setUsePattern] = useState(undefined);
  const [correctPattern, setCorrectPattern] = useState(false);
  const [inputTrustContact, setInputTrustContact] = useState(undefined);
  const { baseUrl, controlEmail } = useGlobalContext();

  console.log(controlEmail);
  useEffect(() => {
    const handleHaveTrustContact = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/control/checkIfUserHasTrustedContacts.php`,
          {
            user_email: controlEmail,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setHaveTrustContact(data.has_trusted_contact);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    handleHaveTrustContact();
    console.log(controlEmail);
  }, []);

  return (
    <div className="wrapper-control-password">
      {currentPagePassword === 0 && (
        <ControlScreen
          setCurrentPagePassword={setCurrentPagePassword}
          setUsePattern={setUsePattern}
          usePattern={usePattern}
          haveTrustContact={haveTrustContact}
          setCorrectPattern={setCorrectPattern}
          correctPattern={correctPattern}
          inputTrustContact={inputTrustContact}
          setInputTrustContact={setInputTrustContact}
          usePatternAnswer={usePatternAnswer}
          setUserPatternAnswer={setUserPatternAnswer}
        ></ControlScreen>
      )}
      {currentPagePassword === 1 && (
        <EnterCode setCurrentPage={setCurrentPage}></EnterCode>
      )}
      {currentPagePassword === 2 && (
        <CheckForTrustedContacts
          haveTrustContact={haveTrustContact}
          setCurrentPagePassword={setCurrentPagePassword}
          usePattern={usePattern}
          usePatternAnswer={usePatternAnswer}
          correctPattern={correctPattern}
        ></CheckForTrustedContacts>
      )}
      {currentPagePassword === 3 && (
        <SendCodeOnlyToMyself
          setCurrentPage={setCurrentPage}
          setCurrentPagePassword={setCurrentPagePassword}
        />
      )}
      {currentPagePassword === 4 && (
        <SendMyselfOrTrustedUser
          setCurrentPage={setCurrentPage}
          setCurrentPagePassword={setCurrentPagePassword}
        />
      )}
      {currentPagePassword === 5 && (
        <SendOrCopyMail setCurrentPagePassword={setCurrentPagePassword} />
      )}
    </div>
  );
};

export default ControlPassword;
