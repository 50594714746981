import "./trustFolder.css";
import Button from "../Button/Button";
import Back from "../picture/Back.png";
import Send from "../picture/Send.png";
import Pen from "../picture/Pen.png";
import axios from "axios";
import { useRef, useEffect } from "react";
import TrustContact from "../picture/TrustContact.png";
import TrustContactMailOne from "../picture/TrustEmaiUser1.png";
import TrustContactMailTwo from "../picture/TrustEmailUser2.png";
import TrustContactMessageOne from "../picture/TrustMessageUser1.png";
import TrustContactMessageTwo from "../picture/TrustMessageUser2.png";
import { useState } from "react";
import { useGlobalContext } from "../Context/Context";
const TrustFolder = ({ setCurrentPage, type, setSelectAccountPin }) => {
  const firstContact = useRef({ id: 0, email: "" });
  const confirmFirstContact = useRef({ id: 0, email: "" });
  const secondContact = useRef({ id: 0, email: "" });
  const confirmSecondContact = useRef({ id: 0, email: "" });
  const [firstContactSeted, setFirstContactSeted] = useState(true);
  const [secondContactSeted, setSecondContactSeted] = useState(true);
  const [updateContactSeted, setUpdateContactSeted] = useState(true);
  const { loggedUser } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();

  const handleBack = () => {
    if (type) {
      setCurrentPage(3);
    } else {
      setSelectAccountPin(1);
    }
  };

  const handleChangeText = (e, index) => {
    console.log(e.currentTarget.value);
    if (index === 1) firstContact.current.email = e.currentTarget.value;
    if (index === 2) confirmFirstContact.current.email = e.currentTarget.value;
    if (index === 3) secondContact.current.email = e.currentTarget.value;
    if (index === 4) confirmSecondContact.current.email = e.currentTarget.value;
  };
  console.log(firstContact.current.email);
  const handleInputTrustContact = async () => {
    console.log("Prosao" + updateContactSeted);
    console.log(firstContact.current);
    console.log(confirmFirstContact.current);
    if (updateContactSeted) {
      if (
        firstContact.current.email === confirmFirstContact.current.email &&
        firstContact.current.email.length !== 0 &&
        firstContactSeted
      ) {
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/createTrustedContacts.php`,
            {
              user_id: loggedUser.id,
              api_key: apiKey,
              trusted_contact_email: firstContact.current.email,
            }
          );
          console.log(data);
          if (data.status === 200) {
            firstContact.current.id = data.trusted_contact_id;
            confirmFirstContact.current.id = data.trusted_contact_id;
            setFirstContactSeted(false);
            console.log("Uspesno unesen mail");
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (
        secondContact.current.email === confirmSecondContact.current.email &&
        secondContact.current.email.length !== 0 &&
        secondContactSeted
      ) {
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/createTrustedContacts.php`,
            {
              user_id: loggedUser.id,
              api_key: apiKey,
              trusted_contact_email: secondContact.current.email,
            }
          );
          if (data.status === 200) {
            console.log("Uspesno unesen mail", data);
            secondContact.current.id = data.trusted_contact_id;
            confirmSecondContact.current.id = data.trusted_contact_id;
            setSecondContactSeted(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      console.log("Azuriraj korisnika" + firstContactSeted);

      try {
        const { data } = await axios.put(
          `${baseUrl}/auth/updateTrustedContacts.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
            trusted_contact_id: firstContactSeted
              ? firstContact.current.id
              : secondContact.current.id,
            trusted_contact_email: firstContactSeted
              ? firstContact.current.email
              : secondContact.current.email,
          }
        );
        console.log(data);
        if (data.status === 200) {
          if (firstContactSeted) {
            setFirstContactSeted(false);
          } else {
            setSecondContactSeted(false);
          }
          setUpdateContactSeted(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdateFirstContact = () => {
    setFirstContactSeted(true);
    setUpdateContactSeted(false);
  };

  const handleUpdateSecondContact = () => {
    setSecondContactSeted(true);
    setUpdateContactSeted(false);
  };

  useEffect(() => {
    const handelTrustContact = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/auth/getTrustedContactsByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        if (data.status === 200) {
          if (data.contacts.length === 2) {
            firstContact.current.id = data.contacts[0].id;
            firstContact.current.email = data.contacts[0].email;
            confirmFirstContact.current.id = data.contacts[0].id;
            confirmFirstContact.current.email = data.contacts[0].email;
            secondContact.current.id = data.contacts[1].id;
            secondContact.current.email = data.contacts[1].email;
            confirmSecondContact.current.id = data.contacts[1].id;
            confirmSecondContact.current.email = data.contacts[1].email;
            setFirstContactSeted(false);
            setSecondContactSeted(false);
          }
          if (data.contacts.length === 1) {
            firstContact.current.id = data.contacts[0].id;
            firstContact.current.email = data.contacts[0].email;
            confirmFirstContact.current.id = data.contacts[0].id;
            confirmFirstContact.current.email = data.contacts[0].email;
            setFirstContactSeted(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    handelTrustContact();
  }, []);

  return (
    <div className="wrapper-trust-contact">
      {updateContactSeted ? (
        <>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={TrustContactMailOne}
                alt=""
              ></img>
              {!firstContactSeted && (
                <div
                  className="wrapper-button-edit"
                  onClick={handleUpdateFirstContact}
                >
                  <Button image={Pen}></Button>
                </div>
              )}
            </div>
            {firstContactSeted ? (
              <>
                <input type="text" onChange={(e) => handleChangeText(e, 1)} />
                <input type="text" onChange={(e) => handleChangeText(e, 2)} />
              </>
            ) : (
              <span>{firstContact.current.email}</span>
            )}
            <div className="wrapper-trust-contact-button">
              {firstContactSeted && (
                <>
                  <div className="trust-contact-button">
                    <Button>
                      <img
                        src={TrustContactMailOne}
                        alt=""
                        className="trust-contact-button-image"
                      />
                    </Button>
                  </div>
                  <div className="trust-contact-button">
                    <Button>
                      <img
                        src={TrustContactMessageOne}
                        alt=""
                        className="trust-contact-button-image"
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={TrustContactMailTwo}
                alt=""
              ></img>
              {!secondContactSeted && (
                <div
                  className="wrapper-button-edit"
                  onClick={() => handleUpdateSecondContact()}
                >
                  <Button image={Pen}></Button>
                </div>
              )}
            </div>
            {secondContactSeted ? (
              <input type="text" onChange={(e) => handleChangeText(e, 3)} />
            ) : (
              <span> {secondContact.current.email}</span>
            )}

            {secondContactSeted && (
              <input type="text" onChange={(e) => handleChangeText(e, 4)} />
            )}
            <div className="wrapper-trust-contact-button">
              {secondContactSeted && (
                <>
                  <div className="trust-contact-button">
                    <Button>
                      {" "}
                      <img
                        src={TrustContactMailTwo}
                        alt=""
                        className="trust-contact-button-image"
                      />
                    </Button>
                  </div>
                  <div className="trust-contact-button">
                    <Button>
                      {" "}
                      <img
                        src={TrustContactMessageTwo}
                        alt=""
                        className="trust-contact-button-image"
                      />{" "}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="finish-contact-button" onClick={() => handleBack()}>
            <Button image={Back}> </Button>
          </div>
        </>
      ) : (
        <>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={
                  firstContactSeted ? TrustContactMailOne : TrustContactMailTwo
                }
                alt=""
              ></img>
            </div>

            <input
              type="text"
              defaultValue={
                firstContactSeted
                  ? firstContact.current.email
                  : secondContact.current.email
              }
              onChange={(e) => handleChangeText(e, firstContactSeted ? 1 : 3)}
            />
            <input
              type="text"
              defaultValue={
                firstContactSeted
                  ? confirmFirstContact.current.email
                  : confirmSecondContact.current.email
              }
              onChange={(e) => handleChangeText(e, firstContactSeted ? 2 : 4)}
            />
          </div>
          <div className="trust-contact-privilege">
            Ekran za definisanje privilegija
          </div>
        </>
      )}

      {firstContactSeted || secondContactSeted ? (
        <div
          className="finish-contact-button"
          onClick={handleInputTrustContact}
        >
          {
            <Button image={Send} activation={true}>
              {" "}
            </Button>
          }
        </div>
      ) : (
        <div className="finish-contact-button"></div>
      )}
    </div>
  );
};

export default TrustFolder;
