import { useEffect, useState ,useRef} from "react";
import Button from "../../Button/Button";
import axios from "axios";
import Plus from "../../picture/Plus.png";
import Send from "../../picture/Send.png";
import "./languageFlag.css";
import { useGlobalContext } from "../../Context/Context";

const LanguageFlag = ({ setCurrentPage , setCurrentPageGlobal,setSelectAccount }) => {
  const [flags, setFlags] = useState([]);
  const baseUrl = "https://data.nadzor.app/api";
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedCountry, setSelectedCountry] = useState();
  const interval = useRef();
  const {loggedUser,apiKey,setSelectedLanguage} = useGlobalContext()

  useEffect(() => {
    const handleSetFlags = async () => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/languages/ReadMainLanguages.php`,
          {}
        );
        console.log(data);
        if (data.status === 200) setFlags(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    handleSetFlags();

    window.addEventListener(
      "resize",
      () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      },
      true
    );
  }, []);

  const addNewLanguage = () => {

    if(loggedUser !== undefined)
    {
    setCurrentPage(1);
    }
  };

  const handleSelectFlag = (country) => {
    setSelectedCountry(country);
    console.log("Brise se interval",interval)
    clearTimeout(interval.current);
    console.log("Zemlja brise interval",country)
  };

  const handleSelectLanguage = async () =>
    {
      console.log("Ime prosledjenog jezika",selectedCountry)
      try {
        const { data } = await axios.post(
          `${baseUrl}/vocabulary/getAll.php`,
          {
            lang_name: selectedCountry === undefined ? "English" : selectedCountry.lang_name,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setSelectedLanguage(data)
          if(loggedUser === undefined)
          {
          setCurrentPageGlobal(2);
          }
          else
          {
            setSelectAccount(0);
            let sidebar = document.querySelector(".sidebar");
             sidebar.style.pointerEvents = "auto"
             let definePin = document.querySelector(".definePin-container");
           definePin.style.width = "calc(100vw -11.11vh)"
          }
        }
        
      } catch (error) {
        console.log(error);
      }

    }

  
  useEffect(() => {
    console.log("POziva se useEffect")
    if(loggedUser=== undefined)
    {
    interval.current = setTimeout(() => {
      console.log("Interval postavlja language",interval)
        handleSelectLanguage()
    }, 2000);
    }
    else
    {
      let sidebar = document.querySelector(".sidebar");
      if(sidebar.classList.contains("visible"))
      {
        sidebar.classList.replace("visible","hidden");
        sidebar.style.pointerEvents = "none"
        let definePin = document.querySelector(".definePin-container");
        definePin.style.width = "100vw"
        console.log("Postavljen novi css",sidebar)
      }

    }
    console.log("Interval postavljen",interval)
    return () => clearTimeout(interval.current);
  }, []);




  return (
    <div className="wrapper-languages-container">
      <div className="flags-up">
        {flags.map((item, index) => {
          if (index < flags.length / 4) {
            const theta = Math.PI / (flags.length / 4 + 3);
            const x =
              (width / 2.4) *
                Math.cos(-(theta * (index - (3 * flags.length) / 4 + 1))) +
              width / 2.3;
            const y =
              (height / 3) *
                Math.sin(theta * (index - (3 * flags.length) / 4 + 1)) -
              height / 2.3;

            return (
              <div
                key={index}
                className="one-flag-up"
                style={{ top: `${-y}px`, right: `${x}px` }}
                onClick={() => handleSelectFlag(item)}
              >
                <img src={item.lang_flag_base64} alt="" />
              </div>
            );
          }
        })}
      </div>
      <div className="flags-center">
        <div className="flags-center-side">
          {flags.map((item, index) => {
            if (index >= flags.length / 4 && index < flags.length / 2) {
              // let distance = ((90*window.innerHeight/2)/200)/(flags.length/4) + 30
              return (
                <div
                  className="one-flag"
                  onClick={() => handleSelectFlag(item)}
                >
                  <img src={item.lang_flag_base64} alt="" />
                </div>
              );
            }
          })}
        </div>
        {selectedCountry !== undefined && (
          <div className="wrapper-select-flag">
            <img
              src={selectedCountry.lang_flag_base64}
              className="image-flag"
            ></img>
            <div className="name-country">
              {selectedCountry.lang_name.slice(0, 3).toUpperCase()}
            </div>
            <div className="select-country-button" onClick={handleSelectLanguage}>
              <Button image={Send}></Button>
            </div>
          </div>
        )}
        <div className="flags-center-side">
          {flags.map((item, index) => {
            if (index >= flags.length / 2 && index < (3 * flags.length) / 4) {
              return (
                <div
                  className="one-flag"
                  onClick={() => handleSelectFlag(item)}
                >
                  <img src={item.lang_flag_base64} alt="" />
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="flags-down">
        {flags.map((item, index) => {
          if (index >= (3 * flags.length) / 4 && index < flags.length) {
            console.log(index - (3 * flags.length) / 4);
            const theta = Math.PI / (flags.length / 4 + 1);
            const x =
              (width / 3) *
                Math.cos(theta * (index - (3 * flags.length) / 4 + 1)) +
              width / 3;
            const y =
              (height / 5) *
                Math.sin(theta * (index - (3 * flags.length) / 4 + 1)) -
              height / 12;

            return (
              <div
                className="one-flag-down"
                style={{ top: `${y}px`, right: `${x}px` }}
                onClick={() => handleSelectFlag(item)}
              >
                <img src={item.lang_flag_base64} alt="" />
              </div>
            );
          }
        })}
        <div className="flags-down-button" onClick={ addNewLanguage}>
          <Button activation={false} image={Plus} />
        </div>
      </div>
    </div>
  );
};

export default LanguageFlag;
