import { useEffect } from "react";
import Button from "../Button/Button";
import "./registerChoose.css";
import { useGlobalContext } from "../Context/Context";
const RegisterChoose = ({ setCurrentPage }) => {
  const { selectedLanguage } = useGlobalContext();
  const handleStyleInput = () => {
    const registerChoose = document.querySelector(".registration-choose");
    const rect = registerChoose.getBoundingClientRect().height;
    const registerText = document.querySelectorAll(".registration-text");
    registerText.forEach((input) => {
      input.style.fontSize = ` ${(rect * 20) / 100}px`;
    });
  };
  const handleSelectPassword = () => {
    setCurrentPage(0);
  };

  useEffect(() => {
    handleStyleInput();
  }, []);

  window.onresize = () => {
    handleStyleInput();
  };

  return (
    <div className="registration-wrapper">
      <div className="registration-choose" onClick={handleSelectPassword}>
        <Button activation={true}>
          <a
            href={`mailto:registracija@nadzor.app?subject=Registration request&body=I wish to create a private account with my email address.`}
            className="registration-text"
          >
            {selectedLanguage.data.givePassword}
          </a>
        </Button>
      </div>
      <div className="registration-choose" onClick={() => setCurrentPage(3)}>
        <Button>
          <span className="registration-text">
            {" "}
            {selectedLanguage.data.havePassword}
          </span>
        </Button>
      </div>
      <div className="registration-choose">
        <Button>
          <span className="registration-text">
            {" "}
            {selectedLanguage.data.sendMail}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default RegisterChoose;
