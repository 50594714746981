import { useState, useEffect, useRef } from "react";
import "./messageList.css";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import Button from "../Button/Button";
import Search from "../picture/Search.png";
import Back from "../picture/Back.png";
import Add from "../picture/Plus.png";
import { format } from "date-fns";
import { WrapTextRounded } from "@mui/icons-material";
import Send from "../picture/Send.png";
import NewFindUser from "./NewFindUser";

const MessageList = ({ setCurrentChat, setCheckBack }) => {
  const [data, setData] = useState([]);
  const [resetData, setResetData] = useState([]);
  const [newUser, setNewUser] = useState(false);
  const [addChat, setAddChat] = useState(false);
  const [selectAddChat, setSelectAddChat] = useState(false);

  const lastDate = useRef();
  lastDate.current = format(new Date(), "MM");

  const searchTearm = useRef();

  const { selectedLanguage } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const messageListRef = useRef(null);

  console.log(selectedLanguage);
  useEffect(() => {
    // chat/getAllChatRoomsByUserId.php
    const fetchAllMessages = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/chat/getAllChatRoomsByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        setData(response.data.chat_rooms);
        setResetData(response.data.chat_rooms);
        console.log(response.data);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    fetchAllMessages();
  }, [baseUrl, loggedUser.id, apiKey]);

  const handleChangeSearch = (e) => {
    console.log(e.currentTarget.value);
    searchTearm.current.name = e.currentTarget.value;
    if (newUser) {
      const searchContact = document.querySelector(".search-contact");
      const searchInput = searchContact.querySelector("input");
      searchInput.style.color = "black";
      setNewUser(false);
    }
  };

  const validateEmail = (email) => {
    return searchTearm.current.name.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSearchChat = async (e) => {
    console.log(searchTearm);
    let searchChat = data?.filter((item) =>
      item.name.toUpperCase().includes(searchTearm.current.name.toUpperCase())
    );
    if (searchChat === undefined) searchChat = [];
    if (searchChat?.length === 0) {
      console.log(validateEmail);
      if (validateEmail) {
        try {
          const response = await axios.post(
            `${baseUrl}/chat/checkIfEmailExists.php`,
            {
              email: searchTearm.current.name,
              api_key: apiKey,
            }
          );
          searchTearm.current.id = response.data.user_id;

          console.log(response.data);
          const searchContact = document.querySelector(".search-contact");
          const searchInput = searchContact.querySelector("input");
          if (response.data.status === 200) {
            setAddChat(true);
            searchInput.style.color = "green";
            console.log("U bazi je");
          } else {
            console.log("Nije u bazi", searchInput);
            searchInput.style.color = "red";
            setNewUser(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("Nije mail");
      }
    } else {
      setData(searchChat);
    }
  };

  const handleResetChat = (e) => {
    setData(resetData);
    setNewUser(false);
    const searchContact = document.querySelector(".search-contact");
    const searchInput = searchContact.querySelector("input");
    searchInput.style.color = "black";
  };

  const sendNewUser = async () => {
    try {
      const dataExternal = await axios.post(
        `${baseUrl}/chat/inviteExternalUser.php`,
        {
          logger_user_id: loggedUser.id,
          email: searchTearm.current.name,
          api_key: apiKey,
        }
      );
      console.log(dataExternal);
      if (dataExternal.data.status === 404) {
        console.log("Nije uspelo");
      }

      if (dataExternal.data.status === 200) {
        setData([...data, dataExternal.data.message]);
        const linkMail = document.querySelector(".link-mail");
        linkMail.href = `mailto:${searchTearm.current.name}?subject="Naziv"&body=${dataExternal.data.url}`;
        linkMail.click();
        searchTearm.current.name = "";
        console.log("Uspelo");
      }
      setNewUser(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewUser = async () => {
    try {
      // Store the current scroll position
      const scrollPosition = document.querySelector(".message-list");

      const { data } = await axios.post(
        `${baseUrl}/chat/createChatRoomAfterEmail.php`,
        {
          logged_user_id: loggedUser.id,
          other_user_id: searchTearm.current.id,
          api_key: apiKey,
        }
      );
      console.log(data);
      if (data.status === 200) {
        searchTearm.current.chat_room_id = data.chat_room_id;
        setSelectAddChat(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMessage = async (message) => {
    // console.log("Dosao sam", message);
    const handleBackMessage = async () => {
      try {
        // Store the current scroll position
        const scrollPosition = document.querySelector(".message-list");

        const { data } = await axios.post(
          `${baseUrl}/chat/getAllMessagesByTwoUsers.php`,
          {
            user_id: loggedUser.id,
            other_user_id: message.id,
            api_key: apiKey,
          }
        );
        //  console.log(data);
        if (data.status === 200) {
          setCurrentChat(data);
          // Restore the scroll position
          messageListRef.current.scrollTop = scrollPosition;
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Load messages instantly
    await handleBackMessage();

    // Set interval to check for new messages every 10 seconds
    let interval = setInterval(handleBackMessage, 10000);

    setCheckBack(true);
  };
  useEffect(() => {
    searchTearm.current = { id: 0, name: "", chat_room_id: 0 };
  }, []);
  console.log(searchTearm);

  return selectAddChat ? (
    <NewFindUser
      searchTearm={searchTearm.current}
      setAddChat={setAddChat}
      setSelectAddChat={setSelectAddChat}
      handleSelectMessage={handleSelectMessage}
    />
  ) : (
    <div className="message-list" ref={messageListRef}>
      <div className="search-contact">
        <div className="button-search" onClick={handleResetChat}>
          <Button image={Back} activation={true}></Button>
        </div>
        <input type="text" onChange={handleChangeSearch} />
        <div
          className="button-search"
          onClick={addChat ? handleAddNewUser : handleSearchChat}
        >
          <Button image={addChat ? Add : Search} activation={true}></Button>
        </div>
      </div>
      {data !== undefined &&
        data.map((message) => {
          const time = new Date(message.last_message_time);
          const hours =
            time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
          const minutes =
            time.getMinutes() < 10
              ? `0${time.getMinutes()}`
              : time.getMinutes();
          const day = format(time, "dd.MM.yyyy");
          const isCurrent =
            lastDate.current === format ||
            lastDate.current !== format(time, "MM");
          if (isCurrent) lastDate.current = format(time, "MM");
          return (
            <>
              {format(time, "dd.MM.yyyy") !==
                format(new Date(), "dd.MM.yyyy") &&
                isCurrent && <span className="time-span">{day}</span>}
              <div
                key={message.id}
                className="message-element"
                onClick={() => handleSelectMessage(message)}
              >
                <div className="wrapper-message-user">
                  <div className="wrapper-data-info">
                    <div
                      className="count-message"
                      style={{
                        backgroundColor: message.firstTime ? "red" : "green",
                      }}
                    >
                      {message.unseen_messages_count}
                    </div>
                    <div className="name-message">{message.name}</div>
                    <div className="time-message">{`${hours}:${minutes} `}</div>
                  </div>
                  <div className="wrapper-data-info">
                    <div className="text-message">{message.last_message}</div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      {newUser && (
        <div className="new-user-wrapper">
          <span>{selectedLanguage.data.newUser}</span>
          <div className="send-new-user" onClick={sendNewUser}>
            <Button activation={true} image={Send}></Button>
          </div>
        </div>
      )}
      <a className="link-mail"></a>
    </div>
  );
};

export default MessageList;
