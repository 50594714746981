import { useEffect, useState } from "react";
import "./pinUnlock.css";

const PinUnlock = ({
  height,
  width,
  setInputPattern,
  draft,
  resetSwitch,
  setResetSwitch,
}) => {
  const arrayPin = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const pin = "1234";

  const [currentPin, setCurrentPin] = useState("");

  const handleChoosePin = (number, e) => {
    console.log(number);
    if (draft) {
      if (currentPin.length >= 6) {
        setResetSwitch(true);
        return;
      }
      setInputPattern((prev) => [...prev, number - 1]);
      setCurrentPin(currentPin + (number - 1));
      e.currentTarget.style.backgroundColor = "gray";
    }
  };
  const resetCurrentPin = () => {
    setCurrentPin("");
  };

  const handleDeletePin = () => {
    resetCurrentPin();
  };

  const handleOK = () => {
    if (currentPin.length < 4) return;
    if (pin !== currentPin) {
      const currentPinDots = document.querySelectorAll(".current-pin-dot");
      currentPinDots.forEach((element) => {
        element.classList.add("animated");
      });
      setTimeout(resetCurrentPin, 1000);
    }
  };

  const handleKeyPress = (e) => {
    // enter
    if (e.keyCode === 13) handleOK();
    // brojevi i brojevi na Num pad-u
    else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    )
      handleChoosePin(parseInt(e.key));
  };

  const handleClick = () => {
    const wrapperPinUnlock = document.querySelector(".wrapper-pin-unlock");
    wrapperPinUnlock.focus();
  };

  useEffect(() => {
    if (draft) {
      window.addEventListener("click", handleClick);

      return () => {
        window.removeEventListener("click", handleClick);
      };
    }
  }, []);

  useEffect(() => {
    if (resetSwitch) {
      const dots = document.querySelectorAll(".number-choose-pin");
      dots.forEach((dot) => (dot.style.backgroundColor = "#fff"));
      setResetSwitch(false);
      setCurrentPin("");
      setInputPattern([]);
    }
  }, [resetSwitch]);

  return (
    <div
      className="unlock-screen"
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      <div
        className="wrapper-pin-unlock"
        tabIndex={0}
        onKeyDown={handleKeyPress}
      >
        <div className="wrapper-pin-choose-element">
          {arrayPin.map((number, index) => {
            return (
              <div
                className="number-choose-pin"
                key={index}
                onClick={(e) => handleChoosePin(number, e)}
              >
                {" "}
                {number}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PinUnlock;
