import { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import Cancel from "../picture/Cancel.png";
import Back from "../picture/Back.png";
import Sort from "../picture/Sort.png";
import Search from "../picture/Search.png";
import Share from "../picture/Share.png";
import Plus from "../picture/Plus.png";
import Send from "../picture/Send.png";
import Message from "../picture/Message.png";
import Mail from "../picture/Mail.png";
import axios from "axios";
import "./linksItemModal.css";
import { useGlobalContext } from "../Context/Context";
import BackLink from "../Links/ComeLink";

const LinksItemModal = ({ setOpenLinks, newLinks, setNewLinks }) => {
  const [myLinks, setMyLinks] = useState([]);
  const [openLink, setOpenLink] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [goToLink, setGoToLink] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [goToMyLink, setGoToMyLink] = useState();
  const [page, setPage] = useState(1);

  const perf = "../OpenLink/index.html";
  const { apiKey, baseUrl, loggedUser } = useGlobalContext();

  const linkRef = useRef();
  const nameRef = useRef();
  const searchRef = useRef();

  const handleOpenLink = () => {
    setOpenLink(!openLink);
  };

  /* const handleDeleteLink = async(index) => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/user_links/delete.php`,
          {link_id: index,
          api_key: apiKey}
        );
        console.log(data);
        if (data.status === 200) {
          const myNewLinks = myLinks.filter((item) => item.id !== index);
          setMyLinks(myNewLinks);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
     
    };*/

  const handleChangeLink = (e) => {
    linkRef.current = e.currentTarget.value;
  };

  const handleInputName = (e) => {
    nameRef.current = e.currentTarget.value;
  };

  const handleGoToLink = (myLink, index) => {
    setGoToMyLink(myLink);
    setGoToLink(true);

    const IFrame = document.querySelector(".wrapper-link-content");

    //IFrame.src= `https://${goToMyLink.link}`
    /* const corsAnywhere = "https://cors-anywhere.herokuapp.com/";
      fetch(`https://${myLink.link}`)
        .then((response) => response.text())
        .then((data) => {
          myDiv.innerHTML = data;
        })
        .catch((error) => console.error("Error fetching data:", error));
    */
  };

  const handleShareLink = () => {
    setOpenShare(!openShare);
  };

  const handleCloseLink = () => {
    setGoToLink(false);
    setGoToMyLink();
    setOpenShare(false);
  };

  const handleAddLink = async () => {
    if (openName) {
      try {
        console.log(nameRef.current);
        console.log(apiKey);
        const { data } = await axios.post(`${baseUrl}/user_links/create.php`, {
          user_id: loggedUser.id,
          api_key: apiKey,
          name: nameRef.current,
          link: linkRef.current,
        });
        console.log(data);
        if (data.status === 200) {
          setMyLinks([
            {
              id: data.link_id,
              name: nameRef.current,
              link: linkRef.current,
            },
            ...myLinks,
          ]);
          nameRef.current = "";
          linkRef.current = "";
          setOpenLink(false);
          setOpenName(false);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenName(true);
    }
  };

  const handleOpenMail = () => {
    window.location.href = `mailto:?subject=ShareLink&body=${goToMyLink.link}`;
  };

  const handleOpenSearch = () => {
    setGoToLink(!goToLink);
    setOpenSearch(!openSearch);
  };

  const handleSearchTerm = (e) => {
    searchRef.current = e.currentTarget.value;
  };

  const handleFindLinkSearch = () => {
    console.log("Load iframe");
  };

  const handleFindLink = (e) => {
    const IFrame = e.currentTarget;
    console.log(IFrame);
    console.log(IFrame.querySelectorAll("*"));
  };

  const handleSearchTermFromInput = async () => {
    try {
      console.log(searchRef.current);
      console.log(page);
      const { data } = await axios.post(
        `${baseUrl}/user_links/searchLink.php`,
        {
          keyword: searchRef.current,
          page: page,
        }
      );
      console.log(data);
      if (data.status === 200) {
        const IFrame = document.querySelector(".wrapper-link-search-content");
        //console.log(IFrame)
        console.log(BackLink);
        if (IFrame !== null && IFrame !== undefined) IFrame.srcdoc = BackLink;
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddLinkToItem = (item) => {
    setNewLinks([...newLinks, item]);
  };

  useEffect(() => {
    const handleBackLink = async () => {
      try {
        console.log(loggedUser);
        console.log(apiKey);
        const { data } = await axios.post(
          `${baseUrl}/user_links/readByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setMyLinks(data.data);
        } else {
          setMyLinks([]);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    handleBackLink();
    nameRef.current = "";
    linkRef.current = "";
  }, []);

  useEffect(() => {
    const IFrame = document.querySelector(".wrapper-link-content");
    console.log(IFrame);
    if (IFrame !== null && IFrame !== undefined)
      IFrame.src = `https://${goToMyLink?.link}`;
  }, [goToLink]);

  console.log("Link za slanje", goToMyLink);
  return (
    <div className="wrapper-container-links">
      <div className="wrapper-header">
        {!goToMyLink ? (
          <>
            <div className="wrapper-button" onClick={() => setOpenLinks(true)}>
              <Button image={Back}></Button>
            </div>
            <div
              className="wrapper-button"
              onClick={() => setMyLinks([...myLinks.reverse()])}
            >
              <Button image={Sort}></Button>
            </div>
            <div className="wrapper-button" onClick={handleOpenSearch}>
              <Button image={Search}></Button>
            </div>
            <div className="wrapper-button" onClick={handleOpenLink}>
              <Button active={openLink} image={Plus}></Button>
            </div>
          </>
        ) : !openSearch ? (
          <div className="one-link open">
            <div className="wrapper-my-link-button" onClick={handleCloseLink}>
              <Button active={goToLink}>
                {goToMyLink.name !== "" ? goToMyLink.name : goToMyLink.link}
              </Button>
            </div>
            <div
              className="wrapper-link-cancel"
              onClick={() => handleShareLink()}
            >
              <Button active={openShare} image={Share}></Button>
            </div>
          </div>
        ) : (
          <>Naslov search</>
        )}
      </div>
      <div className="wrapper-content">
        {!goToLink ? (
          myLinks.map((item, index) => {
            return (
              <div className="one-link" key={index}>
                <div
                  className="wrapper-my-link-button"
                  onClick={() => handleGoToLink(item, index)}
                >
                  <Button active={goToLink}>
                    {item.name !== "" ? item.name : item.link}
                  </Button>
                </div>
                <div
                  className="wrapper-link-cancel"
                  onClick={() => handleAddLinkToItem(item)}
                >
                  <Button image={Plus}></Button>
                </div>
              </div>
            );
          })
        ) : !openSearch ? (
          <iframe
            className="wrapper-link-content"
            title=""
            onLoad={handleFindLink}
          ></iframe>
        ) : (
          <div className="wrapper-search-content">
            <div className="wrapper-search-header">
              <input onChange={handleSearchTerm}></input>
              <div
                className="wrapper-search-button"
                onClick={handleSearchTermFromInput}
              >
                <Button image={Search}> </Button>
              </div>
            </div>
            <div className="wrapper-search-body">
              <iframe
                className="wrapper-link-search-content"
                title=""
                onLoad={handleFindLinkSearch}
              ></iframe>
            </div>
          </div>
        )}
      </div>
      {openLink && !goToLink && (
        <div className="wrapper-input-link">
          <input type="text" onChange={handleChangeLink} />
          <div className="wrapper-link-cancel" onClick={handleAddLink}>
            <Button activation={true} image={openName ? Send : Plus}></Button>
          </div>
        </div>
      )}
      {openName && !goToLink && (
        <input
          type="text"
          className="input-name-link"
          onChange={handleInputName}
        />
      )}
      {openShare && (
        <div className="wrapper-open-share">
          <div className="wrapper-link-share-button">
            <Button activation={true}>Dots</Button>
          </div>
          <div className="wrapper-link-share-button">
            <Button activation={true}>Question</Button>
          </div>
          <div className="wrapper-link-share-button" onClick={handleOpenMail}>
            <Button activation={true} image={Mail}></Button>
          </div>
          <div className="wrapper-link-share-button">
            <Button activation={true} image={Message}></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinksItemModal;
