import "./imageModal.css";
import plusImg from "../../plus.png";
import imageImg from "../../slika.png";
import { useState } from "react";
import ImageModalAdd from "./ImageModalAdd";
import ImageModalAddEdit from "./ImageModalAddEdit";
import ImageModalComment from "./ImageModalComment";
import { useGlobalContext } from "../../../Context/Context";
import axios from "axios";

const ImageModal = ({
  itemData,
  setItemData,
  isAdding,
  capacity,
  loggedUser,
  name,
  itemList,
  setItemList,
  imageModalActive,
  setImageModalActive,
  imageModalCommentActive,
  realItemData,
  setRealItemData,
  user_id,
  order,
}) => {
  console.log("Poziva se redni broj", order);
  /*console.log("Kapacitet", capacity);*/
  console.log("Prijvaljeni user i onaj koji koristi", user_id, loggedUser);
  console.log("Citanje i dodavanje", isAdding);
  console.log(realItemData);
  console.log(itemData);
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const addImagesLimit = capacity;
  const settingLocalImages = () => {
    if (imageModalActive === undefined) {
      return itemData.images;
    }

    if (imageModalActive.isCommenting) {
      return realItemData === undefined ? [] : realItemData.images;
    }

    if (imageModalActive.isCommenting === undefined) {
      return [
        itemData?.comments !== undefined
          ? itemData.comments[imageModalCommentActive.value].image
          : realItemData.images,
      ];
    }

    if (imageModalActive.isCommenting) {
      return realItemData.images;
    } else {
      return itemData.images;
    }
  };
  const [localImages, setLocalImages] = useState(settingLocalImages());

  const [draftImages, setDraftImages] = useState([]);
  const [updatingImage, setUpdatingImage] = useState(null);

  const remainingImages = addImagesLimit - localImages?.length;

  const handleInputImage = (e) => {
    let newImage;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      newImage = reader.result;
      const images = [
        ...draftImages,
        {
          data: newImage,
          title: "",
        },
      ];
      setDraftImages(images);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleDeleteImage = (index) => {
    const newImages = realItemData.images.filter((_, i) => index !== i);

    setRealItemData({ ...realItemData, images: newImages });
    setLocalImages(newImages);
  };

  const handleUpdatingImage = (title) => {
    console.log(title);
    console.log(updatingImage);
    const newImages = realItemData.images.map((image, index) => {
      console.log(image);
      if (index === updatingImage) {
        return { ...image, title };
      }
      return image;
    });
    setRealItemData({ ...realItemData, images: newImages });
    setLocalImages(newImages);
  };
  console.log(loggedUser);
  return (
    <div
      className={
        isAdding ? "image-modal-wrapper adding" : "image-modal-wrapper"
      }
    >
      <>
        <div className="image-modal">
          {localImages !== undefined &&
            localImages.length !== 0 &&
            localImages.map((image, index) => {
              const { data, title } = image;
              console.log(image);
              return (
                <>
                  <div className="image-modal-preview" key={index}>
                    <div className="title-container">
                      {index + 1}.{title}
                    </div>
                    <div className="image-container-finish">
                      <img src={data} alt="" />
                    </div>
                    {loggedUser.id === user_id && isAdding ? (
                      <ImageModalAddEdit
                        setDraftImages={setDraftImages}
                        index={index}
                        setUpdatingImage={setUpdatingImage}
                        handleUpdatingImage={handleUpdatingImage}
                        itemData={itemData}
                        handleDeleteImage={handleDeleteImage}
                        updatingImage={updatingImage}
                        realItemData={realItemData}
                      />
                    ) : (
                      <ImageModalComment
                        index={index}
                        itemData={itemData}
                        setItemData={setItemData}
                        isAdding={isAdding}
                        capacity={capacity}
                        loggedUser={loggedUser}
                        name={name}
                        user_id={user_id}
                        itemList={itemList}
                        setItemList={setItemList}
                        imageModalActive={imageModalActive}
                        imageModalCommentActive={imageModalCommentActive}
                        realItemData={realItemData}
                        setRealItemData={setRealItemData}
                        setImageModalActive={setImageModalActive}
                      />
                    )}
                  </div>
                  <div className="line-image"></div>
                </>
              );
            })}
          {draftImages.length !== 0 &&
            draftImages.map((image, index) => {
              const { data, title } = image;
              return (
                <>
                  <div className="image-modal-preview draft-image" key={index}>
                    <div className="image-container">
                      <img src={data} alt="" />
                    </div>
                    <ImageModalAdd
                      itemData={itemData}
                      setItemData={setItemData}
                      setDraftImages={setDraftImages}
                      draftImages={draftImages}
                      image={image}
                      index={index}
                      isAdding={isAdding}
                      itemList={itemList}
                      setItemList={setItemList}
                      imageModalActive={imageModalActive?.value}
                      capacity={capacity}
                      realItemData={realItemData}
                      setRealItemData={setRealItemData}
                      setLocalImages={setLocalImages}
                      localImages={localImages}
                    />
                  </div>
                  <div className="line-image"></div>
                </>
              );
            })}
        </div>
        {remainingImages !== 0 && isAdding && (
          <div className="wrapper-input-picture">
            <span className="image-modal-span">
              {`* Dozvoljeno ${remainingImages} fotografije`}
            </span>
            <span className="image-modal-span">
              * Dozvoljeni formati: JPEG, PNG, TIFF
            </span>
            <div className="image-modal-input">
              <label
                className="image-modal-input-wrapper"
                htmlFor="file-upload"
              >
                <img className="plus-img" src={plusImg} alt="" />
                <img className="image-img" src={imageImg} alt="" />
              </label>
              <input
                accept="image/jpeg, image/png, /image/tiff, image/jpg"
                id="file-upload"
                type="file"
                onChange={handleInputImage}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ImageModal;
