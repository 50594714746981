import React from "react";
import "./Style.css";
import {  useRef } from "react";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import Button from "../../Button/Button";

function SendCodeOnlyToMyself({setCurrentPage,setCurrentPagePassword}) {

  const {controlEmail,baseUrl } = useGlobalContext();

console.log(controlEmail)


  const handleSendMYCode =async () => 
    {
        try {
            const { data } = await axios.post(
              `${baseUrl}/verification_codes/send_verification_code.php`,
              {
                user_email: controlEmail,
              }
            );
            console.log(data)
            if (data.status === 200) {
              setCurrentPagePassword(1)
            }
            else
            {
                setCurrentPage(0)
            }
            console.log(data)
          } catch (error) {
            console.log(error);
          }

    }

  return (
    <div className="send-code-only-to-myself-container">
      <div className="message-top">
        <p>
          KOD će stići na vasu email adresu, molimo vas da isti unesete u polja
          u prvom pokusaju.
        </p>
      </div>

      <div className="send-code-btn" onClick={handleSendMYCode}>
        <Button activation={true}>POŠALJI MI KOD</Button>
      </div>
    </div>
  );
}

export default SendCodeOnlyToMyself;
