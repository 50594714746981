import React, { useRef } from "react";
import "./Style.css";
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";

function SendMyselfOrTrustedUser({ setCurrentPagePassword }) {
  const textRef = useRef(null);
  const { selectedLanguage } = useGlobalContext();

  // Kopiranje teksta
  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          alert("Tekst je uspesno kopiran.");
          setCurrentPagePassword(1);
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    } else {
      console.error("Text reference is null");
    }
  };

  // Slanje mail-a iz svog servisa
  const handleClick = (event) => {
    event.preventDefault();
    console.log("Otvaram slanje maila.");
    setCurrentPagePassword(1);
    window.location.href = `mailto:verify@nadzor.app?subject=Verification&body=${selectedLanguage.data.dear},${selectedLanguage.data.verificationCode}`;
  };

  return (
    <div className="send-or-copy-mail-container">
      <div className="message-top">
        <p>
          {selectedLanguage.data.dear},
          <br />
          <br />
          ukoliko sa ovog uredjaja ne mozete poslati zahtev sa mail adrese kojom
          ste registrovani, molimo vas da tekst koji ce se nakon potvrde za
          slanje mail-a generisati u vasem mail servisu u celosti kopirate i
          posaljete sa adrese sa kojom ste registrovani.
        </p>
      </div>

      <div className="send-to-me-btn" onClick={handleClick}>
        <Button activation={true}>POŠALJI MAIL</Button>
      </div>

      <div className="message-bottom" ref={textRef}>
        <p>
          {selectedLanguage.data.dear},
          <br />
          <br />
          {selectedLanguage.data.verificationCode}
        </p>
      </div>

      <div className="send-to-me-btn" onClick={handleCopyClick}>
        <Button activation={true}>KOPIRAJ TEKST</Button>
      </div>
    </div>
  );
}

export default SendMyselfOrTrustedUser;
