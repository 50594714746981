export const itemDataDefault = {
  dataItems: [
    {
      name: "ZAPAZANJE",
      important: true,
      data: "",
    },
    {
      name: "PREDUZETO",
      important: false,
      data: "",
    },
    {
      name: "REZULTAT",
      important: false,
      data: "",
    },
  ],
  images: [],
  link: "",
  document: {
    name: "",
    data: "",
  },
};

export const archive = [
  {
    id: 1,
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-03-10").setHours(11, 20, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 1",
    document: {
      data: "",
      name: "",
    },
    comments: [
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-01").setHours(10, 10, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-12").setHours(9, 8, 0, 0)),
      },
      {
        id: 3,
        seen: false,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-14").setHours(10, 50, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Zikica Petkovic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-15").setHours(10, 11, 0, 0)),
      },
    ],
    people: [],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 2,
    name: "Igor Krstic",
    timeCreated: new Date(new Date("2024-03-11").setHours(20, 21, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 2",
    document: {
      data: "",
      name: "",
    },
    comments: [],
    people: [],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 3,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-03-11").setHours(15, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 3",
    document: {
      data: "",
      name: "",
    },
    comments: [],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },

  {
    id: 4,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-03-11").setHours(0, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 4",
    document: {
      data: "",
      name: "",
    },
    comments: [
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-10").setHours(2, 10, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-07").setHours(7, 50, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-05").setHours(16, 20, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-07").setHours(12, 0, 0, 0)),
      },
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-18").setHours(11, 38, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-14").setHours(7, 53, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-11").setHours(6, 8, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-11").setHours(5, 4, 0, 0)),
      },
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-12").setHours(8, 9, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-08").setHours(7, 56, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-11").setHours(6, 30, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-12").setHours(10, 20, 0, 0)),
      },
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-11").setHours(7, 54, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-14").setHours(9, 30, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-07").setHours(9, 12, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-03").setHours(5, 50, 0, 0)),
      },
      {
        id: 1,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-08").setHours(17, 0, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-16").setHours(18, 20, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-17").setHours(22, 0, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Mladen Dubovac",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-11").setHours(15, 10, 0, 0)),
      },
    ],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 5,
    people: [],
    name: "Igor Krstic",
    timeCreated: new Date(new Date("2024-03-12").setHours(0, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 2",
    document: {
      data: "",
      name: "",
    },
    comments: [
      {
        id: 1,
        seen: true,
        text: "Ovo je tekst za komentar.",
        user: "Mladen Dubovac",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-17").setHours(11, 11, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        text: "Ovo je tekst za komentar.",
        user: "Mladen Dubovac",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-03-18").setHours(10, 20, 0, 0)),
      },
    ],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 6,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-04-13").setHours(0, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 3",
    document: {
      data: "",
      name: "",
    },
    comments: [
      {
        id: 1,
        seen: true,
        text: "Ovo je tekst za komentar.",
        user: "Mladen Dubovac",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(17, 10, 0, 0)),
      },
      {
        id: 2,
        seen: true,
        text: "Ovo je tekst za komentar.",
        user: "Mladen Dubovac",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(18, 52, 0, 0)),
      },
    ],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 7,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-04-14").setHours(0, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 4",
    document: {
      data: "",
      name: "",
    },
    comments: [
      {
        id: 1,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(13, 0, 0, 0)),
      },
      {
        id: 2,
        seen: false,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(14, 20, 0, 0)),
      },
      {
        id: 3,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(14, 45, 0, 0)),
      },
      {
        id: 4,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(14, 52, 0, 0)),
      },
      {
        id: 5,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(16, 30, 0, 0)),
      },
      {
        id: 6,
        seen: false,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(16, 42, 0, 0)),
      },
      {
        id: 7,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(16, 48, 0, 0)),
      },
      {
        id: 8,
        seen: true,
        user: "Igor Krstic",
        text: "Ovo je tekst za komentar.",
        taggedPeople: [],
        link: "",
        document: {
          name: "",
          data: "",
        },
        image: "",
        timeCreated: new Date(new Date("2024-04-15").setHours(16, 50, 0, 0)),
      },
    ],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 8,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-03-31").setHours(15, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 3",
    document: {
      data: "",
      name: "",
    },
    comments: [],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
  {
    id: 9,
    people: [],
    name: "Zikica Petkovic",
    timeCreated: new Date(new Date("2024-04-01").setHours(15, 0, 0, 0)),
    data: itemDataDefault.dataItems,
    title: "STAVKA 3",
    document: {
      data: "",
      name: "",
    },
    comments: [],
    timeModified: new Date(),
    visibility: true,
    images: [],
    link: "",
  },
];
