import "./editor.css";
import calendarImg from "../kalendar.png";
import sendImg from "../slanje.png";
import sendCorrectImg from "../olovka_zelena.png";
import folderImg from "../folder.png";
import filterImg from "../filter.png";
import imagesImg from "../slika.png";
import Button from "../Button/Button";
import { compareAsc, format } from "date-fns";
import pdfImg from "../generisi_pdf.png";
import backImg from "../nazad.png";
import closeImg from "../zatvaranje.png";
import detailsImg from "../povecaj.png";
import detailsShrinkImg from "../smanji.png";
import categoriesImg from "../kategorije.png";
import attachImg from "../prilog.png";
import sortImg from "../sortiranje.png";
import EditorListItems from "./EditorListItems/EditorListItems";
import EditorEntries from "./EditorEntries/EditorEntries";
import ImageModal from "./ImageModal/ImageModal";
import Archive from "../Archive/Archive";
import { useEffect, useRef, useState } from "react";
import FilterArchive from "../Archive/FilterArchive/FilterArchive";
import FilterArchiveLandscape from "../Archive/FilterArchive/FilterArchiveLandscape";
import EditorCalendar from "../EditorCalendar/EditorCalendar";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import CategoryModal from "../Category/CategoryModal";
import FilterListItem from "../Archive/FilterListItem/FilterListItem";
import CalendarWork from "../CalendarWork/CalendarWork";
import DocumentLinkItemModal from "./DocumentLinkItemModal/DocumentLinkItemModal";
import PdfViewer from "../PdfViewer/PdfViewer";
import FolderModal from "../FolderModal/FolderModal";

const Editor = ({ setSelectAccount, account }) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { setNewLinks } = useGlobalContext();

  const { openAddLink, setOpenAddLink } = useGlobalContext();
  const { setCopy } = useGlobalContext();
  const [daysArchive, setDaysArchive] = useState();
  const [timeOfCreate, setTimeOfCreate] = useState([]);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const [pdfActive, setPdfActive] = useState(false);
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [imageModalActive, setImageModalActive] = useState(false);
  const [categoryModalActive, setCategoryModalActive] = useState(false);
  const [atachModalActive, setAtachModalActive] = useState(false);
  const [filterModalActive, setFilterModalActive] = useState(false);
  const [filterListItemActive, setFilterListItemActive] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [archive, setArchive] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [counterDocument, setCounterDocumet] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate: "",
    endDate: "",
    message: { count: 0, value: 0 }, //0 se gasi 1 crveno 2 zeleno
    documents: { count: 0, value: 0 },
    link: { count: 0, value: 0 },
    image: { count: 0, value: 0 },
    category: [],
    people: [],
  });
  console.log("Editor archive data", archiveData);
  // cuva podatke stavke koja treba da se doda
  const [itemDataDefault, setItemDataDefault] = useState();
  const [itemData, setItemData] = useState();
  const [detailsChecked, setDetailsChecked] = useState(false);
  const [archiveClicked, setArchiveClicked] = useState(false);
  const [archiveActive, setArchiveActive] = useState(false);
  const [sortingChecked, setSortingChecked] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [calendarClick, setCalendarClick] = useState(false);
  const [folderClicked, setFolderClicked] = useState(false);
  const [fromDateColumn, setFromDateColumn] = useState(false);

  console.log("Pozicironiranje imena", isNameFocused);

  const [name, setName] = useState("");
  const [editing, setEditing] = useState({
    value: false,
    info: "",
  });

  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  console.log(loggedUser);
  const handleBackItem = async () => {
    try {
      axios
        .post(`${baseUrl}/items/getAllItemsByLocationId.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log("Poziva se i postavlja item list", data);
          if (data.data.status === 200) {
            setItemList(data.data.items);
          } else {
            setItemList([]);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleChangeName = (e) => {
    if (e.currentTarget.value !== "") setIsNameEntered(true);
    else {
      const newDataItems = itemData.dataItems?.map((item) => {
        return { ...item, data: "" };
      });
      setItemData({ ...itemData, dataItems: newDataItems });
      setIsNameEntered(false);
    }
    setName(e.currentTarget.value);
  };

  const handleReset = () => {
    setName("");
    setIsNameEntered(false);
    setItemData(itemDataDefault);
    setIsNameFocused(false);
    setImageModalActive(false);
    setEditing({
      value: false,
      info: "",
    });
  };

  /*const checkIfAllCorrect = () => {
    // setuje klase dugmetu za slanje zavisno od stanja
    let wrongOne;
    wrongOne = itemData.dataItems.find(
      (item) => isNameEntered && item.data === "" && item.important
    );
    if (wrongOne === undefined && !isNameEntered) return undefined;
    else if (isNameEntered && wrongOne === undefined) return true;
    else return false;
  };*/

  const createItem = (e) => {
    // IZMENI KASNIJE ZA VALIDACIJU VISE NJIH
    // validacije
    let itemFound;
    const customBtn = e.currentTarget.querySelector(".dos-btn");
    itemFound = itemData.dataItems.find(
      (item) => isNameEntered && item.data === "" && item.important
    );
    if (!isNameEntered || itemFound) {
      let index = itemData.dataItems.indexOf(itemFound);
      const textAreas = document.querySelectorAll(
        ".editor-data-entry-textarea"
      );
      textAreas[index].classList.add("empty");
      customBtn.classList.add("not-correct");
      setTimeout(() => {
        customBtn.classList.remove("not-correct");
      }, 500);
      return;
    }
    console.log(itemData);
    const selectedCategory = itemData.categories?.filter(
      (item) => item.selected === true
    );
    const selectedCategoryId = selectedCategory.map((item) => {
      return parseInt(item.id);
    });
    console.log(selectedCategoryId);

    const sendData = {
      location_id: 1,
      item_title: name.toUpperCase(),
      data: itemData.dataItems.filter((item) => item.data !== ""),
      created_by: 1,
      api_key: apiKey,
      categories: selectedCategoryId,
      comments: [],
      images: itemData.images,
      link: itemData.link,
      documents: itemData.documents,
    };
    console.log("Kreiranje slanje podataka", sendData);
    try {
      axios
        .post(`${baseUrl}/items/createNewItem2.php`, {
          location_id: location.location_id,
          item_title: name.toUpperCase(),
          data: itemData.dataItems.filter((item) => item.data !== ""),
          created_by: loggedUser.id,
          api_key: apiKey,
          categories: selectedCategoryId,
          comments: [],
          images: itemData.images,
          links: itemData.link,
          document: itemData.documents,
        })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            const count_c = itemData.categories.filter(
              (category) => category.selected
            ).length;
            const newListItem = {
              id: data.data.item_id,
              name: loggedUser.name,
              timeCreated: new Date(),
              data: itemData.dataItems.filter((item) => item.data !== ""),
              title: name.toUpperCase(),
              links_count: itemData.link === "" ? 0 : 1,
              document_count: itemData.documents.length === 0 ? 0 : 1,
              categories_count: count_c,
              categories: itemData.categories,
              images_count: itemData.images.length,
              comments: [],
              timeModified: null,
              visibility: true,
              images: itemData.images,
              links: itemData.link === "" ? [] : itemData.link,
              documents: itemData.documents,
              user_id: loggedUser.id,
            };
            console.log(newListItem);
            customBtn.classList.add("correct-btn");
            const time = setTimeout(() => {
              setName("");
              setItemData(itemDataDefault);
              setIsNameEntered(false);
              setImageModalActive(false);
              setIsNameFocused(false);

              const textAreas = document.querySelectorAll(
                ".editor-data-entry-textarea"
              );
              textAreas.forEach((textarea) => (textarea.value = ""));

              setItemList([...itemList, newListItem]);
              customBtn.classList.remove(".correct-clicked");
              clearTimeout(time);
            }, 500);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
    // kreiranje stavke

    // reset
  };

  const handleImageClick = async () => {
    setImageModalActive(!imageModalActive);
  };

  const handlePieClick = ({ index }) => {
    console.log("Poziva katetogrija", itemData.id, index);
    if (index !== undefined) {
      try {
        axios
          .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
            location_id: parseInt(location.location_id),
            api_key: apiKey,
            user_id: loggedUser.id,
            item_id: index,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              console.log("Postavlja se vrednost kategorije");

              setItemData({ ...itemData, categories: data.data.categories });
            }
            setCategoryModalActive(!categoryModalActive);
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    } else {
      setCategoryModalActive(!categoryModalActive);
    }
    console.log("Category modal creating...");
  };

  const handleLinkClick = (order) => {
    console.log("Link/Document modal creating...", order);
    if (!counterDocument) {
      try {
        axios
          .post(`${baseUrl}/items/getAllDocumentsByItemId.php`, {
            item_id: itemData.id,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              setItemData({ ...itemData, documents: data.data.documents[0] });
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    }
    setCounterDocumet(true);
    setAtachModalActive(!atachModalActive);
    setNewLinks([]);
  };

  const handleDetails = () => {
    if (itemList.length === 0) return;
    setDetailsChecked(!detailsChecked);
    const buttons = document.querySelector(".editor-buttons");
    buttons.scrollIntoView();
  };

  const handleArchiveClose = () => {
    console.log("Archive close");
    setCopy({ index: null, value: false });
    const header = document.querySelector(".editor-header");
    header.style.zIndex = 0;
    setOpenAddLink(false);
    setArchiveClicked(false);
    setArchiveActive(false);
    setFilterListItemActive(false);
    setFilterState({
      startDate: "",
      endDate: "",
      message: { count: 0, value: 0 }, //0 se gasi 1 crveno 2 zeleno
      documents: { count: 0, value: 0 },
      link: { count: 0, value: 0 },
      image: { count: 0, value: 0 },
      category: [],
      people: [],
    });
    try {
      axios
        .post(`${baseUrl}/items/getAllItemsByLocationId.php`, {
          location_id: location.location_id,
          api_key: apiKey,
          user_id: loggedUser.id,
        })
        .then((data) => {
          console.log(data.data);
          if (data.data.status === 200) {
            setItemList(data.data.items);
          } else {
            setItemList([]);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
    setDetailsChecked(false);
    setFilterModalActive(false);
  };

  const handleArchiveClicked = (e) => {
    const dosBtn = e.currentTarget.querySelector(".dos-btn");
    dosBtn.classList.add("correct-btn");
    const time = setTimeout(() => {
      setArchiveClicked(true);
      setFilterActive(true);
      dosBtn.classList.remove("correct-btn");
      clearTimeout(time);
    }, 500);
  };

  const handleFilterOpenArchiveClose = () => {
    setFilterActive(!filterActive);
    setFilterModalActive(!filterModalActive);
  };

  const handleBack = () => {
    if (archiveActive) setArchiveClicked(true);
    setCopy({ index: null, value: false });
  };

  console.log("Lokacija kategorije", location);
  console.log(loggedUser);
  const handlePdf = async () => {
    const responses = [];
    const responsesCategory = [];

    console.log("Usao sam u pdf");
    itemList.forEach((item) => {
      const response = axios.post(`${baseUrl}/items/getAllFieldsByItemId.php`, {
        item_id: item.id,
        api_key: apiKey,
        user_id: loggedUser.id,
      });
      responses.push(response);
    });
    console.log("Zavrsio se pozvi");
    const fullfilled = await Promise.all(responses);
    console.log(fullfilled);

    itemList.forEach((item) => {
      const responseC = axios.post(
        `${baseUrl}/categories/getAllCategoriesByLocationId.php`,
        {
          item_id: item.id,
          api_key: apiKey,
          location_id: location.location_id,
          user_id: loggedUser.id,
        }
      );
      responsesCategory.push(responseC);
    });
    const fullfilledC = await Promise.all(responsesCategory);
    console.log(fullfilledC);

    setItemList(
      itemList.map((item, index) => {
        return {
          ...item,
          data: fullfilled[index].data.data,
          comments: fullfilled[index].data.comments,
          categories: fullfilledC[index].data.categories,
        };
      })
    );
    setPdfActive(true);
  };

  const setLinksLength = (link, document, validate) => {
    console.log("Racunam vrednosti nakon update", link, document);
    let count = 0;

    if (validate) {
      count = link + document;
    } else {
      if (link !== "") {
        count++;
      }
      if (document?.data !== "" && document?.length !== 0) {
        count++;
      }
    }

    return count;
  };

  const handleSort = () => {
    let newItemList = [];
    if (!sortingChecked)
      // pretpostavimo da kada su se dodavale nove stavke
      // da je inicijalno sortirano u rastucem redosledu
      newItemList = itemList.sort((a, b) => {
        return compareAsc(a.timeCreated, b.timeCreated);
      });
    else
      newItemList = itemList.sort((a, b) => {
        return compareAsc(b.timeCreated, a.timeCreated);
      });
    setItemList(newItemList);
    setSortingChecked(!sortingChecked);
    const buttons = document.querySelector(".editor-buttons");
    buttons.scrollIntoView();
  };

  const handleArchiveClick = async (e) => {
    const customBtn = e.currentTarget.querySelector(".dos-btn");

    try {
      axios
        .post(`${baseUrl}/items/getCommentsSeen.php`, {
          location_id: location.location_id,
          user_id: loggedUser.id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log(data);

          if (data.status === 200) {
            console.log(data);
            if (data.data.items.length === 0) {
              // dugme treba da pocrveni na kratko
              customBtn.classList.add("not-correct");
              setTimeout(() => {
                customBtn.classList.remove("not-correct");
              }, 500);
            } else {
              setArchive(data.data.items);
              setArchiveData(data.data.items);
              setArchiveClicked(true);
            }
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleFocusName = () => {
    console.log("Kada je on pozvan ne treba da se zove", isNameFocused);
    if (!isNameFocused) {
      try {
        axios
          .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
            location_id: parseInt(location.location_id),
            api_key: apiKey,
            user_id: loggedUser.id,
            item_id: undefined,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              console.log("Postavlja se vrednost kategorije");

              setItemData({
                ...itemData,
                categories:
                  data.data.categories === undefined
                    ? []
                    : data.data.categories,
              });
            } else {
              setItemData({ ...itemData, categories: [] });
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    }
    setIsNameFocused(true);
    console.log("Postavlja se vrednost ulaska");
  };

  const updateItem = (e) => {
    //console.log(editing.info);
    //console.log(itemList);
    console.log(itemData);
    const customBtn = e.currentTarget.querySelector(".dos-btn");
    customBtn.classList.add("correct-btn");
    const sendData = {
      item_id: itemData.id,
      location_id: 1,
      item_title: name.toUpperCase(),
      data: itemData.dataItems.filter((item) => item.data !== ""),
      created_by: 1,
      api_key: apiKey,
      category_id: 1,
      comments: [],
      images: itemData.images === undefined ? [] : itemData.images,
      link: itemData.link === undefined ? "" : itemData.link,
      documents: itemData.documents === undefined ? [] : itemData.documents,
    };
    const selectedCategory = itemData.categories?.filter(
      (item) => item.selected === true
    );
    const selectedCategoryId = selectedCategory?.map((item) => {
      return parseInt(item.id);
    });
    console.log("Update stavke", sendData);
    try {
      axios
        .post(`${baseUrl}/items/updateItem2.php`, {
          item_id: itemData.id,
          location_id: location.location_id,
          item_title: name.toUpperCase(),
          data: itemData.dataItems.filter((item) => item.data !== ""),
          created_by: loggedUser.id,
          api_key: apiKey,
          categories: selectedCategoryId,
          comments: [],
          images: itemData.images === undefined ? [] : itemData.images,
          links: itemData.link === undefined ? "" : itemData.link,
          document: itemData.documents === undefined ? [] : itemData.documents,
        })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            const countItem = itemData?.categories?.filter(
              (cat) => cat.selected === true
            ).length;
            console.log(countItem);
            const time = setTimeout(() => {
              setName("");
              setItemData(itemDataDefault);
              setIsNameEntered(false);
              setImageModalActive(false);
              setIsNameFocused(false);
              const newItemList = itemList.map((item) => {
                console.log(item);
                if (item.id === editing.info.id) {
                  return {
                    ...item,
                    data: itemData.dataItems,
                    title: name.toUpperCase(),
                    links: itemData.link,
                    documents: itemData.documents,
                    categories: itemData.categories,
                    images: itemData.images,
                    images_count:
                      itemData.images === undefined
                        ? []
                        : itemData.images.length,
                    document_count:
                      itemData.documents === undefined ||
                      itemData.documents?.length === 0
                        ? 0
                        : 1,
                    links_count: itemData.link === undefined ? 0 : 1,
                    categories_count: countItem,
                  };
                } else {
                  return item;
                }
              });
              setItemList(newItemList);
              const textAreas = document.querySelectorAll(
                ".editor-data-entry-textarea"
              );
              textAreas.forEach((textarea) => (textarea.value = ""));
              customBtn.classList.remove(".correct-clicked");
              clearTimeout(time);
            }, 500);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleBackPattern = () => {
    try {
      axios
        .post(`${baseUrl}/items/getAllFields.php`, {
          location_id: location.location_id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log(data.data);
          if (data.data.status === 200) {
            setItemData(data.data.default);
            setItemDataDefault(data.data.default);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  window.onresize = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
    if (isLandscape) setFilterActive(true);
    else setFilterActive(false);
  };

  const setImagesCount = (itemData) => {
    if (itemData !== undefined) {
      return itemData.images.length;
    } else {
      return 0;
    }
  };

  const setDocumentLinkCount = (itemData) => {
    console.log("Postavlja se kaunter za dokument", itemData);

    /* if (id === undefined) return 0;
    const item = itemList.find((i) => i.id === id);
    return item?.document_count + item?.links_count;
*/
    if (itemData !== undefined) {
      console.log(
        "Uslov jedan",
        itemData.link !== "" ? itemData.link.length : 0
      );
      console.log("Uslov dva", itemData.documents.data !== undefined ? 1 : 0);
      console.log(
        "Zbirni uslov",
        parseInt(itemData.link !== "" ? itemData.link.length : 0) +
          parseInt(itemData.documents.data !== undefined ? 1 : 0)
      );
      return (
        parseInt(itemData.link !== "" ? itemData.link.length : 0) +
        parseInt(itemData.documents.data !== undefined ? 1 : 0)
      );
    } else {
      return 0;
    }
  };

  const setCategoriesCount = (itemData) => {
    console.log("Kategorija", itemData);

    if (itemData !== undefined) {
      const count = itemData.categories.filter(
        (category) => category.selected
      ).length;
      return count;
    } else {
      return 0;
    }
  };

  const getAllReportsForSelectedDay = (day) => {
    const reports = archive.filter(
      (item) =>
        compareAsc(day, new Date(item.timeCreated).setHours(0, 0, 0, 0)) === 0
    );
    return reports;
  };

  const handleDateColumnClick = () => {
    const reports = getAllReportsForSelectedDay(
      new Date(new Date(itemList[0]?.timeCreated).setHours(0, 0, 0, 0))
    );
    console.log(reports);
    try {
      axios
        .post(`${baseUrl}/items/updateSeen.php`, {
          item_id: reports[0].id,
          api_key: apiKey,
          user_id: loggedUser.id,
        })
        .then((data) => {
          console.log(data);
          if (data.data.status === 200) {
            setArchiveActive(true);
            setArchiveClicked(false);
            setItemList(reports);
            setDetailsChecked(true);
            setFromDateColumn(false);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  /*useEffect(() => {
    if (editing.value) {
      console.log(editing.info);
      const textAreas = document.querySelectorAll(
        ".editor-data-entry-textarea"
      );
      editing.info.data.forEach((item, i) => {
        textAreas[i].value = item.data;
      });
    }
  }, [editing]);*/

  useEffect(() => {
    // console.log(itemData);
  }, [itemData]);

  // console.log(itemData);
  useEffect(() => {
    handleBackItem();
    handleBackPattern();
  }, []);

  console.log(itemData);
  console.log(itemList);
  console.log("Ovo je filteredData", filteredData);

  if (isLandscape) {
    return (
      <div className="editor">
        <div className="editor-top">
          <div className="editor-top-left">
            {!archiveActive && (
              <div className="editor-information">
                <div className="editor-information-header">
                  <div className="number">{`${itemList.length + 1}.`}</div>
                  <div className="name">{loggedUser.name}</div>
                  <div className="time">{format(new Date(), "hh:mm")}</div>
                </div>
                <div className="editor-information-textarea">
                  <textarea
                    rows="2"
                    onChange={handleChangeName}
                    value={name}
                    placeholder="..."
                    onFocus={() => handleFocusName}
                  />
                  {isNameFocused && (
                    <div
                      className="editor-information-textarea-btn"
                      onClick={handleReset}
                    >
                      <Button image={closeImg} activation={true}></Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!archiveActive && (
              <div className="editor-buttons-top">
                <div
                  className={
                    isNameEntered
                      ? "editor-buttons-top-btn active"
                      : "editor-buttons-top-btn"
                  }
                  onClick={handlePieClick}
                >
                  <Button active={categoryModalActive} image={categoriesImg} />
                  <div className="editor-button-top-circle">{0}</div>
                </div>
                <div
                  className={
                    isNameEntered
                      ? "editor-buttons-top-btn active"
                      : "editor-buttons-top-btn"
                  }
                  onClick={handleImageClick}
                >
                  <Button
                    image={imagesImg}
                    active={imageModalActive}
                    activation={!imageModalActive}
                  />
                  <div className="editor-button-top-circle">
                    {itemData?.images.length}
                  </div>
                </div>
                <div
                  className={
                    isNameEntered
                      ? "editor-buttons-top-btn active"
                      : "editor-buttons-top-btn"
                  }
                  onClick={() => handleLinkClick(1)}
                >
                  <Button image={attachImg} activation={true} />
                  <div className="editor-button-top-circle">{0}</div>
                </div>
                <div
                  className={
                    isNameEntered
                      ? "editor-buttons-top-btn active"
                      : "editor-buttons-top-btn"
                  }
                  onClick={editing.value ? updateItem : createItem}
                >
                  <Button image={sendImg} activation={true} />
                </div>
              </div>
            )}
          </div>
          <div className="editor-top-right">
            <div
              className={`${
                filterModalActive
                  ? "editor-header z-index-landscape"
                  : "editor-header"
              }`}
            >
              <div
                className="editor-header-btn"
                onClick={() => setCalendarClick(!calendarClick)}
              >
                <Button image={calendarImg} active={calendarClick} />
              </div>
              <div className="editor-header-middle">
                {archiveClicked ? (
                  <div className="editor-header-middle-archive">
                    <div
                      className="editor-header-archive-btn cancel"
                      onClick={handleArchiveClose}
                    >
                      <Button image={closeImg} />
                    </div>
                    <div
                      className="editor-header-archive-btn filter"
                      onClick={handleFilterOpenArchiveClose}
                    >
                      <Button active={filterModalActive}>
                        <img src={filterImg} className="archive-icon" alt="" />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="editor-header-middle-main">
                    <div
                      className="editor-header-middle-btn"
                      onClick={(e) =>
                        archiveActive
                          ? handleArchiveClose()
                          : handleArchiveClicked(e)
                      }
                    >
                      <Button
                        activation={!archiveActive}
                        active={archiveActive}
                      >
                        <span className="editor-header-middle-span">
                          ARCHIVE
                        </span>
                      </Button>
                    </div>
                    <span className="editor-date">
                      {format(
                        archiveActive ? itemList[0]?.timeCreated : new Date(),
                        "dd.MM.yy"
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="editor-header-btn">
                <Button image={folderImg} activation={true} />
              </div>
            </div>
            <div className="editor-buttons">
              <div className="editor-buttons-btn" onClick={handleBack}>
                <Button activation={true} image={backImg} />
              </div>
              <div
                className={
                  isNameEntered || itemList.length < 2
                    ? "editor-buttons-btn not-active"
                    : "editor-buttons-btn"
                }
                onClick={handleSort}
              >
                <Button active={sortingChecked} image={sortImg} />
              </div>
              <div
                className={
                  isNameEntered || itemList.length === 0
                    ? "editor-buttons-btn not-active"
                    : "editor-buttons-btn"
                }
                onClick={handleDetails}
              >
                <Button
                  active={detailsChecked}
                  image={detailsChecked ? detailsShrinkImg : detailsImg}
                  activation={true}
                />
              </div>
              <div
                className={
                  isNameEntered || itemList.length === 0
                    ? "editor-buttons-btn not-active"
                    : "editor-buttons-btn"
                }
                onClick={handlePdf}
              >
                <Button activation={true} image={pdfImg} />
              </div>
            </div>
          </div>
        </div>
        <div className="editor-bottom-wrapper">
          <div className="editor-bottom">
            {!archiveActive && (isNameEntered || isNameFocused) && (
              <EditorEntries
                isNameEntered={isNameEntered}
                itemData={itemData}
                setItemData={setItemData}
              />
            )}
            {itemList.length !== 0 && (
              <EditorListItems
                itemList={itemList}
                detailsChecked={detailsChecked}
                setItemList={setItemList}
                archiveActive={archiveActive}
                setIsNameEntered={setIsNameEntered}
                setIsNameFocused={setIsNameFocused}
                setName={setName}
                setEditing={setEditing}
                setItemData={setItemData}
                itemData={itemData}
                setLinksLength={setLinksLength}
              />
            )}
            {imageModalActive && (
              <ImageModal
                isLandscape={isLandscape}
                itemData={itemData}
                setItemData={setItemData}
                loggedUser={loggedUser}
                isAdding={true}
                capacity={4}
                itemList={itemList}
                setItemList={setItemList}
                order={4}
                user_id={isNameFocused ? loggedUser.id : undefined}
                setImageModalActive={setImageModalActive}
              />
            )}
            {calendarClick && (
              <CalendarWork
                timeOfCreate={timeOfCreate}
                setTimeOfCreate={setTimeOfCreate}
              />
            )}
          </div>
        </div>
        {archiveClicked && !filterModalActive && !filterListItemActive && (
          <Archive
            setArchiveActive={setArchiveActive}
            setArchiveClicked={setArchiveClicked}
            archive={archive}
            setItemList={setItemList}
            setDetailsChecked={setDetailsChecked}
            daysArchive={daysArchive}
            setDaysArchive={setDaysArchive}
            setFromDateColumn={setFromDateColumn}
          />
        )}
        {filterModalActive && <FilterArchiveLandscape />}
      </div>
    );
  } else {
    return (
      <div className="editor">
        <div className="editor-header">
          <div
            className="editor-header-btn"
            onClick={() => setCalendarClick(!calendarClick)}
          >
            <Button image={calendarImg} active={calendarClick} />
          </div>
          <div className="editor-header-middle">
            {archiveClicked ? (
              <div className="editor-header-middle-archive">
                <div
                  className="editor-header-archive-btn cancel"
                  onClick={handleArchiveClose}
                >
                  <Button image={closeImg} />
                </div>
                <div
                  className="editor-header-archive-btn filter"
                  onClick={handleFilterOpenArchiveClose}
                >
                  <Button active={filterModalActive}>
                    <img src={filterImg} className="archive-icon" alt="" />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="editor-header-middle-main">
                <div
                  className="editor-header-middle-btn"
                  onClick={(e) =>
                    archiveActive ? handleArchiveClose() : handleArchiveClick(e)
                  }
                >
                  <Button activation={!archiveActive} active={archiveActive}>
                    <span className="editor-header-middle-span">ARCHIVE</span>
                  </Button>
                </div>
                <span>{location.location_name}</span>
              </div>
            )}
          </div>
          <div
            className="editor-header-btn"
            onClick={() => setFolderClicked(!folderClicked)}
          >
            <Button image={folderImg} active={folderClicked} />
          </div>
        </div>
        <hr className="editor-line-scroll" />
        {!filterListItemActive && (
          <div
            className={`${
              archiveActive
                ? "editor-data-wrapper archive-active"
                : "editor-data-wrapper"
            }`}
          >
            <div className="editor-data-content">
              {!archiveActive && (
                <div className="editor-information">
                  <div className="editor-information-header">
                    <div className="number">
                      {editing.value
                        ? editing.info.id
                        : `${itemList?.length + 1}.`}
                    </div>
                    <div className="name">
                      {loggedUser.name === undefined || loggedUser.name === ""
                        ? loggedUser.user_email
                        : loggedUser.name}
                    </div>
                    <div className="time">{format(new Date(), "HH:mm")}</div>
                  </div>
                  <div className="editor-information-textarea">
                    <textarea
                      rows="2"
                      onChange={handleChangeName}
                      placeholder="..."
                      onFocus={handleFocusName}
                      value={name}
                    />
                    {isNameFocused && (
                      <div
                        className="editor-information-textarea-btn"
                        onClick={handleReset}
                      >
                        <Button image={closeImg} activation={true}></Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!archiveActive && (isNameEntered || isNameFocused) && (
                <EditorEntries
                  isNameEntered={isNameEntered}
                  itemData={itemData}
                  setItemData={setItemData}
                />
              )}
              {!archiveActive && (
                <div
                  className="editor-buttons-top"
                  style={{ zIndex: isNameFocused ? 20 : 0 }}
                >
                  <div
                    className={
                      isNameEntered
                        ? "editor-buttons-top-btn active"
                        : "editor-buttons-top-btn"
                    }
                    onClick={handlePieClick}
                  >
                    <Button
                      active={categoryModalActive}
                      image={categoriesImg}
                    />
                    <div className="editor-button-top-circle">
                      {setCategoriesCount(itemData)}
                    </div>
                  </div>
                  <div
                    className={
                      isNameEntered
                        ? "editor-buttons-top-btn active"
                        : "editor-buttons-top-btn"
                    }
                    onClick={handleImageClick}
                  >
                    <Button
                      image={imagesImg}
                      active={imageModalActive}
                      activation={!imageModalActive}
                    />
                    <div className="editor-button-top-circle">
                      {setImagesCount(itemData)}
                    </div>
                  </div>
                  <div
                    className={
                      isNameEntered
                        ? "editor-buttons-top-btn active"
                        : "editor-buttons-top-btn"
                    }
                    onClick={() => handleLinkClick(1)}
                  >
                    <Button image={attachImg} active={atachModalActive} />
                    <div className="editor-button-top-circle">
                      {setDocumentLinkCount(itemData)}
                    </div>
                  </div>
                  <div
                    className={
                      isNameEntered
                        ? "editor-buttons-top-btn active"
                        : "editor-buttons-top-btn"
                    }
                    onClick={editing.value ? updateItem : createItem}
                  >
                    <Button image={sendImg} activation={true} />
                  </div>
                </div>
              )}
              <hr className="editor-line" />
              <span className="editor-date">
                {itemList[0]?.timeCreated !== undefined &&
                  format(
                    archiveActive ? itemList[0]?.timeCreated : new Date(),
                    "dd.MM.yy"
                  )}
              </span>
              <div className="editor-buttons">
                <div className="editor-buttons-btn" onClick={handleBack}>
                  <Button activation={true} image={backImg} />
                </div>

                {archiveActive && fromDateColumn ? (
                  <div
                    className="archive-list-item-date"
                    onClick={() => handleDateColumnClick()}
                  >
                    <Button activation={true}>
                      <span className="archive-date">
                        {format(
                          new Date(
                            new Date(itemList[0]?.timeCreated).setHours(
                              0,
                              0,
                              0,
                              0
                            )
                          ),
                          "dd.MM.yyyy"
                        )}
                      </span>
                    </Button>
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        isNameEntered || itemList?.length < 2
                          ? "editor-buttons-btn not-active"
                          : "editor-buttons-btn"
                      }
                      onClick={handleSort}
                    >
                      <Button active={sortingChecked} image={sortImg} />
                    </div>
                    <div
                      className={
                        isNameEntered || itemList.length === 0
                          ? "editor-buttons-btn not-active"
                          : "editor-buttons-btn"
                      }
                      onClick={handleDetails}
                    >
                      <Button
                        active={detailsChecked}
                        image={detailsChecked ? detailsShrinkImg : detailsImg}
                        activation={true}
                      />
                    </div>
                    <div
                      className={
                        isNameEntered || itemList.length === 0
                          ? "editor-buttons-btn not-active"
                          : "editor-buttons-btn"
                      }
                      onClick={handlePdf}
                    >
                      <Button activation={true} image={pdfImg} />
                    </div>
                  </>
                )}
              </div>
              {itemList.length !== 0 && (
                <EditorListItems
                  itemList={itemList}
                  detailsChecked={detailsChecked}
                  setItemList={setItemList}
                  archiveActive={archiveActive}
                  setIsNameEntered={setIsNameEntered}
                  setIsNameFocused={setIsNameFocused}
                  setName={setName}
                  setEditing={setEditing}
                  setItemData={setItemData}
                  itemData={itemData}
                  setLinksLength={setLinksLength}
                  editing={editing}
                />
              )}
              {imageModalActive && (
                <ImageModal
                  isLandscape={isLandscape}
                  itemData={itemData}
                  setItemData={setItemData}
                  loggedUser={loggedUser}
                  isAdding={true}
                  capacity={4}
                  setItemList={setItemList}
                  itemList={itemList}
                  order={5}
                  realItemData={itemData}
                  setRealItemData={setItemData}
                  user_id={isNameFocused ? loggedUser.id : undefined}
                />
              )}
              {categoryModalActive && (
                <CategoryModal
                  itemData={itemData}
                  setItemData={setItemData}
                  isAdding={true}
                  order={1}
                />
              )}
              {atachModalActive && !openAddLink && (
                <DocumentLinkItemModal
                  realItemData={itemData}
                  itemData={itemData}
                  setItemData={setItemData}
                  activeAdd={true}
                  setSelectAccount={setSelectAccount}
                  setArchiveClicked={setArchiveClicked}
                  setArchive={setArchive}
                  setArchiveData={setArchiveData}
                />
              )}
            </div>
          </div>
        )}

        {filterListItemActive && (
          <div className="editor-data-wrapper">
            <FilterListItem
              setFilterListItemActive={setFilterListItemActive}
              setFilterModalActive={setFilterModalActive}
              setArchiveClicked={setArchiveClicked}
              setArchiveActive={setArchiveActive}
              filteredData={filteredData}
              call={true}
              account={account}
            />
          </div>
        )}

        {archiveClicked && !filterModalActive && !filterListItemActive && (
          <Archive
            setArchiveActive={setArchiveActive}
            setArchiveClicked={setArchiveClicked}
            archive={archive}
            setItemList={setItemList}
            setDetailsChecked={setDetailsChecked}
            daysArchive={daysArchive}
            setDaysArchive={setDaysArchive}
            setFromDateColumn={setFromDateColumn}
          />
        )}
        {filterModalActive && (
          <FilterArchive
            setArchive={setArchive}
            archiveData={archiveData}
            filterState={filterState}
            setFilterState={setFilterState}
            daysArchive={daysArchive}
            setFilterModalActive={setFilterModalActive}
            setFilterListItemActive={setFilterListItemActive}
            setArchiveClicked={setArchiveClicked}
            setArchiveActive={setArchiveActive}
            setFilteredData={setFilteredData}
          />
        )}

        {calendarClick && <CalendarWork />}
        {pdfActive && (
          <PdfViewer
            setPdfActive={setPdfActive}
            itemList={itemList}
            setItemList={setItemList}
            isFilter={true}
            account={account}
            filterData={filteredData}
          />
        )}
        {folderClicked && <FolderModal />}
      </div>
    );
  }
};

export default Editor;
