import React, { useContext, useEffect, useState } from "react";
import { languages } from "../Vocabulary/Vocabulary";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [account, setAccount] = useState();
  const [loggedUser, setLoggedUser] = useState(undefined);
  const [controlEmail, setControlEmail] = useState(undefined);
  const [baseUrl] = useState("https://data.nadzor.app/api");
  const [apiKey, setApiKey] = useState("");
  const [currentChat, setCurrentChat] = useState(null);
  const [entryKey, setEntryKey] = useState("");
  const [location, setLocation] = useState();
  const [activeIconsMiddle, setActiveIconsMiddle] = useState([]);
  const [openAddLink, setOpenAddLink] = useState(false);
  const [newLinks, setNewLinks] = useState([]);
  const [copy, setCopy] = useState({ index: null, value: false });
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  console.log(location);

  const values = {
    selectedLanguage,
    setSelectedLanguage,
    loggedUser,
    setLoggedUser,
    baseUrl,
    account,
    setAccount,
    apiKey,
    setApiKey,
    location,
    setLocation,
    entryKey,
    setEntryKey,
    currentChat,
    setCurrentChat,
    activeIconsMiddle,
    setActiveIconsMiddle,
    controlEmail,
    setControlEmail,
    openAddLink,
    setOpenAddLink,
    newLinks,
    setNewLinks,
    copy,
    setCopy,
    isAndroid,
    setIsAndroid,
    isIOS,
    setIsIOS,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
