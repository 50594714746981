import mail from "../picture/Mail.png";
import key from "../picture/Key.png";
import send from "../picture/Send.png";
import Button from "../Button/Button";
//import data from "./data";
import "./login.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import plus from "../picture/Plus.png";
import user from "../picture/User.png";
const Login = ({ setCurrentPage, currentPage }) => {
  const inputsRef = useRef([]);
  const resetTimeRef = useRef();
  const [remaining, setRemaining] = useState(2);
  const [keyCurrent, setKeyCurrent] = useState("");
  const [mailCurrent, setMailCurrent] = useState("");
  const [registerUser, setRegisterUser] = useState(false);
  const countDown = useRef();
  countDown.current = 60000;

  const { selectedLanguage } = useGlobalContext();
  const { setLoggedUser } = useGlobalContext();
  const { setControlEmail } = useGlobalContext();
  const { setApiKey } = useGlobalContext();

  const { baseUrl } = useGlobalContext();

  const handleInputMail = (e) => {
    let mailUser = document.querySelector(".input-mail");
    if (mailUser.classList.contains("not-correct-content"))
      mailUser.classList.remove("not-correct-content");

    setMailCurrent(e.currentTarget.value);
  };

  const handleInputKey = (index) => {
    console.log("Unos prvog");
    if (
      index < inputsRef.current.length - 1 &&
      inputsRef.current[index].value !== ""
    ) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleLogin = async (e) => {
    //mladendubovac98@gmail.com
    //uFhOdn
    const mailUser = {
      id: 0,
      user_email: mailCurrent,
      user_pin: keyCurrent,
      isTemp: false,
      pattern: "",
      privilage: "",
      firstContact: "",
      secondContact: "",
      name: "",
    };
    const inputData = {
      user_email: mailUser.user_email,
      user_pin: mailUser.user_pin,
    };

    try {
      if (!registerUser) {
        const { data } = await axios.post(
          `${baseUrl}/auth/login.php`,
          inputData
        );

        if (data.status === 200) {
          mailUser.id = parseInt(data.user_id);
          setApiKey(data.api_key);
          mailUser.name = data.name;
          if (data.pattern) {
            mailUser.pattern = data.pattern;
          } else {
            mailUser.pattern = "";
          }
        } else {
          const dataAuth = await axios.post(`${baseUrl}/auth/checkEmail.php`, {
            user_email: mailUser.user_email,
          });

          if (dataAuth.data.status === 200) {
            mailUser.id = dataAuth.data.id;
            mailUser.user_pin = null;
          }
        }
      } else {
        const { data } = await axios.post(`${baseUrl}/auth/checkEmail.php`, {
          user_email: mailUser.user_email,
        });

        if (data.status === 200) {
          mailUser.id = data.id;
        }
      }
    } catch (error) {
      console.log(error);
    }

    //napomena promeniti key ako je status code 404 i promeniti id

    if (registerUser) {
      if (mailUser.id !== 0) {
        setRegisterUser(false);
      } else {
        setCurrentPage(0);
      }
    } else {
      if (
        mailUser.id !== 0 &&
        mailUser.user_pin === keyCurrent &&
        keyCurrent !== ""
      ) {
        let customBtn = document.querySelector(".dos-btn");
        customBtn.classList.add("correct");
        const time = setTimeout(() => {
          setCurrentPage(4);
          customBtn.classList.remove("correct");
          setLoggedUser(mailUser);
          clearTimeout(time);
        }, 500);
      }

      if (mailUser.id !== 0 && mailUser.user_pin !== keyCurrent) {
        const inputMail = document.querySelector(".input-mail");
        const loginButton = document.querySelector(".login-button");
        let customBtn = loginButton.querySelector(".dos-btn");

        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        const submit = document.querySelector(".login-button");

        if (remaining === 2) {
          customBtn.classList.add("not-correct");
          inputs.forEach((input) => {
            input.style.color = "red";
          });
          const time = setTimeout(() => {
            setRemaining(1);
            customBtn.classList.remove("not-correct");
            inputs.forEach((input) => {
              input.style.color = "black";
              input.innerHTML = "";
            });
            inputs[0].focus();
            setKeyCurrent("");
            inputMail.style.color = "green";
            clearTimeout(time);
          }, 500);
        } else if (remaining === 1) {
          customBtn.classList.add("not-correct");
          inputs.forEach((input) => {
            input.style.color = "red";
            input.style.pointerEvents = "none";
          });
          inputMail.style.pointerEvents = "none";
          submit.style.pointerEvents = "none";
          const wrapper = document.querySelector(".login-wrapper");
          const numberDiv = document.createElement("span");
          numberDiv.innerHTML = countDown.current / 1000;
          numberDiv.classList.add("countdown-span");
          wrapper.insertBefore(numberDiv, wrapper.firstChild);
          console.log("Dodajem number div", numberDiv);
          const interval = setInterval(() => {
            countDown.current -= 1000;
            numberDiv.innerHTML = countDown.current / 1000;
          }, 1000);
          const time = setTimeout(() => {
            setRemaining(0);
            customBtn.classList.remove("not-correct");
            inputs.forEach((input) => {
              input.style.color = "black";
              input.innerHTML = "";
              input.style.pointerEvents = "auto";
            });
            inputMail.style.pointerEvents = "auto";
            inputs[0].focus();

            setKeyCurrent("");
            submit.style.pointerEvents = "auto";
            resetTimeRef.current = setTimeout(() => {
              setCurrentPage(0);
              clearTimeout(resetTimeRef.current);
            }, 10000);
            numberDiv.innerHTML = "";
            clearInterval(interval);
            clearTimeout(time);
          }, 60000);
        } else if (remaining === 0) {
          setControlEmail(mailCurrent);
          console.log(mailCurrent);
          setCurrentPage(7);
        }
      }

      if (mailUser.id === 0) {
        const customBtn = document.querySelector(".dos-btn");

        //let customBtn = e.currentTarget.querySelector(".dos-btn");

        customBtn.classList.add("not-correct");
        let mailUser = document.querySelector(".input-mail");
        mailUser.classList.add("not-correct-content");
        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        inputs.forEach((input) => {
          input.style.color = "black";
          input.innerHTML = "";
          input.style.pointerEvents = "auto";
        });
        const time = setTimeout(() => {
          setRegisterUser(true);
          mailUser.focus();
          customBtn.classList.remove("not-correct");
          clearTimeout(time);
        }, 500);
      }
    }
  };

  const handleStyleInput = () => {
    const inputKey = document.querySelector(".input-key");
    const inputMail = document.querySelector(".input-mail");
    if (inputKey !== null) {
      const inputs = inputKey.querySelectorAll("input");
      const inputKeyRect = inputKey.getBoundingClientRect();
      inputs.forEach((input) => {
        input.style.fontSize = ` ${(inputKeyRect.height * 90) / 100}px`;
      });
      if (inputMail !== null) {
        inputMail.style.fontSize = ` ${(inputKeyRect.height * 50) / 100}px`;
      }
    }
  };

  const handleChange = (index, value) => {
    console.log("Poziv drugog");
    // Zabranjujemo unos space-a
    if (value === " ") {
      return; // Tj. ignorisemo unos space-a
    }
    clearTimeout(resetTimeRef.current);
    setKeyCurrent((prevPassword) => {
      const newPassword =
        prevPassword.substring(0, index) +
        value +
        prevPassword.substring(index + 1);
      return newPassword;
    });
    // Resetujemo state kad korisnik pocne opet da unosi, tj. da ispravlja lozinku
  };

  const handleBackspace = (index, event) => {
    console.log("Poziv treceg");
    if (
      event.keyCode === 8 &&
      index > 0 &&
      inputsRef.current[index].value === ""
    ) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleRegisterUser = (e) => {
    let customBtn = e.currentTarget.querySelector(".dos-btn");
    customBtn.classList.add("correct");
    const time = setTimeout(() => {
      setCurrentPage(6);
      customBtn.classList.remove("correct");
      clearTimeout(time);
    }, 500);
  };

  useEffect(() => {
    setRemaining(2);
  }, []);

  handleStyleInput();

  useEffect(() => {
    console.log("Ostalo pokusaja", remaining);
    if (remaining === 1) {
      resetTimeRef.current = setTimeout(() => {
        const inputMail = document.querySelector(".input-mail");
        let customBtn = document.querySelector(".dos-btn");
        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        const submit = document.querySelector(".login-button");

        customBtn.classList.add("not-correct");
        inputs.forEach((input) => {
          input.style.color = "red";
          input.style.pointerEvents = "none";
        });
        inputMail.style.pointerEvents = "none";
        submit.style.pointerEvents = "none";
        inputs[0].blur();
        const wrapper = document.querySelector(".login-wrapper");
        const numberDiv = document.createElement("span");
        numberDiv.innerHTML = countDown.current / 1000;
        numberDiv.classList.add("countdown-span");
        console.log("Dodajem number div", numberDiv);
        wrapper.insertBefore(numberDiv, wrapper.firstChild);
        const interval = setInterval(() => {
          countDown.current -= 1000;
          numberDiv.innerHTML = countDown.current / 1000;
        }, 1000);
        const time = setTimeout(() => {
          setRemaining(0);
          customBtn.classList.remove("not-correct");
          inputs.forEach((input) => {
            input.style.color = "black";
            input.innerHTML = "";
            input.style.pointerEvents = "auto";
          });
          inputMail.style.pointerEvents = "auto";
          inputs[0].focus();
          setKeyCurrent("");
          submit.style.pointerEvents = "auto";
          resetTimeRef.current = setTimeout(() => {
            setCurrentPage(0);
            clearTimeout(resetTimeRef.current);
          }, 10000);
          numberDiv.innerHTML = "";
          clearInterval(interval);
          clearTimeout(time);
        }, 60000);
        clearTimeout(resetTimeRef.current);
      }, 10000);
    }
  }, [remaining]);

  window.onresize = () => {
    if (!registerUser) handleStyleInput();
  };
  return (
    <div className="login-wrapper">
      {registerUser && (
        <div className="register-wrapper">
          <div className="register-text">
            <span>{selectedLanguage.data.registrationAddress}</span>
            <span>{selectedLanguage.data.readTerms}</span>
          </div>
          <div className="register-button" onClick={handleRegisterUser}>
            <Button activation={true}>
              <div className="register-button-img">
                <img src={plus} />
                <img src={user} />{" "}
              </div>
            </Button>
          </div>
          <div className="register-text">
            <span>{selectedLanguage.data.noUser}</span>
            <span>{selectedLanguage.data.newAddress}</span>
          </div>
        </div>
      )}
      <img src={mail} alt="" />
      <input
        value={mailCurrent}
        className="input-mail"
        onChange={handleInputMail}
      />
      {!registerUser && (
        <>
          <img src={key} alt="" />
          <div className="input-key">
            {[...Array(6)].map((_, index) => (
              <input
                type="text"
                maxLength="1"
                key={index}
                ref={(input) => (inputsRef.current[index] = input)}
                value={keyCurrent[index] || ""}
                onChange={(e) => handleChange(index, e.target.value)}
                onInput={() => handleInputKey(index)}
                onKeyDown={(e) => handleBackspace(index, e)}
                autoCapitalize="off"
              />
            ))}
          </div>
        </>
      )}
      <div className="login-button" onClick={(e) => handleLogin(e)}>
        <Button image={send} activation={true} />
      </div>
    </div>
  );
};
export default Login;
