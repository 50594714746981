import React from "react";
import "./languagesHeader.css";
import Button from "../../Button/Button";

// Icons
import Back from "./icons/back.png";
import Share from "../../picture/Share.png";
import Close from "./icons/close.png";

function LanguagesHeader({ shareBack, setCurrentPage, currentPage }) {
  console.log(shareBack);

  const handleClickBack = (shareBack) => {
    if (shareBack && currentPage > 0) {
      if(currentPage === 3)
      setCurrentPage(currentPage -2)  
        else
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="languages-header-container">
      <div className="languages-header">
        <div
          className="languages-header-button"
          onClick={() => handleClickBack(shareBack ? true : false)}
        >
          <Button className="back-button">
            <img src={shareBack ? Back : Share} alt="" />
          </Button>
        </div>
        <div className="languages-header-button">
          <Button className="close-button">
            <img src={Close} alt="" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LanguagesHeader;
