import axios from "axios";
import { useGlobalContext } from "../../../Context/Context";
import Button from "../../Button/Button";
import { useState } from "react";
import documentImg from "../../dokument.png";
import addDocument from "../../plus.png";
import link from "../../link.png";
import FilterListItem from "../../Archive/FilterListItem/FilterListItem";
import "./documentLinkItemPreview.css";

const DocumentLinkItemPreview = ({
  itemData,
  attachModalActive,
  atachModalCommentActive,
  realItemData,
}) => {
  const maxFileNameLength = 40;
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const [itemClick, setItemClick] = useState([]);
  const [openFilterListItem, setOpenFilterListItem] = useState(false);
  const setSettingData = () => {
    if (attachModalActive === undefined) {
      console.log("Prvi");
      return {
        document: itemData.document,
        link: itemData.link,
      };
    }

    if (attachModalActive.isCommenting === undefined) {
      console.log("drugi");
      return {
        document: itemData.comments[atachModalCommentActive.value].document[0],
        link: itemData.comments[atachModalCommentActive.value].link,
      };
    }

    if (attachModalActive.isCommenting) {
      console.log("Treci");
      return { document: realItemData.document, link: realItemData.link };
    } else {
      console.log("Cetvrti");
      console.log(itemData);
      return {
        document: itemData?.documents.length === 0 ? [] : itemData.documents[0],
        link: itemData.links,
      };
    }
  };
  const [data, setData] = useState(setSettingData());

  const handleOpenNewLink = (item) => {
    if (item.link !== null) {
      window.open(`https://${item.link}`);
    } else {
      console.log(item);
      const sendData = {
        user_id: loggedUser.id,
        api_key: apiKey,
        itemId: item.itemId,
      };
      console.log(sendData);
      try {
        axios
          .post(`${baseUrl}/items/getItemForLink.php`, sendData)
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              setItemClick(data.data.item);
            } else {
              setItemClick([]);
            }
            setOpenFilterListItem(!openFilterListItem);
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    }
  };

  return (
    <>
      <div className="image-document-wrapper">
        <div className="wrapper-container-link">
          {itemData.links !== "" &&
            itemData.links !== undefined &&
            itemData.links.map((addLink, i) => {
              return (
                <div className="wrapper-added-link">
                  <div
                    className="add-link-button button-link preview-link"
                    onClick={() => handleOpenNewLink(addLink)}
                  >
                    <Button activation={true}>
                      <div className="wrapper-count-name">
                        <span>{i}</span>
                        <div className="add-link-name ">{addLink.name}</div>
                      </div>
                    </Button>
                  </div>
                </div>
              );
            })}
          {data.document !== undefined && data.document.length !== 0 && (
            <div className="wrapper-added-link">
              <div className="add-link-button button-link preview-link">
                <Button activation={true}>
                  <div className="wrapper-count-name">
                    <a
                      className="download-link download-link-height"
                      download={data.document?.name}
                      href={data.document?.data}
                    >
                      <img src={documentImg} alt="" />
                      <div className="add-link-name ">
                        {data.document?.name?.length > maxFileNameLength
                          ? `${data.document.name.slice(
                              0,
                              maxFileNameLength
                            )}...`
                          : data.document.name}{" "}
                      </div>
                    </a>{" "}
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="wrapper-link-button">
          <div className="document-link-add">
            <div className={"document-add disable-link"}>
              <label
                className="button-image-document-link"
                htmlFor="file-upload"
              >
                <img src={addDocument} alt="" className="" />
                <img src={documentImg} alt="" className="" />
              </label>
              <input accept="application/*" id="file-upload" type="file" />
            </div>
            <div className={"document-add disable-link"}>
              <Button active={data.link === ""} activation={true}>
                <div className="button-image-document-link">
                  <img src={addDocument} alt="" className="" />
                  <img src={link} alt="" className="" />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {openFilterListItem && (
        <FilterListItem
          setFilterListItemActive={setOpenFilterListItem}
          call={false}
          filteredData={itemClick}
        />
      )}
    </>
  );
};

export default DocumentLinkItemPreview;
