import { useState, useRef, useEffect } from "react";
import Button from "../Button/Button";
import SingleAccount from "../Accounts/SingleAccount";
import Editor from "../Editor/Editor/Editor";
import Message from "../Chat/Message";
import Settings from "../Settings/Settings";
//import data from "./data";
import "./accounts.css";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import TrustFolder from "../TrustFolder/TrustFolder";
import HomePage from "../HomePage/HomePage";
import Link from "../Links/Link";
import LanguageApp from "../LanguagePage/LanguageApp/LanguageApp";
import LinksItemModal from "../LinksItemModal/LinksItemModal";

const Accounts = ({
  selectAccount,
  setSelectAccount,
  setSelectAccountPin,
  setCurrentPageGlobal,
}) => {
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const [accounts, setAccounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const { account, setAccount } = useGlobalContext();
  const [checkBack, setCheckBack] = useState(false);
  const { setLocation } = useGlobalContext();

  const handleAccount = async (item) => {
    setAccount(item);
    console.log(item);
    try {
      const { data } = await axios.post(
        `${baseUrl}/companies/getUserLocationsByCompanyId.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          company_id: item.id,
        }
      );
      console.log(data);
      if (data.status === 200) {
        console.log("Usao drugi ", data.locations);
        setSelectAccount(2);
        setLocations(data.locations);
      }
    } catch (data) {
      console.log(data);
    }
  };

  const handleChooseLocation = (location) => {
    setSelectAccount(3);
    setLocation(location);
  };

  console.log(locations);
  useEffect(() => {
    const handleAccountData = async () => {
      try {
        console.log(loggedUser.id);
        console.log(apiKey);
        const { data } = await axios.post(
          `${baseUrl}/companies/getCompaniesByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setAccounts(data.companies);
          console.log("{postavljam account");
        }
      } catch (data) {}
      console.log("pozic apiKey");
    };

    console.log("render");
    handleAccountData();
  }, []);

  console.log("Render", selectAccount);
  console.log(account);

  return (
    <>
      {(selectAccount === 2 || selectAccount === 1 || selectAccount === 6) && (
        <div className="accounts-wrapper">
          {selectAccount === 2 && (
            <SingleAccount
              setSelectAccount={setSelectAccount}
              selectAccount={selectAccount}
              locations={locations}
            />
          )}
          {selectAccount === 1 && (
            <div className="wrapper-location">
              <div className="name-account">{account.name.toUpperCase()}</div>
              <div className="concret-location">
                {locations.map((location) => {
                  return (
                    <div
                      className="location-name"
                      onClick={() => handleChooseLocation(location)}
                    >
                      <Button activation={true}>
                        {" "}
                        <div className="location-name-current">
                          {location.location_name}
                        </div>
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="logo-account">
                {account.logo === "" ? (
                  account.name[0].toUpperCase()
                ) : (
                  <img src={account.logo} alt="" />
                )}{" "}
              </div>
            </div>
          )}
          {selectAccount === 6 && (
            <div className="account-list">
              {accounts.map((item, index) => {
                return (
                  <div
                    className="account"
                    onClick={() => handleAccount(item)}
                    key={index}
                  >
                    <Button>
                      {item.logo === "" ? (
                        <div className="account-button">
                          <span className="initial">
                            {item.name[0].toUpperCase()}
                          </span>
                          <span>{item.name.toUpperCase()}</span>
                        </div>
                      ) : (
                        <div className="account-button">
                          <img
                            className="account-button-image"
                            src={item.logo}
                            alt=""
                          ></img>
                          <span>{item.name.toUpperCase()}</span>
                        </div>
                      )}
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {selectAccount === 4 && (
        <Settings
          selectAccount={selectAccount}
          setSelectAccountPin={setSelectAccountPin}
        />
      )}
      {selectAccount === 5 && (
        <Message
          checkBack={checkBack}
          setCheckBack={setCheckBack}
          currentTab={setSelectAccountPin}
        />
      )}
      {selectAccount === 0 && (
        //treba ubaciti home-page

        <HomePage setSelectAccount={setSelectAccount}></HomePage>
      )}
      {selectAccount === 3 && (
        <Editor setSelectAccount={setSelectAccount} account={account} />
      )}
      {selectAccount === 11 && <Link setSelectAccount={setSelectAccount} />}

      {selectAccount === 10 && (
        <LanguageApp
          setCurrentPageGlobal={setCurrentPageGlobal}
          setSelectAccount={setSelectAccount}
        />
      )}
    </>
  );
};

export default Accounts;
