import "./newFindUser.css";
import EditPen from "../picture/EditPen.png";
import axios from "axios";
import { format } from "date-fns";
import Button from "../Button/Button";
import Send from "../picture/Send.png";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../Context/Context";

const NewFindUser = ({
  searchTearm,
  setAddChat,
  setSelectAddChat,
  handleSelectMessage,
}) => {
  const [data, setData] = useState();
  const { apiKey, baseUrl, loggedUser } = useGlobalContext();

  console.log(searchTearm);
  const handleSendMessage = async () => {
    const wrapperMessage = document.querySelector(".wrapper-message");
    const message = wrapperMessage.querySelector("input");
    console.log(message.value);
    console.log(loggedUser.user_email);
    console.log(searchTearm.chat_room_id);
    if (message.value === "") return;
    if (searchTearm.chat_room_id === 0) return;

    try {
      const sendData = await axios.post(`${baseUrl}/chat/sendMessage.php`, {
        sender: loggedUser.user_email,
        text: message.value,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        api_key: apiKey,
        chat_room_id: parseInt(searchTearm.chat_room_id),
      });
    } catch (error) {
      console.log(error);
    }
    console.log(data.user_id);

    const selectMessage = handleSelectMessage({ id: data.user_id });
    if (selectMessage) {
      setAddChat(false);
      setSelectAddChat(false);
    }
  };

  useEffect(() => {
    const handleSetUser = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/chat/getProfileByEmail.php`,
          {
            user_email: searchTearm.name,
            api_key: apiKey,
          }
        );
        console.log(response);
        setData(response.data.profile);
      } catch (error) {
        console.log(error);
      }
    };

    handleSetUser();
  }, []);
  console.log(data);
  return (
    <div className="new-find-user">
      <div className="wrapper-logo-pen">
        <div className="wrapper-logo-name">
          <div className="user-logo">
            <img src={data?.cp_image} alt="" />
          </div>
          <div className="user-name">{data?.cp_name}</div>
        </div>
        <div className="wrapper-edit-pen">
          <div className="button-edit-pen">
            <Button image={EditPen}></Button>
          </div>
          <div className="button-edit-pen">
            <Button image={EditPen}></Button>
          </div>
        </div>
      </div>
      <div className="wrapper-mail">{data?.user_email}</div>
      <div className="wrapper-message">
        <input></input>
        <div className="button-message-send" onClick={handleSendMessage}>
          <Button activation={true} image={Send}></Button>
        </div>
      </div>
    </div>
  );
};

export default NewFindUser;
