import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";
import cancel from "../picture/Cancel.png";
import "./message.css";

const Message = ({ setActiveExit }) => {
  const { selectedLanguage } = useGlobalContext();
  return (
    <div className="message-wrapper">
      <div className="message-container">
        <img
          src={cancel}
          alt=""
          className="cancel-message"
          onClick={() => setActiveExit(false)}
        />
        <span className="message-text">
          {selectedLanguage.data.messageCancel}
        </span>
        <div className="leave-button" onClick={() => window.close()}>
          <Button activation={true}>
            <span className="leave-application">
              {selectedLanguage.data.leave}
            </span>{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Message;
