import { useGlobalContext } from "../Context/Context";
import "./termsOfUse.css";
import cancel from "../picture/Cancel.png";
import Button from "../Button/Button";
import { useState } from "react";
import Message from "../Message/Message";

const TermsOfUse = ({ setCurrentPage }) => {
  const { selectedLanguage } = useGlobalContext();
  const [activeExit, setActiveExit] = useState(false);
  //console.log(selectedLanguage);
  return (
    <div className="terms-of-use-wrapper">
      <img
        src={cancel}
        alt=""
        className="close-terms"
        onClick={() => setActiveExit(true)}
      />
      <div className="title-terms">{selectedLanguage.data.titleTerms}</div>
      <div className="content-terms">{selectedLanguage.data.termsOfUse}</div>
      <div className="send-terms" onClick={() => setCurrentPage(3)}>
        <Button>
          <span className="send-terms-span">{selectedLanguage.data.agree}</span>
        </Button>
      </div>
      {activeExit && <Message setActiveExit={setActiveExit} />}
    </div>
  );
};

export default TermsOfUse;
