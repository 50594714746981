import "./folderModal.css";
import Button from "../../Button/Button";
import calendarImg from "../calendar.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import UndoIcon from "@mui/icons-material/Undo";
import { MobilePDFReader } from "react-read-pdf";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { format, compareAsc } from "date-fns";
import AddFolderModal from "./AddFolderModal";
import { useGlobalContext } from "../../Context/Context";
import FilterCalendar from "../FilterCalendar";

const FolderModal = () => {
  const { baseUrl, location } = useGlobalContext();
  const { apiKey, loggedUser } = useGlobalContext();
  const maxDocuments = 5;
  const [index, setIndex] = useState(0);
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [openCalendar, setOpenCalendar] = useState(null);
  const [startDocuments, setStartDocuments] = useState([]); // svi dokumenti
  const [viewDocument, setViewDocument] = useState({
    id: 0,
    value: false,
    data: "",
    name: "",
  });
  const [filteredDocuments, setFilteredDocuments] = useState([]); // dokumenti koji se trenutno prikazauju

  const { isIOS, isAndroid } = useGlobalContext();

  const setCurrentDocuments = (index, array, documents) => {
    const newArray = documents.length === 0 ? array : documents;
    return newArray.slice(
      index * maxDocuments,
      index * maxDocuments + maxDocuments
    );
  };

  const [documents, setDocuments] = useState(
    setCurrentDocuments(0, [], startDocuments)
  ); // dokumenti na selektovanoj stranici koji se prikazuju

  const [addingDocument, setAddingDocument] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [selectedComboBox, setSelectedComboBox] = useState(false);
  const [filterData, setFilterData] = useState({
    filterText: "",
    filterDates: {
      from: "",
      to: "",
    },
  });

  const setPagination = (array) => {
    const numberOfPages =
      array.length % maxDocuments === 0
        ? parseInt(array.length / maxDocuments)
        : parseInt(array.length / maxDocuments + 1);
    let numbers = [];
    for (let i = 0; i < numberOfPages; i++) numbers.push(i + 1);
    return numbers;
  };

  const numbersList = useRef(setPagination([]));
  const comboBoxRef = useRef();

  const handleReset = () => {
    setIsFilters(false);
    setFilteredDocuments(startDocuments);
    setDocuments(setCurrentDocuments(0, [], startDocuments));
    numbersList.current = setPagination(startDocuments);
    setFilterData({
      filterText: "",
      filterDates: {
        from: "",
        to: "",
      },
    });
    setDates({ from: "", to: "" });
  };
  const handleResetView = () => {
    setViewDocument({ id: 0, value: false, data: "", name: "" });
  };

  const handleAddDocument = () => {
    setAddingDocument(true);
  };

  const handlePreviousPage = () => {
    if (index === 0) return;
    else {
      setDocuments(setCurrentDocuments(index - 1, [], filteredDocuments));
      setIndex(index - 1);
      setIsFilters(false);
      setSelectedComboBox(false);
    }
  };

  const handleSelectedComboBox = () => {
    setSelectedComboBox(!selectedComboBox);
    setIsFilters(false);
  };

  const handleChangeIndex = (i) => {
    handleSelectedComboBox();
    handleSelectIndex(i);
  };

  const handleSelectIndex = (i) => {
    setIndex(i);
    setDocuments(setCurrentDocuments(i, [], filteredDocuments));
  };

  const handleNextPage = () => {
    if (index >= numbersList.current.length - 1) return;
    else {
      setIndex(index + 1);
      setDocuments(setCurrentDocuments(index + 1, [], filteredDocuments));
      setSelectedComboBox(false);
      setIsFilters(false);
    }
  };

  const downloadDocument = (doc) => {
    try {
      axios
        .post(`${baseUrl}/location_documents/getDocumentData.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          location_document_id: doc.id,
        })
        .then((data) => {
          if (data.data.status === 200) {
            const link = document.createElement("a");
            link.download = doc.name;
            link.href = data.data.data;
            link.click();
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  let dataURItoBlob = (dataURI) => {
    console.log(dataURI.split(",")[1]);
    var byteString = atob(dataURI.split(",")[1]);

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    console.log(ia.buffer);

    var blob = new Blob([ia.buffer], { type: "aplication/pdf" });
    console.log(blob.stream());
    var pdfUrl = window.URL.createObjectURL(blob);
    console.log(pdfUrl);

    return pdfUrl;
  };

  const handleViewDocument = (document) => {
    try {
      axios
        .post(`${baseUrl}/location_documents/getDocumentData.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          location_document_id: document.id,
        })
        .then((data) => {
          console.log("Poziva se i postavlja item list", data);
          if (data.data.status === 200) {
            console.log(data.data.data);

            var blob = dataURItoBlob(data.data.data);
            console.log(blob);

            setViewDocument({
              id: document.id,
              value: true,
              data: data.data.data,
              name: document.name,
            });
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleSort = (cond) => {
    let sortedDocuments = filteredDocuments.sort((a, b) => {
      if (cond) return a.id - b.id;
      else return b.id - a.id;
    });
    setFilteredDocuments(sortedDocuments);
    setDocuments(setCurrentDocuments(0, [], sortedDocuments));
    setIndex(0);
    setIsFilters(false);
    setSelectedComboBox(false);
  };

  const handleActiveFilters = () => {
    setIsFilters(!isFilters);
  };

  const handleSearchText = (e) => {
    const text = e.target.value;
    setFilterData({ ...filterData, filterText: text });
  };

  console.log(filterData);

  const handleClickCalendar = (index) => {
    if (index === openCalendar) setOpenCalendar(null);
    else {
      setOpenCalendar(index);
    }
  };

  const handleClickSearch = () => {
    let newDocuments = startDocuments;
    if (filterData.filterText !== "") {
      newDocuments = newDocuments.filter((document) => {
        return document.name.includes(filterData.filterText);
      });
    }
    // 1 => prvi datum je veci od drugog datuma
    // -1 => drugi datum je veci od prvog
    // 0 => datumi su isti i mora da sadrzi i vreme kako bi vrsio poredjenje
    let filteredNewDocuments = [];
    console.log(dates);
    if (dates.from !== "" || dates.to !== "") {
      newDocuments = newDocuments.map((doc) => {
        return {
          ...doc,
          date: formatDate(doc),
        };
      });
      newDocuments.forEach((doc) => {
        const documentDate = new Date(doc.date.toString());
        if (
          (dates.from === "" &&
            compareAsc(
              new Date(new Date(doc.date.setHours(0, 0, 0, 0))),
              dates.to
            ) <= 0) ||
          (dates.to === "" &&
            compareAsc(
              dates.from,
              new Date(new Date(doc.date.setHours(0, 0, 0, 0)))
            ) <= 0) ||
          (compareAsc(
            new Date(new Date(doc.date.setHours(0, 0, 0, 0))),
            dates.from
          ) >= 0 &&
            compareAsc(
              new Date(new Date(doc.date.setHours(0, 0, 0, 0))),
              dates.to
            ) <= 0)
        ) {
          filteredNewDocuments.push({ ...doc, date: documentDate });
        }
      });
      console.log(filteredNewDocuments);
    }
    const dataToSet =
      dates.from !== "" || dates.to !== ""
        ? filteredNewDocuments
        : newDocuments;
    console.log(dataToSet);
    console.log(filteredNewDocuments);
    console.log(newDocuments);
    setFilterData({ filterText: "", filterDates: { from: "", to: "" } });
    setFilteredDocuments(dataToSet);
    setDocuments(setCurrentDocuments(0, [], dataToSet));
    setIsFilters(false);
    numbersList.current = setPagination(dataToSet);
  };

  const formatDate = (doc) => {
    if (typeof doc.date === "object") return doc.date;
    else {
      const date = doc.date.split(" ")[0];
      const day = date.split(".")[0];
      const month = date.split(".")[1];
      const year = date.split(".")[2];
      const hours = doc.date.split(" ")[1].split(":")[0];
      const minutes = doc.date.split(" ")[1].split(":")[1];
      const newDate = new Date(
        parseInt(year),
        parseInt(month),
        parseInt(day),
        parseInt(hours),
        parseInt(minutes)
      );
      return newDate;
    }
  };

  console.log(filteredDocuments);
  console.log(documents);

  useEffect(() => {
    const handleGetDocuments = async () => {
      try {
        axios
          .post(`${baseUrl}/location_documents/getDocuments.php`, {
            user_id: loggedUser.id,
            location_id: location.location_id,
            api_key: apiKey,
          })
          .then((data) => {
            console.log("Poziva se vraca dokumenta", data.data.documents);
            if (data.data.status === 200) {
              setStartDocuments(data.data.documents);
              setFilteredDocuments(data.data.documents);
              setDocuments(
                setCurrentDocuments(0, data.data.documents, data.data.documents)
              );
              numbersList.current = setPagination(data.data.documents);
            } else {
              setStartDocuments([]);
              setFilteredDocuments([]);
              setDocuments(setCurrentDocuments(0, [], data.data.documents));
              numbersList.current = setPagination([]);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleGetDocuments();
  }, []);

  return (
    <div className="folder-modal">
      {!viewDocument.value ? (
        <>
          <div className="envelope-header">
            <h4>Documents</h4>
            {isFilters ? (
              <div className="envelope-reset" onClick={handleReset}>
                <Button activation={true}>
                  <RestartAltIcon />
                </Button>
              </div>
            ) : (
              <div className="envelope-header-sort">
                <div className="sort-up" onClick={() => handleSort(true)}>
                  <Button activation={true}>
                    <div className="triangle-sort" />
                  </Button>
                </div>
                <div className="sort-up" onClick={() => handleSort(false)}>
                  <Button activation={true}>
                    <div className="triangle-container">
                      <div className="triangle-sort active" />
                    </div>
                  </Button>
                </div>
              </div>
            )}
            {isFilters && (
              <div className="envelope-wrapper-filters">
                <div className="envelope-filters-search">
                  <input
                    name="input"
                    className="filter-input"
                    onChange={(e) => handleSearchText(e)}
                  />
                  <div
                    className="envelope-filters-search-button"
                    onClick={handleClickSearch}
                  >
                    <Button activation={true}>
                      <SearchIcon />
                    </Button>
                  </div>
                </div>
                <div className="envelope-filters-date">
                  <div className="envelope-filters-date-from-to">
                    <span className="first-span">Od</span>
                    <span className="second-span">
                      {dates.from !== "" &&
                        `${format(dates.from, "dd")}.${format(
                          dates.from,
                          "MM"
                        )}.${format(dates.from, "yyyy")}`}
                    </span>
                    <div
                      className="envelope-filters-date-button"
                      onClick={() => handleClickCalendar(1)}
                    >
                      <Button
                        active={openCalendar === 1}
                        image={calendarImg}
                      ></Button>
                    </div>
                  </div>
                  <div className="envelope-filters-date-from-to">
                    <span className="first-span">Do</span>
                    <span className="second-span">
                      {dates.to !== "" &&
                        `${format(dates.to, "dd")}.${format(
                          dates.to,
                          "MM"
                        )}.${format(dates.to, "yyyy")}`}
                    </span>
                    <div
                      className="envelope-filters-date-button"
                      onClick={() => handleClickCalendar(2)}
                    >
                      <Button
                        active={openCalendar === 2}
                        image={calendarImg}
                      ></Button>
                    </div>
                  </div>
                  {openCalendar && (
                    <FilterCalendar
                      dates={dates}
                      setDates={setDates}
                      isFrom={openCalendar === 1 ? true : false}
                      setOpenCalendar={setOpenCalendar}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              className="envelope-header-filter"
              onClick={handleActiveFilters}
            >
              <Button active={isFilters}>
                <div className="filter-container">
                  <h4>SVE</h4>
                  <div className="wraper-triangle">
                    <div
                      className={
                        isFilters
                          ? "triangle-envelope active"
                          : "triangle-envelope"
                      }
                    />
                  </div>
                </div>
              </Button>
            </div>
          </div>
          <div className="add-document" onClick={handleAddDocument}>
            <Button activation={true}>
              <FileUploadIcon />
            </Button>
          </div>
          <div className="envelope-documents">
            {documents?.map((document, i) => {
              const { date, id, data, name } = document;
              console.log(document);
              return (
                <article className="basic-document" key={id}>
                  <div className="header">
                    <span>
                      {format(formatDate(document), "dd.MM.yyyy HH:mm")}
                    </span>
                    <h4>
                      {index * maxDocuments + i + 1}. {name}
                    </h4>
                  </div>
                  <div className="footer">
                    <span>{data}</span>
                    <div className="basic-document-options">
                      <div
                        className="basic-document-button"
                        onClick={() => handleViewDocument(document)}
                      >
                        <Button activation={true}>
                          <VisibilityIcon />
                        </Button>
                      </div>
                      <div className="basic-document-button">
                        <Button activation={true}>
                          <div
                            className="basic-document-download-link"
                            onClick={() => downloadDocument(document)}
                          >
                            <DownloadIcon />
                          </div>
                        </Button>
                      </div>
                      <div className="basic-document-button">
                        <Button activation={true}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="envelope-pagination">
            <div
              className="envelope-pagination-btn"
              onClick={handlePreviousPage}
            >
              <Button activation={true}>
                <ArrowLeftIcon />{" "}
              </Button>
            </div>
            <div className="envelope-pagination-list">
              {numbersList.current.length < 3 ? (
                numbersList.current.map((page, i) => {
                  return (
                    <div
                      className="envelope-page"
                      key={i}
                      onClick={() => handleSelectIndex(i)}
                    >
                      <Button active={i === index} activation={i === index}>
                        {page}
                      </Button>
                    </div>
                  );
                })
              ) : (
                <>
                  <div
                    className="envelope-page combo-box"
                    onClick={handleSelectedComboBox}
                  >
                    <Button active={selectedComboBox}>
                      {index + 1}
                      <div className="wraper-triangle">
                        <div
                          className={
                            selectedComboBox
                              ? "triangle-envelope active"
                              : "triangle-envelope"
                          }
                        />
                      </div>
                    </Button>
                    {selectedComboBox && (
                      <div className="envelope-combo-box" ref={comboBoxRef}>
                        <div className="wraper-envelope-combo-box">
                          {numbersList.current.map((number, ind) => {
                            return (
                              <div
                                className="envelope-combo-box-option"
                                key={ind}
                                onClick={() => handleChangeIndex(ind)}
                              >
                                <Button>{number} </Button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="envelope-page">
                    <Button activation={true}>...</Button>
                  </div>
                  <div className="envelope-page">
                    <Button activation={true}>
                      {numbersList.current.length}
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div className="envelope-pagination-btn" onClick={handleNextPage}>
              <Button activation={true}>
                <ArrowRightIcon />{" "}
              </Button>
            </div>
          </div>
          {addingDocument && (
            <AddFolderModal
              setAddingDocument={setAddingDocument}
              setDocuments={setDocuments}
              documents={documents}
            />
          )}
        </>
      ) : (
        <div className="document-view-wrapper">
          <div className="wrapper-header">
            <div className="full-screen" onClick={handleResetView}>
              <Button activation={true}>
                <UndoIcon />
              </Button>
            </div>
            <div className="document-title">{viewDocument.name}</div>
          </div>

          {!isAndroid && !isIOS ? (
            <iframe src={viewDocument.data} height="92%" width="100%" />
          ) : (
            <MobilePDFReader
              url={viewDocument.data}
              height="90%"
              width="100%"
            ></MobilePDFReader>
          )}
        </div>
      )}
    </div>
  );
};

export default FolderModal;
