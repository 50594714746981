import { useRef } from "react";
import Button from "../../Button/Button";
import sendImg from "../../slanje.png";

const ImageModalAdd = ({
  itemData,
  setItemData,
  image,
  setDraftImages,
  draftImages,
  index,
  isAdding,
  itemList,
  imageModalActive,
  capacity,
  realItemData,
  setRealItemData,
  localImages,
  setLocalImages,
}) => {
  const title = useRef();

  const handleAddImage = (e) => {
    const buttonImage = e.currentTarget.querySelector(".dos-btn");
    buttonImage.classList.add("correct-btn");
    const time = setTimeout(() => {
      buttonImage.classList.remove("correct-btn");
      const imageModalScroll = document.querySelector(".image-modal");
      imageModalScroll.scrollIntoView();
      const newDraftImages = draftImages.filter((_, i) => i !== index);
      setDraftImages(newDraftImages);
      if (capacity === 4) {
        const newImages = [
          { data: draftImages[index].data, title: title.current.value },
          ...itemData.images,
        ];
        if (isAdding) setItemData({ ...itemData, images: newImages });
        else {
          setItemData(
            itemList.map((item, index) => {
              if (index === imageModalActive)
                return { ...item, images: newImages };
              else return item;
            })
          );
        }
        setLocalImages(newImages);
      } else {
        const newImages = [
          { data: draftImages[index].data, title: title.current.value },
          ...realItemData.images,
        ];
        setRealItemData({ ...realItemData, images: newImages });
        setLocalImages(newImages);
      }
      clearTimeout(time);
    }, 500);
  };

  return (
    <div className="image-modal-settings">
      <input className="image-modal-name-input" ref={title} />
      <div className="image-modal-icon" onClick={handleAddImage}>
        <Button image={sendImg} activation={true} />
      </div>
    </div>
  );
};

export default ImageModalAdd;
