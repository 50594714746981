import starImg from "../../zvezda.png";
import "./editorEntries.css";

const EditorEntries = ({ isNameEntered, itemData, setItemData }) => {
  const handleDataChange = (e, ID) => {
    if (!isNameEntered) return;
    let newDataItems = itemData.dataItems.map((id) => {
      if (id.id === ID) return { ...id, data: e.currentTarget.value };
      else return id;
    });
    setItemData({ ...itemData, dataItems: newDataItems });
    /*if (
      e.currentTarget.value === "" &&
      !e.currentTarget.classList.contains("empty")
    )
      e.currentTarget.classList.add("empty");*/
    if (
      e.currentTarget.value !== "" &&
      e.currentTarget.classList.contains("empty")
    )
      e.currentTarget.classList.remove("empty");
  };

  return (
    <div
      className={`${
        itemData.dataItems?.length === 1
          ? "editor-data-entries-wrapper single-data"
          : "editor-data-entries-wrapper"
      }`}
    >
      <div className="editor-data-entries">
        {itemData?.dataItems?.map((item, index) => {
          const { id, name, important, data } = item;

          return (
            <article className="editor-data-entry" key={index}>
              <div className="editor-data-entry-header">
                <h5 className="editor-data-entry-title">{name}</h5>
                {important && <img src={starImg} alt="" />}
              </div>
              <textarea
                className={"editor-data-entry-textarea"}
                value={data}
                onChange={(e) => handleDataChange(e, id)}
              />
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default EditorEntries;
