import { useEffect } from "react";
import { useGlobalContext } from "../Context/Context";
import { PDFDownloadLink } from "@react-pdf/renderer";
import cancel from "../picture/Cancel.png";
import download from "../picture/Download.png";
import "./userManual.css";
import Pdf from "../Pdf/Pdf";

const UserManual = ({ setSelectAccountPin }) => {
  const { selectedLanguage } = useGlobalContext();
  const handleStyleUserManual = () => {
    const sidebar = document.querySelector(".sidebar");
    const userManual = document.querySelector(".user-manual-wrapper");

    if (userManual !== null) {
      if (sidebar.classList.contains("hidden")) {
        console.log("nevidljiv");
        userManual.style.width = "100vw";
      } else {
        console.log("vidljiv");
        userManual.style.width = `calc(100vw - 11.11vh)`;
      }
    }
  };

  useEffect(() => {
    handleStyleUserManual();
  });

  return (
    <div className="user-manual-wrapper">
      <div className="user-manual-container">
        <div className="user-manual-pdf">
          <PDFDownloadLink
            document={<Pdf text={selectedLanguage.data.termsOfUse} />}
            className="cart-buttons-btn pdf"
          >
            <img className="user-manual-pdf-img" src={download} alt="" />
          </PDFDownloadLink>
        </div>

        <img src={cancel} alt="" onClick={() => setSelectAccountPin(0)} />
      </div>
      <span className="user-manual-text">
        {selectedLanguage.data.termsOfUse}
      </span>
    </div>
  );
};

export default UserManual;
