import React, { useState } from 'react';
import LogoPage from "./LogoPage/LogoPage";
import LanguageApp from "./LanguagePage/LanguageApp/LanguageApp";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import Login from "./Login/Login";
import ForgetPassword from "./Login/ForgetPassword";
import RegisterChoose from "./RegisterChoose/RegisterChoose";
import Sidebar from "./Sidebar/Sidebar";
import ControlPassword from "./forgetPassword/ControlPassword/ControlPassword";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Import the service worker registration
import "./app.css";

const App = () => {
  const [currentPage, setCurrentPage] = useState(0);

  console.log(currentPage);
  return (
    <div className="app">
      {currentPage === 0 && <LogoPage setCurrentPage={setCurrentPage} />}
      {currentPage === 1 && <LanguageApp setCurrentPageGlobal={setCurrentPage} />}
      {currentPage === 2 && <TermsOfUse setCurrentPage={setCurrentPage} />}
      {currentPage === 3 && (
        <Login setCurrentPage={setCurrentPage} currentPage={currentPage} />
      )}
      {currentPage === 4 && <Sidebar setCurrentPage={setCurrentPage} />}
      {currentPage === 5 && <ForgetPassword />}
      {currentPage === 6 && <RegisterChoose setCurrentPage={setCurrentPage} />}
      {currentPage === 7 && <ControlPassword setCurrentPage={setCurrentPage} />}
    </div>
  );
};

// Register the service worker
serviceWorkerRegistration.register();

export default App;
