import { React, useState, useEffect } from "react";
import "./button.css";

function Button({
  image,
  active,
  children,
  activation,
  isDisabled,
  isCorrect,
}) {
  let [isActive, setActive] = useState(false);
  let [activeClass, setActiveClass] = useState("");
  let [idleClass, setIdleClass] = useState("");

  useEffect(() => {
    setIdleClass("dos-btn");
    setActiveClass("dos-btn-active");
  }, []);

  useEffect(() => {
    setActive(active);
  }, [active]);

  const handleButtonActive = (event) => {
    if (activation) return;
    setActive((current) => !current);
  };

  const checkActiveNotCorrect = () => {
    if (isCorrect) return `${idleClass} correct`;
    else return idleClass;
  };

  return (
    <button
      className={isActive ? activeClass : checkActiveNotCorrect()}
      onClick={handleButtonActive}
      disabled={isDisabled}
    >
      {image !== undefined && <img src={image} alt="" />}
      {children}
    </button>
  );
}

export default Button;
