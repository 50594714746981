import "./editorListItems.css";
import messageImg from "../../komentar.png";
import categoriesImg from "../../kategorije.png";
import attachImg from "../../prilog.png";
import imagesImg from "../../slika.png";
import editImg from "../../olovka.png";
import userPicture from "../../korisnik.png";
import Button from "../../Button/Button";
import closeImg from "../../zatvaranje.png";
import sendImg from "../../slanje.png";
import copyImg from "../../6.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useRef, useState } from "react";
import ImageModal from "../ImageModal/ImageModal";
import { compareAsc, format } from "date-fns";
import FilterArchive from "../../Archive/FilterArchive/FilterArchive";
import DocumentLinkModal from "../DocumentLinkModal/DocumentLinkModal";
import { itemDataDefault } from "../../data";
import axios from "axios";
import { useGlobalContext } from "../../../Context/Context";
import CategoryModal from "../../Category/CategoryModal";
import DocumentLinkItemModal from "../DocumentLinkItemModal/DocumentLinkItemModal";
import DocumentLinkItemPreview from "../DocumentLinkItemPreview/DocumentLinkItemPreview";

const EditorListItems = ({
  itemList,
  detailsChecked,
  setItemList,
  archiveActive,
  setIsNameFocused,
  setIsNameEntered,
  setName,
  setEditing,
  setItemData,
  itemData,
  setLinksLength,
  editing,
  fromFilter,
}) => {
  const { loggedUser } = useGlobalContext();
  let changeColor = true;
  const iconStyles = {
    height: "80%",
    width: "80%",
  };

  const { openAddLink } = useGlobalContext();
  const { newLinks, setNewLinks } = useGlobalContext();
  const { copy, setCopy } = useGlobalContext();
  const { location } = useGlobalContext();

  const [tagActive, setTagActive] = useState(false);
  const [detailedView, setDetailedView] = useState(null);
  const [startHold, setStartHold] = useState();
  const [users, setUsers] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const [imageModalActive, setImageModalActive] = useState({
    value: null,
    isCommenting: false,
  });
  const [imageModalCommentActive, setImageModalCommentActive] = useState({
    value: null,
    isCommenting: false,
  });

  const [commentActive, setCommentActive] = useState({
    value: false,
    index: 0,
  });

  const [atachModalActive, setAtachModalActive] = useState({
    value: null,
    isCommenting: false,
  });

  const [categoryModalActive, setCategoryModalActive] = useState({
    value: null,
    isCommenting: false,
  });

  const [atachModalCommentActive, setAtachModalCommentActive] = useState({
    value: null,
    isCommenting: false,
  });

  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();

  const handleScrollOpen = () => {
    console.log("Poziva se scroll");
    if (archiveActive) {
      const editorContent = document.querySelector(".editor-line");
      console.log(editorContent);
      if (editorContent !== undefined) editorContent.scrollIntoView();
    } else {
      const editorContent = document.querySelector(".editor-information");
      console.log(editorContent);
      if (editorContent !== undefined) editorContent.scrollIntoView();
    }
  };
  const handleImageClick = (
    index,
    isCommenting,
    indexComment,
    id,
    commentId
  ) => {
    console.log("Poziva se otvaranje slike");
    if (commentActive.value && !isCommenting && commentId !== undefined) return;
    let buttons;
    if (isCommenting && indexComment === undefined) {
      buttons = document.querySelector(".comment-active-buttons");
      console.log("Prvi uslov", buttons);
      if (buttons !== undefined) {
        if (imageModalActive.value === index) {
          setImageModalActive({
            value: null,
            isCommenting: false,
          });
          buttons.classList.remove("fixed");
        } else {
          setImageModalActive({
            value: index,
            isCommenting,
          });
          buttons.classList.add("fixed");
          handleScrollOpen();
        }
      }
      return;
    }

    if (isCommenting === undefined) {
      console.log("Drugi uslov");
      try {
        axios
          .post(`${baseUrl}/items/getAllImagesByCommentId.php`, {
            item_id: id,
            comment_id: commentId,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              let newItemList = itemList.map((item) => {
                if (item.id === id) {
                  let comments = item.comments.map((comment) => {
                    if (comment.id === commentId) {
                      return { ...comment, image: data.data.images[0] };
                    } else return comment;
                  });
                  return { ...item, comments };
                } else return item;
              });
              setItemList(newItemList);
              buttons = document.querySelectorAll(".editor-list-item-buttons")[
                index
              ];
              setImageModalActive({
                value: index,
                isCommenting,
              });

              setImageModalCommentActive({
                value: indexComment,
                isCommenting: true,
              });
              buttons.classList.add("fixed");
              handleScrollOpen();
            } else {
              // setItemList([]);
            }
          });
      } catch (error) {
        console.log("Error fetching messages");
      }
    } else {
      console.log("treci uslov");
      if (isCommenting) {
        buttons = document.querySelector(".comment-active-buttons");
      } else
        buttons = document.querySelectorAll(".editor-list-item-buttons")[index];
      if (buttons !== undefined) {
        if (imageModalActive.value === index) {
          setImageModalActive({
            value: null,
            isCommenting: false,
          });
          buttons.classList.remove("fixed");
        } else {
          try {
            axios
              .post(`${baseUrl}/items/getAllImagesByItemId.php`, {
                item_id: id,
                api_key: apiKey,
                user_id: loggedUser.id,
              })
              .then((data) => {
                console.log(data);
                if (data.data.status === 200) {
                  setItemList(
                    itemList.map((item) => {
                      if (item.id === id)
                        return { ...item, images: data.data.images };
                      else return item;
                    })
                  );
                  setImageModalActive({
                    value: index,
                    isCommenting,
                  });
                  buttons.classList.add("fixed");
                  handleScrollOpen();
                } else {
                  // setItemList([]);
                }
              });
            //console.log(response);
          } catch (error) {
            console.log("Error fetching messages");
          }
        }
      }
    }
  };

  const handlePieClick = (indexItem, id) => {
    let buttons = [];

    buttons = document.querySelectorAll(".editor-list-item-buttons")[indexItem];

    if (buttons !== undefined) {
      if (categoryModalActive.value === indexItem) {
        setCategoryModalActive({
          value: null,
          isCategory: false,
        });
        buttons.classList.remove("fixed");
      } else {
        try {
          axios
            .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
              location_id: parseInt(location.location_id),
              api_key: apiKey,
              user_id: loggedUser.id,
              item_id: id,
            })
            .then((data) => {
              console.log(data);
              if (data.data.status === 200) {
                setItemList(
                  itemList.map((item) => {
                    if (item.id === id)
                      return { ...item, categories: data.data.categories };
                    else return item;
                  })
                );

                setCategoryModalActive({
                  value: indexItem,
                  isCategory: true,
                });
                console.log("Dodajem fixed", buttons);
                buttons.classList.add("fixed");
              }
            });
          //console.log(response);
        } catch (error) {
          console.log("Error fetching messages");
        }
      }
    }
    if (archiveActive) {
      const editorContent = document.querySelector(".editor-line");
      console.log(editorContent);
      if (editorContent !== undefined) editorContent.scrollIntoView();
    } else {
      const editorContent = document.querySelector(".editor-information");
      console.log(editorContent);
      if (editorContent !== undefined) editorContent.scrollIntoView();
    }
  };

  const getDocumentsComments = (idComment, idItem) => {};

  const getImagesComments = (idComment, idItem) => {};

  const getDocumentsFromItem = (id) => {};

  const handleBackItemData = () => {
    const newItemData = itemList[atachModalActive.value];
    const linkItemData = { ...newItemData, link: newItemData.links };
    return linkItemData;
  };

  const handleLinkClick = async (
    indexComment,
    isCommenting,
    indexItem,
    itemSelect,
    commentId
  ) => {
    if (commentActive.value && !isCommenting && commentId !== undefined) return;
    let buttons = [];
    console.log(isCommenting);
    if (isCommenting) {
      buttons = document.querySelector(".comment-active-buttons");

      if (buttons !== undefined) {
        if (atachModalActive.value === indexItem) {
          setAtachModalActive({
            value: null,
            isCommenting: false,
          });
          buttons.classList.remove("fixed");
        } else {
          setAtachModalActive({
            value: indexItem,
            isCommenting,
          });
          buttons.classList.add("fixed");
          handleScrollOpen();
        }
      }
      return;
    }

    if (isCommenting === undefined) {
      console.log(itemSelect);
      console.log(commentId);
      try {
        axios
          .post(`${baseUrl}/items/getAllDocumentsByCommentId.php`, {
            item_id: itemSelect.id,
            comment_id: commentId,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            if (data.data.status === 200) {
              console.log(data.data);
              let newItemList = itemList.map((item) => {
                if (item.id === itemSelect.id) {
                  let comments = item.comments.map((comment) => {
                    if (comment.id === commentId) {
                      return { ...comment, document: data.data.documents };
                    } else return comment;
                  });
                  return { ...item, comments };
                } else return item;
              });
              setItemList(newItemList);

              buttons = document.querySelectorAll(".editor-list-item-buttons")[
                indexItem
              ];
              setAtachModalCommentActive({
                value: indexComment,
                isCommenting: false,
              });
              setAtachModalActive({
                value: indexItem,
                isCommenting,
              });
              buttons.classList.add("fixed");
              handleScrollOpen();
            } else {
              // setItemList([]);
            }
          });
      } catch (error) {
        console.log("Error fetching messages");
      }
      console.log(itemSelect);
      if (itemSelect.links.length !== 0 || itemSelect.comments.length !== 0) {
        buttons = document.querySelectorAll(".editor-list-item-buttons")[
          indexItem
        ];
        setAtachModalCommentActive({
          value: indexComment,
          isCommenting: false,
        });
        setAtachModalActive({
          value: indexItem,
          isCommenting,
        });
        buttons.classList.add("fixed");
        handleScrollOpen();
      }
    } else {
      getDocumentsFromItem(itemSelect.id);
      if (isCommenting)
        buttons = document.querySelector(".comment-active-buttons");
      else
        buttons = document.querySelectorAll(".editor-list-item-buttons")[
          indexItem
        ];

      if (buttons !== undefined) {
        if (atachModalActive.value === indexItem) {
          setAtachModalActive({
            value: null,
            isCommenting: false,
          });
          buttons.classList.remove("fixed");
        } else {
          console.log("pozivam za dokument");
          try {
            await axios
              .post(`${baseUrl}/items/getAllDocumentsByItemId.php`, {
                item_id: itemSelect.id,
                api_key: apiKey,
                user_id: loggedUser.id,
              })
              .then((data) => {
                console.log(data.data);

                if (data.data.status === 200) {
                  setItemList(
                    itemList.map((item) => {
                      if (item.id === itemSelect.id)
                        return { ...item, documents: data.data.documents };
                      else return item;
                    })
                  );
                  setAtachModalActive({
                    value: indexItem,
                    isCommenting,
                  });
                  buttons.classList.add("fixed");
                  handleScrollOpen();
                } else {
                  buttons.classList.remove("fixed");
                }
                console.log(itemSelect);
                if (itemSelect.links.length !== 0) {
                  setAtachModalActive({
                    value: indexItem,
                    isCommenting,
                  });
                  buttons.classList.add("fixed");
                  handleScrollOpen();
                }
              });
            //console.log(response);
          } catch (error) {
            console.log("Error fetching messages");
          }
        }
      }
    }
  };

  const handleVisibilityClick = (index) => {
    try {
      axios
        .post(`${baseUrl}/items/updateItemVisibility.php`, {
          user_id: loggedUser.id,
          api_key: apiKey,
          item_id: itemList[index].id,
        })
        .then((data) => {
          console.log(data.data);
          if (data.data.status === 200) {
            let newItemList = itemList.map((item, i) => {
              if (index === i) return { ...item, visibility: !item.visibility };
              else return item;
            });
            setItemList(newItemList);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleEditClick = async (item) => {
    setIsNameFocused(true);
    setIsNameEntered(true);
    setName(item.title);
    console.log(item);
    /* const newItemData = itemData.dataItems.map((di) => {
      const itemListData = item.data.find((il) => il.name === di.name);
      if (itemListData) return itemListData;
      else return di;
    });*/
    let promises = [];
    const responseImages = axios.post(
      `${baseUrl}/items/getAllImagesByItemId.php`,
      {
        item_id: item.id,
        api_key: apiKey,
        user_id: loggedUser.id,
      }
    );
    promises.push(responseImages);
    const responseDocument = axios.post(
      `${baseUrl}/items/getAllDocumentsByItemId.php`,
      {
        item_id: item.id,
        api_key: apiKey,
        user_id: loggedUser.id,
      }
    );
    promises.push(responseDocument);
    const responseFields = axios.post(
      `${baseUrl}/items/getAllFieldsByItemId.php`,
      {
        item_id: item.id,
        api_key: apiKey,
        user_id: loggedUser.id,
      }
    );
    promises.push(responseFields);
    const responseCategory = axios.post(
      `${baseUrl}/categories/getAllCategoriesByLocationId.php`,
      {
        location_id: parseInt(location.location_id),
        api_key: apiKey,
        user_id: loggedUser.id,
        item_id: item.id,
      }
    );
    promises.push(responseCategory);

    Promise.all(promises).then((values) => {
      console.log(values);
      // slika, document, polja
      console.log(values);
      console.log(item);
      setItemData({
        id: item.id,
        dataItems: values[2].data.data,
        images:
          values[0].data.images !== undefined ? values[0].data.images : [],
        link: item?.links,
        documents:
          values[1].data.documents === undefined
            ? []
            : values[1].data?.documents[0],
        categories: values[3]?.data?.categories,
      });
      setEditing({
        value: true,
        info: item,
      });
      const editorInformation = document.querySelector(".editor-information");
      editorInformation.scrollIntoView();
    });
  };

  const handleSetDetailedView = (index) => {
    if (index === detailedView) {
      setDetailedView(null);
    } else {
      try {
        axios
          .post(`${baseUrl}/items/getAllFieldsByItemId.php`, {
            item_id: index,
            api_key: apiKey,
          })
          .then((data) => {
            // console.log(data.data);
            if (data.data.status === 200) {
              const newItems = itemList.map((item) => {
                if (item.id === index) {
                  return {
                    ...item,
                    data: data.data.data,
                    comments: data.data.comments,
                  };
                } else {
                  return item;
                }
              });

              setItemList(newItems);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }

      setDetailedView(index);
    }
  };

  const handleActiveComment = (index, user_id) => {
    console.log(loggedUser.id !== user_id);
    if (loggedUser.id !== user_id) setCommentActive({ value: true, index });
  };

  const handleCloseComment = () => {
    const text = document.querySelector(".text-area-comment");
    setCommentActive({ ...commentActive, value: false });
    setImageModalActive({
      value: null,
      isCommenting: false,
    });
    setItemData({ ...itemData, images: [] });
    setTagActive(false);
    text.value = "";
  };

  const handleAddComment = (index, id) => {
    const text = document.querySelector(".text-area-comment");
    try {
      // console.log("Ovde se salje komentar", itemData);
      axios
        .post(`${baseUrl}/items/createComment.php`, {
          api_key: apiKey,
          user_id: loggedUser.id,
          item_id: id,
          comment_text: text.value,
          taggedPeople: [],
          document: itemData.document || [],
          link: itemData.link || "",
          image: itemData.images.length === 0 ? "" : itemData.images[0],
        })
        .then((data) => {
          //console.log(data.data);
          if (data.data.status === 200) {
            const newComment = {
              id: data.data.comment_id,
              seen: false,
              user: loggedUser.user_email,
              text: text.value,
              taggedPeople: [],
              timeCreated: data.data.created_at,
              document: itemData.document || { data: "", name: "" },
              link: itemData.link || "",
              image: itemData.images.length === 0 ? "" : itemData.images[0],
            };

            const comments = [...itemList[index].comments, newComment];
            const newItemList = itemList.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  comments,
                  comments_count: data.data.comments_count,
                };
              } else return item;
            });

            setItemList(newItemList);
            setItemData(itemDataDefault);
            setCommentActive({ ...commentActive, value: false });
            setImageModalActive({
              value: null,
              isCommenting: false,
            });
            setAtachModalActive({
              value: null,
              isCommenting: false,
            });
            setTagActive(false);
            setTaggedUsers([]);
            console.log(taggedUsers);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleMouseDown = () => {
    setStartHold(new Date());
  };

  const handleMouseUp = (id, i) => {
    console.log("Provera dolaska", openAddLink);
    if (Math.abs(new Date() - startHold) > 1000 && openAddLink) {
      setCopy({ value: true, index: i });
    }
  };

  const handleOpenImageComment = (index, indexComment) => {
    // console.log("Otvaram sliku za komentare" + imageModalActive);
  };

  const findAnotherDay = () => {
    let contain = false;
    let itemListDate = [];
    if (itemList.length > 0) {
      itemListDate = itemList[0];
    }
    itemList.forEach((item) => {
      if (
        compareAsc(
          new Date(new Date(itemListDate.timeCreated).setHours(0, 0, 0, 0)),
          new Date(new Date(item.timeCreated).setHours(0, 0, 0, 0))
        ) !== 0
      ) {
        contain = true;
      }
    });

    return contain;
  };

  const handleCopyItem = (item) => {
    setCopy({ value: false, index: null });
    setNewLinks([
      ...newLinks,
      { link: null, name: item.title, itemId: item.id },
    ]);
    console.log(item);
  };

  const handleCheckTag = async (e) => {
    const char = e.key;
    if (char === "Shift") return;
    if (char === "@") {
      setTagActive(true);
      if (users.length === 0) {
        try {
          axios
            .post(
              `${baseUrl}/user_location_privilege/getUsersByLocationId.php`,
              {
                location_id: location.location_id,
                api_key: apiKey,
                user_id: loggedUser.id,
              }
            )
            .then((data) => {
              if (data.status === 200) {
                console.log("Postavlja se vrednost kategorije");
                setUsers(data.data);
              }
            });
          //console.log(response);
        } catch (error) {
          console.log("Error fetching messages");
        }
      }
    } else setTagActive(false);
  };

  const handleSelectTagUser = (user) => {
    const textarea = document.querySelector(".text-area-comment");
    textarea.value += user.user_name;
    setTagActive(false);
    setTaggedUsers([...taggedUsers, user]);
    textarea.focus();
  };

  const formatText = (text) => {
    // treba da pretrazuje ceo teskt i tagovani korisnici da
    // imaju poseban stil slova
    return text;
  };

  console.log(atachModalActive);
  console.log(commentActive);

  useEffect(() => {
    setCopy({ value: false, index: null });
  }, []);

  useEffect(() => {
    if (tagActive) {
      const wrapper = document.querySelector(".wrapper-users-container.bottom");
      if (!wrapper) {
        document.querySelector(".wrapper-users-container").scrollIntoView();
      }
    }
  }, [tagActive]);

  return (
    <div className="editor-list-items-wrapper">
      <div className="editor-list-items">
        {itemList?.map((item, index) => {
          const {
            id,
            name,
            timeCreated,
            data,
            title,
            comments,
            timeModified,
            visibility,
            images,
            link,
            document,
            categories_count,
            comments_count,
            images_count,
            links_count,
            document_count,
            user_id,
          } = item;
          if (loggedUser.id !== user_id && !visibility) return null;
          console.log(
            "Ovde se postavljaju",
            links_count,
            document_count,
            id,
            title
          );
          return (
            <div className="editor-list-item-wrapper" key={index}>
              {fromFilter &&
                (index !== 0
                  ? compareAsc(
                      new Date(
                        new Date(itemList[index - 1].timeCreated).setHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ),
                      new Date(new Date(timeCreated).setHours(0, 0, 0, 0))
                    ) !== 0
                  : true) &&
                findAnotherDay() && (
                  <span className="editor-date-time">
                    {format(timeCreated, "dd.MM.yyyy")}
                  </span>
                )}
              <article
                className="editor-list-item"
                onClick={() => handleSetDetailedView(id)}
                onMouseDown={() => handleMouseDown()}
                onMouseUp={() => handleMouseUp(id, index)}
                onTouchStart={() => handleMouseDown()}
                onTouchEnd={() => handleMouseUp(id, index)}
              >
                <div className="editor-list-item-header">
                  <span className="number">{index + 1}</span>
                  <span className="name">{name}</span>
                  <span className="time">{format(timeCreated, "HH:mm")}</span>
                </div>
                <div className="editor-list-item-bottom">
                  <span className="title">{title}</span>
                  <div className="editor-list-item-right">
                    {copy.value && index === copy.index && openAddLink ? (
                      <div
                        className="wrapper-copy"
                        onClick={() => handleCopyItem(item)}
                      >
                        <img src={copyImg}></img>
                      </div>
                    ) : (
                      <>
                        <span className="time">
                          {timeModified !== null &&
                            format(timeModified, "hh:mm")}
                        </span>
                        <div className="comments">
                          <img src={messageImg} alt="" />
                          <span>{comments_count}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </article>
              {commentActive.value && commentActive.index === id && (
                <div className="comment-active">
                  {tagActive && (
                    <div
                      className={`${
                        index === 0
                          ? "wrapper-users-container bottom"
                          : "wrapper-users-container"
                      }`}
                    >
                      <div className="users-container">
                        {users.length !== 0 &&
                          users.map((user) => {
                            console.log(commentActive.index);
                            const { user_id, user_name } = user;
                            return (
                              <div
                                className="user-filter-modal-item-button"
                                key={user_id}
                                onClick={(e) => handleSelectTagUser(user)}
                              >
                                <Button>
                                  <div className="user-filter-modal-item">
                                    <div className="user-img-container">
                                      <img
                                        src={userPicture}
                                        className="user-filter-modal-item-color-letter"
                                      />
                                    </div>
                                    <div className="user-filter-modal-item-name">
                                      {user_name.toUpperCase()}
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                  <textarea
                    className="text-area-comment"
                    onKeyUp={handleCheckTag}
                  />
                  <div className="comment-active-buttons">
                    <div
                      className="comment-list-item-button"
                      onClick={handleCloseComment}
                    >
                      <Button image={closeImg} activation={true} />
                    </div>
                    <div
                      className="comment-list-item-button"
                      onClick={() =>
                        handleImageClick(index, true, undefined, item.id)
                      }
                    >
                      <Button
                        image={imagesImg}
                        active={imageModalActive.isCommenting}
                      />
                    </div>
                    <div
                      className="comment-list-item-button"
                      onClick={() => handleLinkClick(index, true, index, item)}
                    >
                      <Button
                        image={attachImg}
                        active={atachModalActive.isCommenting}
                      />
                    </div>
                    <div
                      className="comment-list-item-button"
                      onClick={() => handleAddComment(index, id)}
                    >
                      <Button image={sendImg} activation={true} />
                    </div>
                  </div>
                </div>
              )}
              {(detailsChecked || id === detailedView) && data.length !== 0 && (
                <div className="editor-list-item-details">
                  {data.map((item, index) => {
                    const { name, data } = item;
                    return (
                      <article className="editor-list-item-article" key={index}>
                        <h5 className="editor-list-item-title">{name}</h5>
                        <span className="editor-list-item-text">{data}</span>
                      </article>
                    );
                  })}
                </div>
              )}
              {(detailsChecked || id === detailedView) &&
                user_id === loggedUser.id && (
                  <div
                    className="comment-list-item-btn"
                    onClick={() => handleActiveComment(id)}
                  >
                    <Button activation={true} image={messageImg} />
                  </div>
                )}

              {comments?.length !== 0 &&
                (detailsChecked || id === detailedView) && (
                  <div className="editor-list-item-comments">
                    <span>KOMENTARI</span>
                    <div className="editor-list-item-comments-main-wrapper">
                      <div className="editor-list-item-comments-main">
                        {comments.map((comment, i) => {
                          const {
                            id,
                            text,
                            timeCreated,
                            user,
                            link,
                            document,
                            image,
                          } = comment;
                          console.log(commentActive);
                          return (
                            <article
                              className="editor-comment"
                              key={`${id}${i}`}
                            >
                              <span>{format(timeCreated, "dd.MM")}</span>
                              <div className="editor-comment-main">
                                <span className="user">{user}</span>
                                <p className="text">{formatText(text)}</p>
                                <div className="buttons">
                                  {image !== "" && (
                                    <div
                                      className="buttons-icon"
                                      onClick={() =>
                                        handleImageClick(
                                          index,
                                          undefined,
                                          i,
                                          item.id,
                                          comment.id
                                        )
                                      }
                                    >
                                      <Button
                                        activation={true}
                                        image={imagesImg}
                                      />
                                    </div>
                                  )}
                                  {(document?.length !== 0 || link !== "") && (
                                    <div
                                      className="buttons-icon"
                                      disabled={commentActive.value}
                                      onClick={() =>
                                        handleLinkClick(
                                          i,
                                          undefined,
                                          index,
                                          item,
                                          comment.id
                                        )
                                      }
                                    >
                                      <Button
                                        activation={true}
                                        image={attachImg}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </article>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              <div className="editor-list-item-buttons">
                {!archiveActive && user_id === loggedUser.id && (
                  <div
                    className="editor-list-item-btn"
                    onClick={() => handleEditClick(item)}
                  >
                    <Button activation={true} image={editImg} />
                  </div>
                )}
                {!archiveActive && user_id === loggedUser.id && (
                  <div
                    className="editor-list-item-btn"
                    onClick={() => handleVisibilityClick(index)}
                  >
                    <Button activation={true}>
                      {visibility ? (
                        <VisibilityIcon style={iconStyles} />
                      ) : (
                        <VisibilityOffIcon style={iconStyles} />
                      )}
                    </Button>
                  </div>
                )}
                <div
                  className="editor-list-item-btn"
                  onClick={() => handlePieClick(index, item.id)}
                >
                  <Button
                    active={categoryModalActive.value === index}
                    image={categoriesImg}
                  />
                  <div className="editor-button-top-circle">
                    {categories_count}
                  </div>
                </div>
                <div
                  className="editor-list-item-btn"
                  onClick={() =>
                    handleImageClick(index, false, undefined, item.id)
                  }
                >
                  <Button
                    image={imagesImg}
                    active={
                      !imageModalActive.isCommenting &&
                      imageModalActive.value === index
                    }
                  />
                  <div className="editor-button-top-circle">{images_count}</div>
                </div>
                <div
                  className="editor-list-item-btn"
                  onClick={() => handleLinkClick(index, false, index, item)}
                >
                  <Button
                    image={attachImg}
                    active={
                      !atachModalActive.isCommenting &&
                      atachModalActive.value === index
                    }
                  />
                  <div className="editor-button-top-circle">
                    {setLinksLength(links_count, document_count, true)}
                  </div>
                </div>

                {(archiveActive || loggedUser.id !== user_id) && (
                  <div
                    className="editor-list-item-btn comments"
                    onClick={() => handleActiveComment(id, user_id)}
                  >
                    <Button
                      activation={true}
                      isDisabled={loggedUser.id === user_id}
                    >
                      <img src={editImg} alt="" />
                      <img src={messageImg} alt="" />
                    </Button>
                  </div>
                )}
              </div>
              {!commentActive.value
                ? imageModalActive.value === index && (
                    <ImageModal
                      itemData={itemList[imageModalActive.value]}
                      setItemData={setItemList}
                      imageIndex={imageModalActive.value}
                      isAdding={editing.value}
                      capacity={4}
                      loggedUser={loggedUser}
                      name={name}
                      user_id={user_id}
                      imageModalActive={imageModalActive}
                      imageModalCommentActive={imageModalCommentActive}
                      itemList={itemList}
                      realItemData={itemData}
                      setRealItemData={setItemData}
                      setImageModalActive={setImageModalActive}
                      order={1}
                    />
                  )
                : imageModalActive.value === index && (
                    <ImageModal
                      itemData={itemList[imageModalActive.value]}
                      setItemData={setItemList}
                      imageIndex={imageModalActive.value}
                      isAdding={true}
                      capacity={1}
                      loggedUser={loggedUser}
                      name={name}
                      user_id={user_id}
                      imageModalActive={imageModalActive}
                      itemList={itemList}
                      realItemData={itemData}
                      setRealItemData={setItemData}
                      setImageModalActive={setImageModalActive}
                      order={22}
                    />
                  )}
              {categoryModalActive.value === index && (
                <CategoryModal
                  itemData={itemList[categoryModalActive.value]}
                  setItemData={setItemData}
                  isAdding={false}
                  order={1}
                />
              )}
              {atachModalActive.isCommenting !== undefined &&
              atachModalActive.value !== null &&
              !atachModalActive.isCommenting ? (
                <DocumentLinkItemPreview
                  realItemData={itemData}
                  attachModalActive={atachModalActive}
                  setItemList={setItemList}
                  itemData={itemList[atachModalActive.value]}
                  setItemData={setItemData}
                  activeAdd={true}
                  index={commentActive.index}
                  order={2}
                  atachModalCommentActive={atachModalCommentActive}
                />
              ) : !commentActive.value ? (
                atachModalActive.value === index && (
                  <DocumentLinkModal
                    realItemData={itemData}
                    attachModalActive={atachModalActive}
                    itemData={itemList[atachModalActive.value]}
                    setItemData={setItemData}
                    activeAdd={false}
                    order={1}
                    atachModalCommentActive={atachModalCommentActive}
                  />
                )
              ) : (
                atachModalActive.value === index && (
                  <DocumentLinkModal
                    realItemData={itemData}
                    attachModalActive={atachModalActive}
                    setItemList={setItemList}
                    itemData={itemList[atachModalActive.value]}
                    setItemData={setItemData}
                    activeAdd={true}
                    index={commentActive.index}
                    order={2}
                    atachModalCommentActive={atachModalCommentActive}
                  />
                )
              )}

              {/*commentActiveImage && <ImageModalComment picture={picture} />*/}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditorListItems;
