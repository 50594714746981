import React, { useState, useRef, useEffect } from "react";
import "./Style.css";
import DefinePin from "../DefinePin/DefinePin";
import user from "../picture/User.png";
import house from "../picture/House.png";
import share from "../picture/Share.png";
import calendar from "../picture/Calendar.png";
import bell from "../picture/Bell.png";
import home from "../picture/Home.png";
import notes from "../picture/Notes.png";
import poruke from "../picture/Message.png";
import settings from "../picture/Settings.png";
import { useGlobalContext } from "../Context/Context";
import Button from "../Button/Button";

function Sidebar({ setCurrentPage }) {
  const landscape = 700;
  const [isVisible, setIsVisible] = useState(false);
  const [selectAccount, setSelectAccount] = useState(0);
  const [selectAccountPin, setSelectAccountPin] = useState(0);

  const startY = useRef(0);
  const startX = useRef(0);
  const isLandscape = useRef(window.innerWidth > landscape);
  const { loggedUser } = useGlobalContext();

  const handleStyleDefinePin = (visible) => {
    const definePin = document.querySelector(".definePin-container");
    const userManual = document.querySelector(".user-manual-wrapper");
    console.log(definePin);
    if (definePin !== null) {
      //width: calc(100vw - 11.11vh);
      /* justify-content: flex-start; */
      // align-items: flex-end;
      console.log("Vidljiv ", visible);
      if (!visible) {
        definePin.style.width = `calc(100vw - 11.11vh)`;
      } else {
        definePin.style.width = "100vw";
      }
    }
    if (userManual !== null) {
      if (!visible) {
        userManual.style.width = `calc(100vw - 11.11vh)`;
      } else {
        userManual.style.width = "100vw";
      }
    }
  };

  const handleBackHome = () => {
    console.log("selektujem account");
    setSelectAccount(0);
    if (selectAccountPin !== 0) setSelectAccountPin(1);
  };

  const handleSettings = () => {
    console.log("selektujem account");
    if (selectAccount === 4) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(4);
    }
  };

  const handleMessage = () => {
    if (selectAccount === 5) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(5);
    }
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.innerWidth < landscape) {
        isLandscape.current = false;
      } else {
        isLandscape.current = true;
      }
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    const handleMouseDown = (e) => {
      startY.current = getClientY(e);
      startX.current = getClientX(e);

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (e) => {
      const deltaY = getClientY(e) - startY.current;
      const deltaX = getClientX(e) - startX.current;
      if (isLandscape.current) {
        if (deltaY > -10) {
          setIsVisible(false); // Slide up
        } else if (deltaY < 10) {
          setIsVisible(true); // Slide down
        }
      } else {
        if (deltaX < -10) {
          setIsVisible(false); // Slide left
          handleStyleDefinePin(false);
        } else if (deltaX > 10) {
          setIsVisible(true); // Slide right
          handleStyleDefinePin(true);
        }
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleTouchStart = (e) => {
      startY.current = getClientY(e);
      startX.current = getClientX(e);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    };

    const handleTouchMove = (e) => {
      const deltaY = getClientY(e) - startY.current;
      const deltaX = getClientX(e) - startX.current;
      if (isLandscape.current) {
        if (deltaY > -10) {
          setIsVisible(true); // Slide down
        } else if (deltaY < 10) {
          setIsVisible(false); // Slide up
        }
      } else {
        if (deltaX < -10) {
          setIsVisible(false); // Slide left
          handleStyleDefinePin(false);
        } else if (deltaX > 10) {
          setIsVisible(true); // Slide right
          handleStyleDefinePin(true);
        }
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    const sidebar = document.querySelector(".sidebar");
    if (sidebar) {
      sidebar.addEventListener("mousedown", handleMouseDown);
      sidebar.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (sidebar) {
        sidebar.removeEventListener("mousedown", handleMouseDown);
        sidebar.removeEventListener("touchstart", handleTouchStart);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isVisible]);

  const getClientY = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientY;
    } else return e.clientY;
  };

  const getClientX = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientX;
    } else return e.clientX;
  };
  console.log(loggedUser);

  return (
    <>
      <DefinePin
        selectAccount={selectAccount}
        setSelectAccount={setSelectAccount}
        setCurrentPage={setCurrentPage}
        setSelectAccountPin={setSelectAccountPin}
        selectAccountPin={selectAccountPin}
      />
      <div className={`sidebar ${isVisible ? "hidden" : "visible"}`}>
        <Button>
          <img src={user} alt="user" />
        </Button>
        <div className="back-home-button"   onClick={()=>setSelectAccount(6)}>
          <Button activation={true}>
            <img src={house} alt="house" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleBackHome()}>
          <Button activation={true}>
            <img src={home} alt="home" />
          </Button>
        </div>
        <Button>
          <img src={notes} alt="notes" />
        </Button>
        <Button>
          <img src={calendar} alt="calendar" />
        </Button>
        <Button>
          <img src={bell} alt="bell" />
        </Button>
        <div className="back-home-button" onClick={handleMessage}>
          <Button active={selectAccount === 5}>
            <img src={poruke} alt="poruke" />
          </Button>
        </div>
        <Button>
          <img src={share} alt="share" />
        </Button>
        <div className="back-home-button" onClick={handleSettings}>
          <Button active={selectAccount === 4}>
            <img src={settings} alt="settings" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
