import EditorListItems from "../../Editor/EditorListItems/EditorListItems";
import "./filterListItem.css";
import Button from "../../Button/Button";
import backImg from "../../nazad.png";
import pdfImg from "../../generisi_pdf.png";
import sortImg from "../../sortiranje.png";
import detailsShrinkImg from "../../smanji.png";
import axios from "axios";
import detailsImg from "../../povecaj.png";
import { compareAsc, format, formatDate } from "date-fns";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../../Context/Context";
import PdfViewer from "../../PdfViewer/PdfViewer";

const FilterListItem = ({
  setFilterListItemActive,
  setArchiveActive,
  setFilterModalActive,
  setArchiveClicked,
  filteredData,
  call,
  account,
}) => {
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [sortingChecked, setSortingChecked] = useState(false);
  const [detailsChecked, setDetailsChecked] = useState(false);
  const [itemList, setItemList] = useState(filteredData);
  const [itemData, setItemData] = useState();
  const [pdfActive, setPdfActive] = useState(false);
  console.log(filteredData);

  const [itemDataDefault, setItemDataDefault] = useState();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { location, loggedUser } = useGlobalContext();

  const setLinksLength = (link, document, validate) => {
    let count = 0;

    if (validate) {
      count = link + document;
    } else {
      if (link !== "") {
        count++;
      }
      if (document?.data !== "" && document?.length !== 0) {
        count++;
      }
    }

    return count;
  };

  const handleBack = () => {
    if (call) {
      setArchiveClicked(true);
      setFilterModalActive(true);
      setArchiveActive(false);
    }

    setFilterListItemActive(false);
  };

  const handleSort = () => {
    let newItemList = [];
    if (!sortingChecked)
      // pretpostavimo da kada su se dodavale nove stavke
      // da je inicijalno sortirano u rastucem redosledu
      newItemList = itemList.sort((a, b) => {
        return compareAsc(a.timeCreated, b.timeCreated);
      });
    else
      newItemList = itemList.sort((a, b) => {
        return compareAsc(b.timeCreated, a.timeCreated);
      });
    setItemList(newItemList);
    setSortingChecked(!sortingChecked);
    const buttons = document.querySelector(".editor-buttons");
    buttons.scrollIntoView();
  };

  const handleDetails = () => {
    if (itemList.length === 0) return;
    setDetailsChecked(!detailsChecked);
    const buttons = document.querySelector(".editor-buttons");
    buttons.scrollIntoView();
  };

  const handlePdf = async () => {
    setPdfActive(true);
    const responses = [];
    const responsesCategory = [];

    console.log("Usao sam u pdf");
    itemList.forEach((item) => {
      const response = axios.post(`${baseUrl}/items/getAllFieldsByItemId.php`, {
        item_id: item.id,
        api_key: apiKey,
        user_id: loggedUser.id,
      });
      responses.push(response);
    });
    console.log("Zavrsio se pozvi");
    const fullfilled = await Promise.all(responses);
    console.log(fullfilled);

    itemList.forEach((item) => {
      const responseC = axios.post(
        `${baseUrl}/categories/getAllCategoriesByLocationId.php`,
        {
          item_id: item.id,
          api_key: apiKey,
          location_id: location.location_id,
          user_id: loggedUser.id,
        }
      );
      responsesCategory.push(responseC);
    });
    const fullfilledC = await Promise.all(responsesCategory);
    console.log(fullfilledC);

    setItemList(
      itemList.map((item, index) => {
        return {
          ...item,
          data: fullfilled[index].data.data,
          comments: fullfilled[index].data.comments,
          categories: fullfilledC[index].data.categories,
        };
      })
    );
  };

  const handleBackPattern = () => {
    try {
      axios
        .post(`${baseUrl}/items/getAllFields.php`, {
          location_id: location.location_id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log(data.data);
          if (data.data.status === 200) {
            setItemData(data.data.default);
            setItemDataDefault(data.data.default);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const findAnotherDay = () => {
    let contain = false;
    let itemListDate = [];
    if (filteredData.length > 0) {
      itemListDate = filteredData[0];
    }
    filteredData.forEach((item) => {
      if (
        compareAsc(
          new Date(new Date(itemListDate.timeCreated).setHours(0, 0, 0, 0)),
          new Date(new Date(item.timeCreated).setHours(0, 0, 0, 0))
        ) !== 0
      ) {
        contain = true;
      }
    });
    return contain;
  };

  console.log(itemList);
  console.log(filteredData);
  console.log(findAnotherDay());
  return (
    <>
      <div className="wrapper-filter-list-item">
        <hr className="editor-line"></hr>
        <div className="wrapper-editor-date">
          <span className="editor-date">
            {filteredData.length !== 0 &&
              format(filteredData[0]?.timeCreated, "dd.MM.yy")}
          </span>
          {findAnotherDay() && (
            <>
              <span className="editor-date">-</span>
              <span className="editor-date">
                {format(
                  filteredData[filteredData.length - 1].timeCreated,
                  "dd.MM.yy"
                )}
              </span>{" "}
            </>
          )}
        </div>
        <div className="editor-buttons">
          <div className="editor-buttons-btn" onClick={handleBack}>
            <Button activation={true} image={backImg} />
          </div>
          <div
            className={
              isNameEntered || itemList.length < 2
                ? "editor-buttons-btn not-active"
                : "editor-buttons-btn"
            }
            onClick={handleSort}
          >
            <Button active={sortingChecked} image={sortImg} />
          </div>
          <div
            className={
              isNameEntered || itemList.length === 0
                ? "editor-buttons-btn not-active"
                : "editor-buttons-btn"
            }
            onClick={handleDetails}
          >
            <Button
              active={detailsChecked}
              image={detailsChecked ? detailsShrinkImg : detailsImg}
              activation={true}
            />
          </div>
          <div
            className={
              isNameEntered || itemList.length === 0
                ? "editor-buttons-btn not-active"
                : "editor-buttons-btn"
            }
            onClick={handlePdf}
          >
            <Button activation={true} image={pdfImg} />
          </div>
        </div>
        {itemList.length !== 0 && (
          <EditorListItems
            itemList={itemList}
            detailsChecked={detailsChecked}
            setItemList={setItemList}
            archiveActive={true}
            setIsNameEntered={setIsNameEntered}
            setIsNameFocused={setIsNameFocused}
            setName={null}
            setEditing={null}
            editing={{ value: false, index: 0 }}
            setItemData={setItemData}
            itemData={itemData}
            setLinksLength={setLinksLength}
            fromFilter={true}
          />
        )}
      </div>
      {pdfActive && (
        <PdfViewer
          setPdfActive={setPdfActive}
          itemList={itemList}
          setItemList={setItemList}
          isFilter={false}
          account={account}
          filterData={filteredData}
        />
      )}
    </>
  );
};

export default FilterListItem;
