import { eachDayOfInterval, startOfMonth, endOfMonth } from "date-fns";

export const weekdays = ["N", "P", "U", "S", "C", "P", "S"];

export const months = [
  "JANUAR",
  "FEBRUAR",
  "MART",
  "APRIL",
  "MAJ",
  "JUN",
  "JUL",
  "AVGUST",
  "SEPTEMBAR",
  "OKTOBAR",
  "NOVEMBAR",
  "DECEMBAR",
];
export const years = [
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

export const setDays = (selected) => {
  const startDay = startOfMonth(selected);
  const endDay = endOfMonth(selected);

  return eachDayOfInterval({
    start: startDay,
    end: endDay,
  });
};

export const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
