import "./addFolderModal.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UndoIcon from "@mui/icons-material/Undo";
import Button from "../../Button/Button";
import { useState } from "react";
import { useGlobalContext } from "../../Context/Context";
import axios from "axios";
import { format } from "date-fns";

const AddFolderModal = ({ setAddingDocument, setDocuments, documents }) => {
  const { baseUrl, location } = useGlobalContext();
  const { apiKey, loggedUser } = useGlobalContext();

  const [document, setDocument] = useState({
    name: "",
    data: "",
    timeCreated: "",
  });

  const handleAddDocument = async () => {
    if (document.data === "") return;
    try {
      axios
        .post(`${baseUrl}/location_documents/create.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          name: document.name,
          data: document.data,
        })
        .then((data) => {
          if (data.data.status === 200) {
            const newDocument = {
              data: "",
              name: document.name,
              date: format(new Date(), "dd.MM.yyyy HH:mm"),
              id: data.data.id,
            };
            setDocuments([...documents, newDocument]);
            setAddingDocument(false);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleInputDocument = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setDocument({
        ...document,
        data: d.currentTarget.result,
        name: currentFile.name,
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="add-folder">
      <div className="add-folder-header">
        <h4>Naziv dokumenta</h4>
        <div
          className="add-folder-header-btn"
          onClick={() => setAddingDocument(false)}
        >
          <Button activation={true}>
            <UndoIcon />
          </Button>
        </div>
      </div>
      <input
        type="text"
        placeholder="Ovde kucamo naziv..."
        value={document.name}
        onChange={(e) =>
          setDocument({ ...document, name: e.currentTarget.value })
        }
      />
      <label htmlFor="file-upload" className="custom-file-upload">
        <FileUploadIcon
          style={{ height: "7vh", width: "30%" }}
          className="custom-file-upload-icon"
        />
        <span>
          {document.name === ""
            ? "Možete da učitate fajl sa ovog uređaja"
            : document.name}
        </span>
      </label>
      <input id="file-upload" type="file" onChange={handleInputDocument} />
      <div className="document-update-btn" onClick={handleAddDocument}>
        <Button activation={true}>POTVRDI</Button>
      </div>
    </div>
  );
};

export default AddFolderModal;
