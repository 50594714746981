import "./filterArchiveLandscape.css";
import Button from "../.././Button/Button";
import message from "../.././1.png";
import startDate from "../.././5.png";
import file from "../.././6.png";
import link from "../.././7.png";
import image from "../.././8.png";
import chart from "../.././9.png";
import profile from "../.././10.png";
import search from "../.././11.png";

const FilterArchive = () => {
  console.log("FIlter kreiran");
  const handleChangeColorButton = (e) => {
    const clickedButton = e.currentTarget.firstChild;
    const clickedButtonStyle = window
      .getComputedStyle(clickedButton, null)
      .getPropertyValue("background-color");
    if (clickedButtonStyle === "rgb(153, 153, 153)") {
      clickedButton.style.backgroundColor = "red";
    } else if (clickedButtonStyle === "rgb(255, 0, 0)") {
      clickedButton.style.backgroundColor = "green";
    } else {
      clickedButton.style.backgroundColor = "#999999ff";
    }
  };

  return (
    <div className="filter-archive-landscape">
      <div className="main-landscape">
        <div className="left-container-landscape">
          <div className="left-top-container-landscape">
            <div className="wrap-dates-landscape">
              <div className="date-landscape">
                <Button>
                  <div className="start-date-calendar-img-landscape">
                    <img src={startDate} alt="" />
                  </div>
                </Button>
                <span>04.03.24</span>
              </div>
              <div className="date-landscape">
                <Button>
                  <div className="end-date-calendar-img-landscape">
                    <img src={startDate} alt="" />
                  </div>
                </Button>
                <span>04.03.24</span>
              </div>
            </div>
            <div className="horizontal-button-row-1-landscape">
              <div className="chart-landscape">
                <Button image={chart} />
              </div>
              <div className="profile-landscape">
                <Button image={profile} />
              </div>
            </div>
          </div>
          <div className="left-bottom-container-landscape">
            <div className="vertical-button-row-3-landscape">
              <div className="colors-landscape">
                <div className="row-flex-landscape">
                <div className="info-text"><span>SVE STAVKE</span></div>
                  <div className="info-color-landscape gray"></div>
                </div>

                <div className="row-flex-landscape">
                  <div className="info-text"><span>STAVKE BEZ</span></div>
                  <div className="info-color-landscape red"></div>
                </div>

                <div className="row-flex-landscape">
                <div className="info-text"><span>STAVKE SA</span></div>
                  <div className="info-color-landscape green"></div>
                </div>
              </div>
            </div>
            <div className="vertical-button-row-1-landscape">
              <div className="first-group-landscape">
                <div
                  className="button-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true}>
                    <img src={message} alt="" />
                  </Button>

                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="file-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true}>
                    <img src={file} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="link-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true}>
                    <img src={link} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="image-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true}>
                    <img src={image} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-container-landscape">
            
          </div>
        </div>
        <div className="right-container-landscape">
          <div className="search-container-landscape">
            <div className="search-landscape">
              <Button>
                <img src={search} alt="" />
                <span>PRIKAŽI</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/**/
export default FilterArchive;
