import "./languageTranslate.css";
import Button from "../../Button/Button";
import Send from "../../picture/Send.png";
import axios from "axios";
import MailOne from "../../picture/TrustEmaiUser1.png";
import MailTwo from "../../picture/TrustMessageUser2.png";
import { useGlobalContext } from "../../Context/Context";
import PlayLanguage from "../../picture/PlayLanguage.png";
import { useRef } from "react";

const LanguageTranslate = ({
  translateLanguage,
  setCurrentPage,
  setTranslateID,
}) => {
  const participant1Ref = useRef();
  const participant2Ref = useRef();

  const { selectedLanguage, loggedUser, apiKey } = useGlobalContext();
  const { baseUrl } = useGlobalContext();

  const handleStartTranslate = async () => {
    console.log("ID jezika na koji se prvodi", translateLanguage);
    console.log("User koji zahteva jezik", loggedUser);
    console.log("Participant 1", participant1Ref.current);
    console.log("Participant 2", participant2Ref.current);
    console.log("APiKey", apiKey);

    const url = `${baseUrl}/translation_users/create.php`;
    const data = {
      language_id: translateLanguage,
      user_id: loggedUser.id,
      api_key: apiKey,
      participant1: participant1Ref.current,
      participant2: participant2Ref.current,
    };

    try {
      const response = await axios.post(url, data);
      console.log("Response:", response.data);
      if (response.data.status === 200) {
        setCurrentPage(4);
        setTranslateID(response.data.translation_id);
      }
      // localStorage.setItem("translation_id", response.data.translation_id);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handelInputMailTwo = (e) => {
    participant2Ref.current = e.currentTarget.value;
  };
  const handelInputMailOne = (e) => {
    participant1Ref.current = e.currentTarget.value;
  };

  return (
    <div className="wrapper-translate">
      <div className="wrapper-table-user">
        <table className="table-language">
          <thead>
            <tr className="first-row">
              <th>N</th>
              <th>ENGLISH</th>
              <td>Ocena klikom</td>
            </tr>
          </thead>
          <tbody>
            <tr key={0}>
              <td className="first-column" style={{ backgroundColor: "green" }}>
                9
              </td>
              <td>SEND</td>
              <td>1x = TACNO</td>
            </tr>
            <tr key={1}>
              <td className="first-column">37</td>
              <td>AGREE</td>
              <td>0/3x = NE ZNAM</td>
            </tr>
            <tr key={2}>
              <td className="first-column" style={{ backgroundColor: "red" }}>
                114
              </td>
              <td>ADD</td>
              <td>2x = NETACNO</td>
            </tr>
          </tbody>
        </table>
        <div className="language-translate-start-button">
          <Button image={PlayLanguage}></Button>
        </div>
      </div>
      <div className="text-translate">
        <span className="text-translate-title">
          {selectedLanguage?.data.titleTranslate}
        </span>
        <span className="text-translate-body">
          {selectedLanguage.data.textTranslate}
        </span>
      </div>
      <div className="input-mail">
        <img src={MailOne} alt="" className="" />
        <input type="text" onChange={handelInputMailOne} />
      </div>
      <div className="input-mail">
        <img src={MailTwo} alt="" className="" />
        <input type="text" onChange={handelInputMailTwo} />
      </div>
      <div className="language-translate-button" onClick={handleStartTranslate}>
        <Button activation={true} image={Send}></Button>
      </div>
    </div>
  );
};

export default LanguageTranslate;
