import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../Context/Context";
import axios from "axios";
import Button from "../../Button/Button";
import "./categoryFilter.css";

const CategoryFilter = ({
  setOpenChart,
  filterState,
  setFilterState,
  isFromFilter,
}) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const [categories, setCategories] = useState([]);
  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();

  const handleSelectedCategory = (cat) => {
    if (!checkIfExists(cat.id))
      setFilterState({
        ...filterState,
        category: [...filterState.category, cat],
      });
    else {
      const newCategory = filterState.category.filter(
        (item) => item.id !== cat.id
      );
      setFilterState({ ...filterState, category: newCategory });
    }
  };

  const checkIfExists = (id) => {
    const item = filterState.category.find((cat) => cat.id === id);
    if (item) return true;
    else return false;
  };

  useEffect(() => {
    const handleCategory = async () => {
      try {
        axios
          .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
            location_id: location.location_id,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              console.log("Postavlja se vrednost kategorije");

              setCategories(data.data.categories);
              // setOpenChart(true);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleCategory();
  }, []);

  console.log(filterState);

  return (
    <div
      className={
        isFromFilter ? "wraper-filter-category" : "wraper-viewer-category"
      }
    >
      {categories.map((category, index) => {
        const { id, color, letter_color, letter, name } = category;
        return (
          <div
            className="category-filter-modal-item-button"
            key={id}
            onClick={() => handleSelectedCategory(category)}
          >
            <Button active={checkIfExists(id)}>
              <div className="category-filter-modal-item">
                <div className="category-filter-modal-item-color">
                  <div
                    className="category-filter-modal-item-color-letter"
                    style={{ backgroundColor: color }}
                  >
                    <div
                      className="category-filter-modal-item-letter"
                      style={{ color: letter_color }}
                    >
                      {letter}
                    </div>
                  </div>
                </div>
                <div className="category-filter-modal-item-name">
                  {name.toUpperCase()}
                </div>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryFilter;
