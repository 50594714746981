import { useEffect, useState } from "react";
import "./languageChoseFlag.css";
import Button from "../../Button/Button";
import Plus from "../../picture/Plus.png";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";

const LanguageChoseFlag = ({ setCurrentPage, setChosenFlag }) => {
  const [choseFlag, setChoseFlag] = useState([]);
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();

  const handleChosenFlag = (flag) => {
    setChosenFlag(flag);
    setCurrentPage(1);
  };

  useEffect(() => {
    const handleSetFlags = async () => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/languages/readFlags.php`,
          {}
        );
        console.log(data);
        if (data.status === 200) setChoseFlag(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    handleSetFlags();
  }, []);

  return (
    <div className="wrapper-language-flag">
      {choseFlag.map((item, index) => {
        return (
          <img
            key={index}
            src={item.image}
            className="chose-flag"
            onClick={() => handleChosenFlag(item)}
          ></img>
        );
      })}
      <div className="add-chose-button">
        <Button image={Plus}></Button>
      </div>
    </div>
  );
};

export default LanguageChoseFlag;
